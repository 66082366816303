/**
 * Returns a three character version of a number
 * Examples:
 *  999 -> 999
 *  1,000 -> 1K
 *  1,001 -> 1K+
 *  1,000,000 -> 1M
 *
 * @param number
 */
export default function shortenCountToThreeCharacters(number: number): string {
  if (number < 1000) {
    return number.toString();
  }

  if (number < 1000000) {
    if (number % 1000 === 0) {
      return `${Math.floor(number / 1000)}K`;
    } else {
      return `${Math.floor(number / 1000)}K+`;
    }
  }

  if (number % 1000000 === 0) {
    return `${Math.floor(number / 1000000)}M`;
  }

  return `${Math.floor(number / 1000000)}M+`;
}
