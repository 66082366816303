import { useParams } from "react-router-dom";
import "./styles.css";
import ProductService from "../../domain/services/product-service";
import { useEffect, useState } from "react";
import IProduct from "../../domain/entities/product/IProduct";
import IApiStatus from "../../domain/entities/api/IApiStatus";
import Breadcrumbs from "../../components/breadcrumbs";
import SEO from "../../components/seo";
import ProductDetails from "../../components/products/product-details";
import IProductReview from "../../domain/entities/product/IProductReview";
import ProductSocial from "../../components/products/product-social";
import Error404Page from "../error/error404";
import ErrorComponent from "../../components/error";
import SkeletonLoading from "../../components/loading/skeleton-loading";
import IProductReviewBrief from "../../domain/entities/product/IProductReviewBrief";

const productService = new ProductService();

export default function ProductPage() {
  const { slug } = useParams();
  const [product, setProduct] = useState<IProduct | undefined>(undefined);
  const [apiStatus, setApiStatus] = useState<IApiStatus>({
    status: false,
    message: "",
    loading: true,
  });
  const [productExists, setProductExists] = useState<boolean>(true);
  const [productReviews, setProductReviews] = useState<IProductReview[]>([]);
  const [productReviewsApiStatus, setProductReviewsApiStatus] =
    useState<IApiStatus>({
      loading: true,
      status: false,
      message: "",
    });
  const [productReviewBrief, setProductReviewBrief] = useState<
    IProductReviewBrief | undefined
  >(undefined);

  async function findProduct() {
    try {
      if (!slug) {
        setProductExists(false);
        setApiStatus({
          status: false,
          message: "Product not found",
          loading: false,
        });
        return;
      }

      const product = await productService.getProduct(slug);
      if (!product) {
        setProductExists(false);
        setApiStatus({
          status: false,
          message: "Product not found",
          loading: false,
        });
        return;
      }

      if (product.data === false) {
        setProductExists(false);
        setApiStatus({
          status: false,
          message: "Product not found",
          loading: false,
        });
        return;
      }

      if (!product.status || !product.data) {
        setApiStatus({
          status: false,
          message: product.message,
          loading: false,
        });
        return;
      }

      setProduct(product.data);
      setProductReviewsApiStatus({
        loading: true,
        message: "",
        status: false,
      });
      const reviews = await productService.getReviews(product.data.slug);
      if (reviews && reviews.status && reviews.data) {
        setProductReviewsApiStatus({
          loading: false,
          message: reviews.message,
          status: reviews.status,
        });
        setProductReviews(reviews.data);
        const r_brief = productService.calculateProductReviewBrief(
          reviews.data
        );
        setProductReviewBrief(r_brief);
      } else
        setProductReviewsApiStatus({
          loading: false,
          message: reviews.message || "An Error Occurred",
          status: false,
        });

      setApiStatus({
        status: true,
        message: "",
        loading: false,
      });
    } catch (error) {
      setApiStatus({
        status: false,
        message: "Unable to find the product",
        loading: false,
      });
    }
  }

  useEffect(() => {
    findProduct();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [slug]);

  if (!productExists && !apiStatus.loading) return <Error404Page />;

  return (
    <div className="product-page">
      <SEO
        title={
          apiStatus.loading
            ? "Please Wait"
            : product
            ? `${product.name} by ${product.brand.name}`
            : "404 - Not Found"
        }
        description={
          apiStatus.loading
            ? "Please wait while we load the product"
            : product
            ? `${product.name} by ${product.brand.name}. ${product.description}`
            : "404 - Not Found"
        }
      />
      <Breadcrumbs
        data={
          product
            ? [
                {
                  to: `/categories/${product.category.slug}`,
                  label: product.category.name,
                },
                {
                  to: `/brands/${product.brand.slug}`,
                  label: product.brand.name,
                },
                {
                  to: window.location.pathname,
                  label: product.name,
                },
              ]
            : []
        }
      />
      {apiStatus.loading ? (
        <div className="product-container">
          <div className="product-details">
            <div className="product-attributes">
              <div className="mobile-product-info">
                <div className="product-brand-name">
                  <SkeletonLoading />
                </div>
                <div className="product-name">
                  <SkeletonLoading />
                </div>
                <div className="product-brief-review">
                  <SkeletonLoading />
                </div>
              </div>
              <div className="product-image">
                <div className="product-image-selector">
                  <SkeletonLoading />
                  <SkeletonLoading />
                  <SkeletonLoading />
                  <SkeletonLoading />
                  <SkeletonLoading />
                  <SkeletonLoading />
                </div>
                <div className="product-image-current borderless">
                  <SkeletonLoading />
                </div>
              </div>
              <div className="product-attributes-info">
                <div className="product-ingredients-brief">
                  <div className="product-ingredients-brief-heading">
                    <SkeletonLoading />
                  </div>
                  <div className="product-additional-information">
                    <div className="product-additional-information-heading">
                      <SkeletonLoading />
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="product-description">
              <div className="product-brand-name">
                <SkeletonLoading />
              </div>
              <div className="product-name">
                <SkeletonLoading />
              </div>
              <div className="product-brief-review">
                <SkeletonLoading />
              </div>
              <div className="product-shades">
                <SkeletonLoading />
                <div className="product-shades-list">
                  <div className="product-shade">
                    <SkeletonLoading />
                  </div>
                  <div className="product-shade">
                    <SkeletonLoading />
                  </div>
                  <div className="product-shade">
                    <SkeletonLoading />
                  </div>
                  <div className="product-shade">
                    <SkeletonLoading />
                  </div>
                  <div className="product-shade">
                    <SkeletonLoading />
                  </div>
                </div>
              </div>
              <div className="product-actions">
                <SkeletonLoading />
                <SkeletonLoading />
              </div>
              <div className="product-details-switch">
                <div className="product-details-switch-action">
                  <SkeletonLoading />
                  <SkeletonLoading />
                </div>
                <div className="product-details-switch-content">
                  <SkeletonLoading />
                </div>
              </div>
            </div>
          </div>
          <div className="product-social">
            <SkeletonLoading />
            <div className="product-social-platforms-switch">
              <SkeletonLoading />
              <SkeletonLoading />
            </div>
            <div className="product-social-content">
              {Array.from({ length: 7 }).map((_, index) => (
                <div
                  key={`p-s-c-sk-ld-${index}`}
                  className="product-social-content-item"
                >
                  <SkeletonLoading />
                </div>
              ))}
            </div>
          </div>
        </div>
      ) : !apiStatus.status ? (
        <ErrorComponent
          title="Uh Oh! Something went wrong"
          description="Something went wrong while finding the product you were looking for. Please try again later."
          errorCode={apiStatus.message}
        />
      ) : product ? (
        <div className="product-container">
          <ProductDetails
            product={product}
            reviews={{
              apiStatus: productReviewsApiStatus,
              reviewBrief: productReviewBrief,
              reviews: productReviews,
            }}
            reload={() => findProduct()}
          />
          <ProductSocial productSocials={product.socials} />
        </div>
      ) : (
        <ErrorComponent
          title="Uh Oh! Something went wrong"
          description="Something went wrong while finding the product you were looking for. Please try again later."
        />
      )}
    </div>
  );
}
