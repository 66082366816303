import IApiResponse from "../entities/api/IApiResponse";
import IUserService from "../entities/services/IUserService";
import UserRepository from "../repositories/user-repository";

export default class UserService implements IUserService {
  private userRepository: UserRepository;

  constructor() {
    this.userRepository = new UserRepository();
  }

  async getUser(userId: number) {
    return this.userRepository.getUser(userId);
  }

  async followUser(userId: number): Promise<IApiResponse<boolean>> {
    return this.userRepository.followUser(userId);
  }

  async unfollowUser(userId: number): Promise<IApiResponse<boolean>> {
    return this.userRepository.unfollowUser(userId);
  }
}
