import { useEffect, useState } from "react";
import Breadcrumbs from "../../../components/breadcrumbs";
import SEO from "../../../components/seo";
import BlogRepository from "../../../domain/repositories/blog-repository";
import BlogService from "../../../domain/services/blog-service";
import "./styles.css";
import IBlogMini from "../../../domain/entities/blog/IBlogMini";
import IBlogFilter from "../../../domain/entities/blog/IBlogFilter";
import IApiStatus from "../../../domain/entities/api/IApiStatus";
import IBlogRequestFilter from "../../../domain/entities/blog/IBlogRequestFilter";
import BlogsList from "../../../components/blogs/blogs-list";
import ErrorComponent from "../../../components/error";

const blogRepository = new BlogRepository();
const blogService = new BlogService(blogRepository);

export default function BlogDiscoverPage() {
  const [blogs, setBlogs] = useState<IBlogMini[]>([]);
  const [filters, setFilters] = useState<IBlogFilter | undefined>(undefined);
  const [apiStatus, setApiStatus] = useState<IApiStatus>({
    loading: true,
    status: false,
    message: "",
  });
  const [loadedPage, setLoadedPage] = useState(1);
  const [customPage, setCustomPage] = useState(0);
  const [allPagesLoaded, setAllPagesLoaded] = useState(false);
  const [totalPages, setTotalPages] = useState(1);
  const [currentFilters, setCurrentFilters] = useState<IBlogRequestFilter>({
    page_limit: 20,
    page_number: 1,
  });

  async function getBlogs(
    page: number,
    keepFilters?: true,
    removeBlogs?: true
  ): Promise<void> {
    try {
      setApiStatus({
        loading: true,
        status: false,
        message: "",
      });
      if (page === 1) {
        setBlogs([]);
        if (!keepFilters) setFilters(undefined);
        setAllPagesLoaded(false);
        setLoadedPage(1);
      }
      if (removeBlogs) setBlogs([]);

      const response = await blogService.getBlogs({
        ...currentFilters,
        page_number: page,
      });

      if (!response || !response.status || !response.data) {
        setApiStatus({
          loading: false,
          status: response.status || false,
          message: "An error occurred while fetching blogs",
        });
        return;
      }

      if (response.pagination?.lastPage) setAllPagesLoaded(true);
      setBlogs((prevBlogs) => [...prevBlogs, ...(response?.data?.blogs || [])]);
      if (page === 1) setTotalPages(response.pagination?.totalPages || 1);
      if ((page === 1 && !keepFilters) || !filters)
        setFilters(response.data.filters);
      setApiStatus({
        loading: false,
        status: response.status,
        message: response.message,
      });
    } catch (error) {
      setApiStatus({
        loading: false,
        status: false,
        message: "An error occurred while fetching blogs",
      });
    }
  }

  useEffect(() => {
    if (customPage === loadedPage) {
      getBlogs(customPage, true, true);
      setCustomPage(0);

      return;
    }

    if (loadedPage <= 1) getBlogs(1);
    else getBlogs(loadedPage, true);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [loadedPage]);

  useEffect(() => {
    if (apiStatus.loading) return;
    getBlogs(1, true);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentFilters]);

  return (
    <div className="blog-discover-page">
      <SEO
        title="Blog"
        description="Discover the latest blog on our website. Find out what's new and trending"
      />
      <Breadcrumbs
        data={[
          {
            label: "Blog",
            to: window.location.pathname,
          },
        ]}
      />

      <BlogsList
        filters={filters}
        blogs={blogs}
        isLoading={apiStatus.loading}
        loadNextPage={() => {
          setLoadedPage((prevLoadedPage) => prevLoadedPage + 1);
        }}
        setPage={(page) => {
          setCustomPage(page);
          setLoadedPage(page);
        }}
        allPagesLoaded={allPagesLoaded}
        currentPage={loadedPage}
        totalPages={totalPages}
        setCurrentFilters={setCurrentFilters}
      />

      {apiStatus.loading ? null : !apiStatus.status ? (
        <ErrorComponent
          title="An error occurred"
          description="An error occurred while fetching all blogs."
          errorCode={apiStatus.message}
        />
      ) : blogs.length === 0 ? (
        <ErrorComponent
          title="Nothing for you here.. At the moment :)"
          description="Have an ice cream or sip a tea while we work for bringing more blogs"
        />
      ) : null}
    </div>
  );
}
