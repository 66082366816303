import { forwardRef } from "react";
import IBlogMini from "../../../domain/entities/blog/IBlogMini";
import "./styles.css";
import { NavLink } from "react-router-dom";
import reduceText from "../../../utilities/text/text-reducer";
import Markdown from "react-markdown";

interface IBlogListItemProps {
  data: IBlogMini;
}

const BlogListItem = forwardRef<any, IBlogListItemProps>(({ data }, ref) => {
  return (
    <div className="blog-list-item" ref={ref}>
      <div className="blog-list-item-image">
        <img
          src={encodeURI(data.image || data.author_image)}
          alt={data.title}
        />
      </div>
      <div className="blog-list-item-details">
        <div className="blog-list-item-type">
          <div className="blog-list-item-author">{data.author_name}</div>
          <div className="blog-list-item-date">
            <i>
              {data.created_at
                ? new Date(data.created_at).toDateString()
                : null}
            </i>
          </div>
        </div>
        <div className="blog-list-item-title">
          <NavLink
            to={`/blog/${data.id}`}
            state={{ from: window.location.pathname }}
          >
            {data.title}
          </NavLink>
        </div>
        <div className="blog-list-item-brief">
          <Markdown>{reduceText(data.md_content, 100)}</Markdown>
        </div>
      </div>
      <div className="blog-list-item-button">
        <NavLink
          to={`/blog/${data.id}`}
          state={{
            from: window.location.pathname,
          }}
        >
          View More
        </NavLink>
      </div>
    </div>
  );
});

export default BlogListItem;
