export default function LeftArrow() {
  return (
    <svg
      width="18px"
      height="18px"
      viewBox="0 0 1024 1024"
      version="1.1"
      xmlns="http://www.w3.org/2000/svg"
      fill="#000000"
    >
      <path
        d="M768 903.232l-50.432 56.768L256 512l461.568-448 50.432 56.768L364.928 512z"
        fill="currentColor"
      ></path>
    </svg>
  );
}
