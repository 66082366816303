import IApiResponse from "../entities/api/IApiResponse";
import IAuthService from "../entities/services/IAuthService";
import LocalStorageKey from "../entities/storage/LocalStorageKey";
import IGetUserProfile from "../entities/user/IGetUserProfile";
import IUser from "../entities/user/IUser";
import AuthRepository from "../repositories/auth-repository";
import LocalStorage from "../storage/local-storage";

export default class AuthService implements IAuthService {
  private authRepository: AuthRepository;
  private localStorage: LocalStorage;

  constructor() {
    this.authRepository = new AuthRepository();
    this.localStorage = new LocalStorage();
  }

  async refreshToken(): Promise<IApiResponse<boolean>> {
    const response = await this.authRepository.refreshToken();
    if (!response.data) this.localStorage.remove(LocalStorageKey.USER);
    window.location.reload();

    return response;
  }

  async logout(): Promise<IApiResponse<boolean>> {
    return this.authRepository.logout();
  }

  async getMyProfile(): Promise<IApiResponse<IGetUserProfile>> {
    return this.authRepository.getMyProfile();
  }

  async updateMyProfile(
    data: IUser,
    userImage?: File
  ): Promise<IApiResponse<null>> {
    return this.authRepository.updateMyProfile(data, userImage);
  }
}
