import StarRatings from "react-star-ratings";
import IProductReview from "../../../../domain/entities/product/IProductReview";
import "./styles.css";
import ThumbsUpIcon from "../../../../assets/icons/thumbs-up";
import ThumbsDownIcon from "../../../../assets/icons/thumbs-down";
import { NavLink } from "react-router-dom";
import ProductService from "../../../../domain/services/product-service";
import { useEffect, useState } from "react";
import { useUserContext } from "../../../../contexts/user";

interface IReviewItemProps {
  data: IProductReview;
}

const productService = new ProductService();

export default function ReviewItem({ data }: IReviewItemProps) {
  const [liked, setLiked] = useState(data.is_liked);
  const [likeCount, setLikeCount] = useState<number>(data.review_likes || 0);
  const [disliked, setDisliked] = useState(data.is_disliked);
  const [dislikeCount, setDislikeCount] = useState<number>(
    data.review_dislikes || 0
  );
  const { isLoggedIn } = useUserContext();

  async function likeReview() {
    await productService.likeProductReview(data.review_id);
    if (disliked) setDislikeCount((prev) => prev - 1);
    setLikeCount((prev) => prev + 1);
    setLiked(true);
    setDisliked(false);
  }

  async function dislikeReview() {
    await productService.dislikeProductReview(data.review_id);
    if (liked) setLikeCount((prev) => prev - 1);
    setDislikeCount((prev) => prev + 1);
    setLiked(false);
    setDisliked(true);
  }

  async function undoLikeDislikeReview() {
    await productService.undoLikeDislikeProductReview(data.review_id);
    if (liked) setLikeCount((prev) => prev - 1);
    else setDislikeCount((prev) => prev - 1);
    setLiked(false);
    setDisliked(false);
  }

  useEffect(() => {
    setLiked(data.is_liked);
    setLikeCount(data.review_likes || 0);
    setDisliked(data.is_disliked);
    setDislikeCount(data.review_dislikes || 0);
  }, [data]);

  return (
    <div className="review-item">
      <div className="review-item-content">
        <div className="review-item-rating">
          {Math.round(data.rating * 10) / 10}
          <StarRatings
            rating={data.rating / 5}
            starDimension="15px"
            starSpacing="1px"
            starRatedColor="var(--background)"
            numberOfStars={1}
          />
        </div>
        <div className="review-content">
          {/* <div className="static-reviews">
            {data.static_review?.map((review, index) => (
              <div
                key={`st-rev-${data.review_id}-${index}`}
                className="static-review"
              >
                {review}
              </div>
            ))}
          </div> */}
          <div className="review-body">{data.static_review?.join(", ")}</div>
          <div className="review-body">{data.review}</div>
          <div className="reviewer-name">
            <NavLink
              to={`/profile/${data.user_id}`}
              state={{
                from: window.location.pathname,
              }}
            >
              {data.user_name}
            </NavLink>{" "}
            | {new Date(data.created_at).toDateString()}
          </div>
        </div>
      </div>
      {isLoggedIn ? (
        <div className="review-item-action">
          <div
            className={`review-item-action-button${liked ? " active" : ""}`}
            onClick={() => {
              if (liked) undoLikeDislikeReview();
              else likeReview();
            }}
            title={liked ? "Undo Like" : "Like this review"}
          >
            <ThumbsUpIcon />
            <span>{likeCount}</span>
          </div>
          <div
            className={`review-item-action-button${disliked ? " active" : ""}`}
            onClick={() => {
              if (disliked) undoLikeDislikeReview();
              else dislikeReview();
            }}
            title={disliked ? "Undo Dislike" : "Dislike this review"}
          >
            <ThumbsDownIcon />
            <span>{dislikeCount}</span>
          </div>
        </div>
      ) : (
        <NavLink
          to="/account/login"
          state={{ from: window.location.pathname }}
          className="review-item-action"
        >
          <div className={`review-item-action-button${liked ? " active" : ""}`}>
            <ThumbsUpIcon />
            <span>{likeCount}</span>
          </div>
          <div
            className={`review-item-action-button${disliked ? " active" : ""}`}
          >
            <ThumbsDownIcon />
            <span>{dislikeCount}</span>
          </div>
        </NavLink>
      )}
    </div>
  );
}
