import { useEffect, useState } from "react";
import "./styles.css";
import CloseIcon2 from "../../../../assets/icons/close-2";
import CollectReview from "./collect-review";
import IProductReview from "../../../../domain/entities/product/IProductReview";

interface ILeaveAReviewPopUpProps {
  display: boolean;
  close(reload: boolean): void;
  productSlug: string;
  categorySlug: string;
  userReview?: IProductReview;
}
export default function LeaveAReviewPopUp({
  display,
  close,
  productSlug,
  categorySlug,
  userReview,
}: ILeaveAReviewPopUpProps) {
  const [visible, setVisible] = useState(display);
  const [closing, setClosing] = useState(false);

  useEffect(() => {
    if (display) {
      setVisible(true);
      setClosing(false);
      document.body.style.overflow = "hidden";
    } else {
      setClosing(true);
      setTimeout(() => {
        setVisible(false);
        setClosing(false);
        document.body.style.overflow = "auto";
      }, 250);
    }
  }, [display]);

  return (
    <div className="product-review-collector">
      <div
        className={`product-review-collector-content${
          visible ? " visible" : ""
        }${closing ? " closing" : ""}`}
      >
        <div className="product-review-collector-heading">
          <div className="product-review-collector-heading-content">
            {userReview ? "Edit Your Review" : "Write a Review"}
          </div>
          <div
            className="close-drawer"
            onClick={() => {
              close(false);
            }}
            title="Close this menu"
          >
            <CloseIcon2 />
          </div>
        </div>
        <CollectReview
          productSlug={productSlug}
          categorySlug={categorySlug}
          close={close}
          userReview={userReview}
        />
      </div>
      <div
        className="grey-out"
        onClick={() => {
          close(false);
        }}
      ></div>
    </div>
  );
}
