import { useEffect, useState } from "react";
import IApiStatus from "../../../domain/entities/api/IApiStatus";
import LandingService from "../../../domain/services/landing-service";
import "./styles.css";
import ILandingCommunity from "../../../domain/entities/landing/ILandingCommunity";
import { NavLink } from "react-router-dom";

interface ILandingCommunityProps {
  landingService: LandingService;
}

export default function LandingCommunity({
  landingService,
}: ILandingCommunityProps) {
  const [communityItems, setCommunityItems] = useState<
    ILandingCommunity[] | undefined
  >(undefined);
  const [apiDetails, setApiDetails] = useState<IApiStatus>({
    status: false,
    message: "",
    loading: true,
  });

  useEffect(() => {
    async function fetchCommunityItems() {
      const response = await landingService.getCommunities();

      if (response.status && response.data) {
        setCommunityItems(response.data);
      }

      setApiDetails({
        status: response.status,
        message: response.message,
        loading: false,
      });
    }

    fetchCommunityItems();
  }, [landingService]);

  return (
    <div className="landing-communities">
      <h2> </h2>
      <div className="landing-communities-content">
        {apiDetails.loading && <div>Loading...</div>}
        {apiDetails.status && communityItems && (
          <div className="landing-communities-items">
            {communityItems.map((item, index) => (
              <NavLink
                to={item.linkTo}
                state={{
                  from: window.location.pathname,
                }}
                key={index}
                className="landing-community-item"
              >
                <img
                  src={item.image}
                  alt={item.name}
                  className="community-image"
                />
                <div className="community-text">
                  <p>{item.description}</p>
                  <h2>{item.name}</h2>
                </div>
              </NavLink>
            ))}
          </div>
        )}

        {!apiDetails.status && <div>{apiDetails.message}</div>}
      </div>
    </div>
  );
}
