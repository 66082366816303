import TopBarProgress from "react-topbar-progress-indicator";

export default function TopBarLoading() {
  TopBarProgress.config({
    barColors: {
      "0": "#ff7f50",
      "1.0": "#ff7f50",
    },
    barThickness: 5,
    shadowBlur: 2,
  });

  return <TopBarProgress />;
}
