import IProductEcoFriendliness from "../../../../domain/entities/product/IProductEcoFriendliness";
import "./styles.css";

export default function ProductAdditionalInformationItem(
  data: IProductEcoFriendliness
) {
  return (
    <div
      className={`product-additional-information-item${
        data.is_unknown ? " unavailable" : data.is_certified ? "" : " red"
      }`}
      title={data.name}
    >
      {data.logo ? (
        <img
          src={data.logo}
          alt={data.name}
          onError={({ currentTarget }) => {
            currentTarget.onerror = null;
            currentTarget.src = "/assets/placeholders/product.svg";
          }}
        />
      ) : (
        <span>{data.name}</span>
      )}
    </div>
  );
}
