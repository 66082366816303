export default function LipstickIcon() {
  return (
    <svg
      className="lipstick-icon"
      width="32px"
      height="24px"
      viewBox="0 0 36 36"
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
      aria-hidden="true"
      role="img"
      preserveAspectRatio="xMidYMid meet"
      fill="#000000"
    >
      <path
        fill="#EA596E"
        d="M16.522.232c2.246 0 4.832 1.375 6.703 3.249C25.098 5.355 27 7.408 27 9.479v17.204l-15.974.031V9.582c-.093-1.506 1.075-9.35 5.496-9.35"
      ></path>
      <path
        fill="#DD2E44"
        d="M19 11.378V28c0 1.104.896 0 2 0v2a2 2 0 0 0 2-2V12.333c-1.258-.104-2.65-.262-4-.955z"
      ></path>
      <path
        fill="#F4ABBA"
        d="M22.072 3.455c2.664 2.391 4.277 5.236 2.961 6.699c-1.314 1.463-5.06.991-7.721-1.4c-2.661-2.39-3.753-5.516-2.439-6.979c1.316-1.463 4.538-.712 7.199 1.68"
      ></path>
      <path
        fill="#FFCC4D"
        d="M30 34a2 2 0 0 1-2 2H10a2 2 0 0 1-2-2v-6a2 2 0 0 1 2-2h18a2 2 0 0 1 2 2v6z"
      ></path>
      <path
        fill="#FFAC33"
        d="M31 34a2 2 0 0 1-2 2H9a2 2 0 0 1-2-2v-1a2 2 0 0 1 2-2h20a2 2 0 0 1 2 2v1z"
      ></path>
    </svg>
  );
}
