import { useState } from "react";
import { IProductSlugFilter } from "../../../domain/entities/product/IProductFilter";
import "./styles.css";
import ProductFilterCheckbox from "../product-filter-checkbox";

interface IProductFilterProps {
  data: IProductSlugFilter[];
  name: string;
  setFilterValue(slugs: string[]): void;
}

export default function ProductFilter({
  data,
  name,
  setFilterValue,
}: IProductFilterProps) {
  const [checkedSlugs, setCheckedSlugs] = useState<string[]>([]);

  return (
    <div className="product-filter">
      <div className="product-filter-type">{name}</div>
      <div className="product-filters-list">
        {data.map((filter, index) => (
          <ProductFilterCheckbox
            selected={checkedSlugs.includes(filter.slug)}
            onSelect={(value) => {
              let upcomingFilter = checkedSlugs;
              if (checkedSlugs.includes(value)) {
                upcomingFilter = checkedSlugs.filter((slug) => slug !== value);
              } else {
                upcomingFilter = [...checkedSlugs, value];
              }
              setFilterValue(upcomingFilter);
              setCheckedSlugs(upcomingFilter);
            }}
            key={`product-filter-${index}`}
            name={filter.name}
            slug={filter.slug}
          />
        ))}
      </div>
    </div>
  );
}
