import "./styles.css";

export default function FooterNewsletter() {
  return (
    <div className="footer-newsletter">
      <h2>Subscribe to our newsletter</h2>
      <form
        onSubmit={(e) => {
          e.preventDefault();
        }}
      >
        <input type="text" placeholder="Enter your email address" />
        <button type="submit">Subscribe Now</button>
      </form>
    </div>
  );
}
