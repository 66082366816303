import IProductReviewBrief from "../../../../domain/entities/product/IProductReviewBrief";
import "./styles.css";
import IndividualReviewBar from "./individual-review-bar";
import StarIcon from "../../../../assets/icons/star";
import shortenQuantityToString from "../../../../utilities/text/shorten-quantity-to-string";

interface IReviewBriefProps {
  data?: IProductReviewBrief;
}

export default function ReviewBrief({ data }: IReviewBriefProps) {
  if (!data) return <div className="review-brief"></div>;
  return (
    <div className="review-brief">
      {data.reviewCount > 0 ? (
        <div className="main-review">
          <p>Based on {data.reviewCount} ratings</p>
          <p>
            <u>What People Say</u>
          </p>
          <div className="static-review-brief">
            {Object.entries(data.staticReviewCounts).map(([key, value]) => (
              <div key={`st-rv-bbr-${key}`} className="static-review">
                <span>{key}</span>
                <span className="count">{shortenQuantityToString(value)}</span>
              </div>
            ))}
          </div>
        </div>
      ) : (
        <div className="main-review">
          <h1>Be the first to review this product</h1>
        </div>
      )}
      <div className="individual-star-review-count">
        {data.individualRatings.map((rating, index) => (
          <div key={`rating-${index}`} className="individual-star-review">
            <span className="left">
              {rating.star} <StarIcon />
            </span>
            <IndividualReviewBar ratingPercentage={rating.ratingPercentage} />
            <span>{rating.ratingCount}</span>
          </div>
        ))}
      </div>
    </div>
  );
}
