import { useEffect, useRef, useState } from "react";
import generateUUID from "../../../../utilities/uuid/generateUUID";
import IGHeader from "./components/IGHeader";
import IGBody from "./components/IGBody";
import IGFooter from "./components/IGFooter";
import "./styles.css";

const defaultIgVersion = "14";
const defaultLinkText = "View this post on Instagram";
const defaultProcessDelay = 100;
const defaultRetryInitialDelay = 1000;
const defaultRetryBackoffMaxDelay = 30000;
const defaultBackgroundBlurAnimationDuration = 700;

let embedScriptLoaded = false;

interface IInstagramEmbedProps {
  videoId: string;
  backgroundUrl?: string;
  igVersion?: string;
  linkText?: string;
  processDelay?: number;
  scriptLoadDisabled?: boolean;
  linkTextDisabled?: boolean;
  backgroundBlurDisabled?: boolean;
  backgroundBlurAnimationDisabled?: boolean;
  backgroundBlurAnimationDuration?: number;
  spinnerDisabled?: boolean;
  softFilterDisabled?: boolean;
  retryDisabled?: boolean;
  retryInitialDelay?: number;
  retryBackoffMaxDelay?: number;
}

export default function InstagramEmbed({
  videoId,
  backgroundUrl,
  igVersion = defaultIgVersion,
  linkText = defaultLinkText,
  processDelay = defaultProcessDelay,
  scriptLoadDisabled = false,
  linkTextDisabled = false,
  backgroundBlurDisabled = false,
  backgroundBlurAnimationDisabled = false,
  backgroundBlurAnimationDuration = defaultBackgroundBlurAnimationDuration,
  spinnerDisabled = false,
  softFilterDisabled = false,
  retryDisabled = false,
  retryInitialDelay = defaultRetryInitialDelay,
  retryBackoffMaxDelay = defaultRetryBackoffMaxDelay,
}: IInstagramEmbedProps) {
  const [initialized, setInitialized] = useState(false);
  const [processTime, setProcessTime] = useState(-1);
  const [retryDelay, setRetryDelay] = useState(retryInitialDelay);
  const [retrying, setRetrying] = useState(false);
  const [retryTime, setRetryTime] = useState(-1);
  const uuidRef = useRef(generateUUID());
  useEffect(() => {
    const win = typeof window !== "undefined" ? (window as any) : undefined;
    if (win && processTime >= 0) {
      // This call will use the IG embed script to process all elements with the `instagram-media` class name.
      if (typeof win.instgrm !== "undefined" && win.instgrm.Embeds) {
        // console.log('Processing...', Date.now());
        win.instgrm.Embeds.process();
      } else {
        console.error(
          "Instagram embed script not found. Unable to process Instagram embed:",
          videoId
        );
      }
    }
  }, [processTime, videoId]);

  // Initialization
  useEffect(() => {
    const timeout: any = undefined;
    if (!initialized) {
      if (typeof processDelay !== "undefined" && processDelay > 0) {
        setTimeout(() => {
          setProcessTime(Date.now());
          setInitialized(true);
        }, Math.max(0, processDelay));
      } else if (processDelay === 0) {
        setProcessTime(Date.now());
        setInitialized(true);
      }
    }
    return () => clearTimeout(timeout);
  }, [initialized, processDelay]);

  // Exponential backoff retry
  useEffect(() => {
    let timeout: any = undefined;
    if (initialized && !retryDisabled && typeof document !== "undefined") {
      timeout = setTimeout(() => {
        const preEmbedElement = document.getElementById(uuidRef.current);
        if (!!preEmbedElement) {
          setProcessTime(Date.now());
          setRetryDelay(
            Math.max(0, Math.min(retryDelay * 2, retryBackoffMaxDelay))
          );
          setRetryTime(Date.now());
          setRetrying(true);
        }
      }, Math.max(0, retryDelay));
    }

    return () => clearTimeout(timeout);
  }, [initialized, retryBackoffMaxDelay, retryDelay, retryDisabled, retryTime]);

  useEffect(() => {
    if (
      typeof document !== "undefined" &&
      !scriptLoadDisabled &&
      !embedScriptLoaded
    ) {
      const alreadyExists = document.getElementById("instagram-embed-script");
      if (!!alreadyExists) {
        embedScriptLoaded = true;
        return;
      }
      const igScript = document.createElement("script");
      igScript.setAttribute("src", "//www.instagram.com/embed.js");
      document.head.appendChild(igScript);
      embedScriptLoaded = true;
    }
  }, [scriptLoadDisabled]);

  const urlWithNoQuery = videoId.replace(/[?].*$/, "");
  const cleanUrlWithEndingSlash = `${urlWithNoQuery}${
    urlWithNoQuery.endsWith("/") ? "" : "/"
  }`;

  return (
    <div
      className={"instagram-media-container"}
      style={{
        overflow: "hidden",
        width: "100%",
        maxWidth: "540px",
        height: "620px",
      }}
      key={`${uuidRef}-${retryTime}`}
    >
      <blockquote
        className="instagram-media"
        data-instgrm-permalink={`${cleanUrlWithEndingSlash}?utm_source=ig_embed&utm_campaign=loading`}
        data-instgrm-version={igVersion}
        style={{
          background: "#FFF",
          borderRadius: "3px",
          border: "1px solid #dee2e6",
          // boxShadow: '0 0 1px 0 rgba(0,0,0,0.5),0 1px 10px 0 rgba(0,0,0,0.15)',
          maxWidth: "540px",
          minWidth: "326px",
          padding: 0,
          width: "calc(100% - 2px)",
        }}
      >
        <div
          className="instagram-media-pre-embed"
          id={uuidRef.current}
          style={{ padding: "16px 0" }}
        >
          <a
            href={`${cleanUrlWithEndingSlash}?utm_source=ig_embed&utm_campaign=loading`}
            style={{
              background: "#FFFFFF",
              lineHeight: 0,
              padding: "0 0",
              textAlign: "center",
              textDecoration: "none",
              width: "100%",
            }}
            target="_blank"
            rel="noreferrer"
          >
            <IGHeader showSpinner={!spinnerDisabled} />
            <IGBody
              url={cleanUrlWithEndingSlash}
              backgroundUrl={backgroundUrl}
              linkText={linkText}
              linkTextDisabled={linkTextDisabled}
              backgroundBlurDisabled={backgroundBlurDisabled}
              backgroundBlurAnimationDisabled={
                retrying || backgroundBlurAnimationDisabled
              }
              backgroundBlurAnimationDuration={backgroundBlurAnimationDuration}
              softFilterDisabled={softFilterDisabled}
            />
            <IGFooter />
          </a>
        </div>
      </blockquote>
    </div>
  );
}
