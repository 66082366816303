import IProductIngredients from "../../../../domain/entities/product/IProductIngredients";
import "./styles.css";

interface IProductIngredientsProps {
  ingredients: IProductIngredients;
}

export default function ProductIngredients({
  ingredients,
}: IProductIngredientsProps) {
  function generateProductIngredientsBio() {
    if (ingredients.natural.length === 0 && ingredients.synthetic.length === 0)
      return (
        <p>
          We are working on getting the ingredients for this product. Check back
          later.
        </p>
      );

    if (ingredients.natural.length > 0 && ingredients.synthetic.length === 0)
      return (
        <p>
          This product is 100% natural and have{" "}
          <span className="natural">
            {ingredients.natural.length} ingredients
          </span>
        </p>
      );

    if (ingredients.natural.length === 0 && ingredients.synthetic.length > 0)
      return (
        <p>
          This product is 100% synthetic and have{" "}
          <span className="synthetic">
            {ingredients.synthetic.length} ingredients
          </span>
        </p>
      );

    if (ingredients.natural.length > 0 && ingredients.synthetic.length > 0)
      return (
        <p>
          This product has{" "}
          <span>
            {ingredients.natural.length + ingredients.synthetic.length}{" "}
            ingredients
          </span>{" "}
          of which{" "}
          <span className="natural">
            {ingredients.natural.length} are natural
          </span>{" "}
          and{" "}
          <span className="synthetic">
            {ingredients.synthetic.length} are synthetic
          </span>
        </p>
      );

    return (
      <p>
        We are working on getting the ingredients for this product. Check back
        later.
      </p>
    );
  }
  return (
    <div className="product-details-product-ingredients">
      <div className="product-ingredients-brief">
        {generateProductIngredientsBio()}
      </div>
      <div className="product-ingredients-list">
        {ingredients.natural.length > 0 && (
          <div className="natural">
            {ingredients.synthetic.length > 0 && (
              <div className="product-ingredient-type-heading">Natural</div>
            )}
            <ul>
              {ingredients.natural.map((ingredient, index) => (
                <li key={index}>{ingredient}</li>
              ))}
            </ul>
          </div>
        )}
        {ingredients.synthetic.length > 0 && (
          <div className="synthetic">
            {ingredients.natural.length > 0 && (
              <div className="product-ingredient-type-heading">Synthetic</div>
            )}
            <ul>
              {ingredients.synthetic.map((ingredient, index) => (
                <li key={index}>{ingredient}</li>
              ))}
            </ul>
          </div>
        )}
      </div>
    </div>
  );
}
