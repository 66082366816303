export default function BlogIcon() {
  return (
    <svg
      version="1.1"
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
      width="32px"
      height="24px"
      viewBox="0 0 32 32"
      xmlSpace="preserve"
      fill="#000000"
    >
      <path
        fill="currentColor"
        d="M22,18h-2c-0.276,0-0.5-0.224-0.5-0.5S19.724,17,20,17h2c0.276,0,0.5,0.224,0.5,0.5S22.276,18,22,18z M17.5,17.5c0-0.276-0.224-0.5-0.5-0.5h-2c-0.276,0-0.5,0.224-0.5,0.5S14.724,18,15,18h2C17.276,18,17.5,17.776,17.5,17.5z M12.5,17.5c0-0.276-0.224-0.5-0.5-0.5h-2c-0.276,0-0.5,0.224-0.5,0.5S9.724,18,10,18h2C12.276,18,12.5,17.776,12.5,17.5z M22.5,22.5c0-0.276-0.224-0.5-0.5-0.5h-2c-0.276,0-0.5,0.224-0.5,0.5S19.724,23,20,23h2C22.276,23,22.5,22.776,22.5,22.5z M17.5,22.5c0-0.276-0.224-0.5-0.5-0.5h-2c-0.276,0-0.5,0.224-0.5,0.5S14.724,23,15,23h2C17.276,23,17.5,22.776,17.5,22.5z M12.5,22.5c0-0.276-0.224-0.5-0.5-0.5h-2c-0.276,0-0.5,0.224-0.5,0.5S9.724,23,10,23h2C12.276,23,12.5,22.776,12.5,22.5z M22.5,12.5c0-0.276-0.224-0.5-0.5-0.5h-2c-0.276,0-0.5,0.224-0.5,0.5S19.724,13,20,13h2C22.276,13,22.5,12.776,22.5,12.5z M17.5,12.5c0-0.276-0.224-0.5-0.5-0.5h-2c-0.276,0-0.5,0.224-0.5,0.5S14.724,13,15,13h2C17.276,13,17.5,12.776,17.5,12.5z M12.5,12.5c0-0.276-0.224-0.5-0.5-0.5h-2c-0.276,0-0.5,0.224-0.5,0.5S9.724,13,10,13h2C12.276,13,12.5,12.776,12.5,12.5z M22.5,7.5 C22.5,7.224,22.276,7,22,7h-2c-0.276,0-0.5,0.224-0.5,0.5S19.724,8,20,8h2C22.276,8,22.5,7.776,22.5,7.5z M17.5,7.5 C17.5,7.224,17.276,7,17,7h-2c-0.276,0-0.5,0.224-0.5,0.5S14.724,8,15,8h2C17.276,8,17.5,7.776,17.5,7.5z M12.5,7.5 C12.5,7.224,12.276,7,12,7h-2C9.724,7,9.5,7.224,9.5,7.5S9.724,8,10,8h2C12.276,8,12.5,7.776,12.5,7.5z M28,0.5v31 c0,0.276-0.224,0.5-0.5,0.5h-17c-0.133,0-0.26-0.053-0.354-0.146l-6-6C4.053,25.76,4,25.633,4,25.5v-25C4,0.224,4.224,0,4.5,0h23 C27.776,0,28,0.224,28,0.5z M10,26H5.707L10,30.293V26z M27,1H5v24h5.5c0.276,0,0.5,0.224,0.5,0.5V31h16V1z"
      ></path>
    </svg>
  );
}
