import IApiResponse from "../entities/api/IApiResponse";
import IBlogFilter from "../entities/blog/IBlogFilter";
import IBlogMini from "../entities/blog/IBlogMini";
import IBlogRequestFilter from "../entities/blog/IBlogRequestFilter";
import IBlogService from "../entities/services/IBlogService";
import BlogRepository from "../repositories/blog-repository";

export default class BlogService implements IBlogService {
  private blogRepository: BlogRepository;

  constructor(blogRepository: BlogRepository) {
    this.blogRepository = blogRepository;
  }

  async getBlogs(filter?: IBlogRequestFilter): Promise<
    IApiResponse<{
      blogs: IBlogMini[];
      filters: IBlogFilter;
    }>
  > {
    try {
      const response = await this.blogRepository.getAllBlogs(filter);

      if (!response.status || !response.data) {
        return {
          status: response.status,
          message: response.message || "Failed to fetch blogs.",
          requestDuration: response.requestDuration || 0,
        };
      }

      const filters = this.generateBlogFilters(response.data || []);

      return {
        ...response,
        data: {
          blogs: response.data,
          filters: filters,
        },
      };
    } catch (error: any) {
      return {
        status: false,
        message: error.message || "Failed to fetch blogs.",
        requestDuration: 0,
      };
    }
  }

  private generateBlogFilters(blogs: IBlogMini[]): IBlogFilter {
    const categories: string[] = [];
    const authors: string[] = [];

    blogs.forEach((blog) => {
      blog.tags.forEach((tag) => {
        if (!categories.includes(tag)) categories.push(tag);
      });
      if (!authors.includes(blog.author_name)) authors.push(blog.author_name);
    });

    return {
      categories,
      authors,
      others: [],
    };
  }

  async getBlogBySlug(id: number) {
    return this.blogRepository.getBlogBySlug(id);
  }
}
