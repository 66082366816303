export default function RightArrow() {
  return (
    <svg
      width="18px"
      height="18px"
      viewBox="0 0 1024 1024"
      version="1.1"
      xmlns="http://www.w3.org/2000/svg"
      fill="#000000"
    >
      <path
        d="M256 120.768L306.432 64 768 512l-461.568 448L256 903.232 659.072 512z"
        fill="currentColor"
      ></path>
    </svg>
  );
}
