import apiErrorChecks from "../../utilities/api/api-error-checks";
import api from "../api";
import IApiResponse from "../entities/api/IApiResponse";
import IUserRepository from "../entities/repositories/IUserRepository";
import IGetUserProfile from "../entities/user/IGetUserProfile";

export default class UserRepository implements IUserRepository {
  async getUser(
    userId: number
  ): Promise<IApiResponse<IGetUserProfile | false>> {
    try {
      const response = await api.get<IGetUserProfile>(
        `/api/v1/user/profile/${userId}`
      );

      if (!response || !response.data)
        return {
          status: false,
          message:
            "An unknown error occurred on our end. Check your network connection and try again. Check if a firewall is blocking access to the server. Enable cookies for this website.",
          requestDuration: 0,
        };

      return {
        status: true,
        data: {
          ...response.data,
          wishlist: response.data.wishlist?.map((wishlistItem) => ({
            ...wishlistItem,
            images:
              wishlistItem.images?.sort((a, b) =>
                a.includes("main") ? -1 : 1
              ) || [],
          })),
        },
        message: "Successfully fetched user profile",
        requestDuration: 0,
      };
    } catch (error: any) {
      if (error?.response?.status === 404) {
        return {
          status: false,
          message: "404 | Not Found",
          requestDuration: 0,
          data: false,
        };
      }
      const apiErrors = apiErrorChecks(error);
      if (apiErrors.isApiError && apiErrors.message)
        return {
          status: false,
          message: apiErrors.message,
          requestDuration: 0,
        };

      return {
        status: false,
        message: `An unknown error occurred on our end. Check your network connection and try again. Detail: ${
          error?.response?.data?.message ||
          error?.response?.message ||
          error?.message
        }`,
        requestDuration: 0,
      };
    }
  }

  async followUser(userId: number): Promise<IApiResponse<boolean>> {
    try {
      await api.post(`/api/v1/follow`, {
        user_id: userId,
      });
      return {
        status: true,
        data: true,
        message: "Successfully followed user",
        requestDuration: 0,
      };
    } catch (error: any) {
      const apiErrors = apiErrorChecks(error);
      if (apiErrors.isApiError && apiErrors.message)
        return {
          status: false,
          message: apiErrors.message,
          requestDuration: 0,
        };

      return {
        status: false,
        message: `An unknown error occurred on our end. Check your network connection and try again. Detail: ${
          error?.response?.data?.message ||
          error?.response?.message ||
          error?.message
        }`,
        requestDuration: 0,
      };
    }
  }

  async unfollowUser(userId: number): Promise<IApiResponse<boolean>> {
    try {
      await api.delete(`/api/v1/unfollow/${userId}`);
      return {
        status: true,
        data: true,
        message: "Successfully unfollowed user",
        requestDuration: 0,
      };
    } catch (error: any) {
      const apiErrors = apiErrorChecks(error);
      if (apiErrors.isApiError && apiErrors.message)
        return {
          status: false,
          message: apiErrors.message,
          requestDuration: 0,
        };

      return {
        status: false,
        message: `An unknown error occurred on our end. Check your network connection and try again. Detail: ${
          error?.response?.data?.message ||
          error?.response?.message ||
          error?.message
        }`,
        requestDuration: 0,
      };
    }
  }
}
