import {
  PropsWithChildren,
  createContext,
  useContext,
  useEffect,
  useState,
} from "react";
import IUserContext from "../../domain/entities/context/IUserContext";
import IUser from "../../domain/entities/user/IUser";
import LocalStorage from "../../domain/storage/local-storage";
import LocalStorageKey from "../../domain/entities/storage/LocalStorageKey";
import { ILocalStorageWishlistCount } from "../../domain/entities/storage/ILocalStorageValue";
import AuthService from "../../domain/services/auth-service";

const UserContext = createContext<IUserContext>({} as IUserContext);

const localStorage = new LocalStorage();

const authService = new AuthService();

export default function UserProvider({ children }: PropsWithChildren) {
  function checkLoggedIn(): {
    user?: IUser;
    isLoggedIn: boolean;
    wishlistCount: number;
  } {
    const user = localStorage.get(LocalStorageKey.USER) as IUser | null;
    const wishlistCount = localStorage.get(
      LocalStorageKey.WISHLIST_COUNT
    ) as ILocalStorageWishlistCount | null;
    const isLoggedIn = user !== null;
    return {
      user: user || undefined,
      isLoggedIn,
      wishlistCount: wishlistCount ? wishlistCount.wishlistCount || 0 : 0,
    };
  }
  const [isLoggedIn, setIsLoggedIn] = useState<boolean>(
    checkLoggedIn().isLoggedIn
  );
  const [user, setUser] = useState<IUser | undefined>(checkLoggedIn().user);
  const [wishlistCount, setWishlistCount] = useState<number>(
    checkLoggedIn().wishlistCount
  );

  function setLogin(user: IUser, wishlistCount: number): boolean {
    localStorage.set(LocalStorageKey.USER, user);
    localStorage.set(LocalStorageKey.WISHLIST_COUNT, { wishlistCount });
    setUser(user);
    setWishlistCount(wishlistCount);
    setIsLoggedIn(true);
    return true;
  }

  async function setLogout(): Promise<boolean> {
    localStorage.remove(LocalStorageKey.USER);
    localStorage.remove(LocalStorageKey.WISHLIST_COUNT);
    setUser(undefined);
    setWishlistCount(0);
    setIsLoggedIn(false);
    await authService.logout();
    return false;
  }

  useEffect(() => {
    async function getMyProfile() {
      const response = await authService.getMyProfile();
      if (response?.status && response.data) {
        setLogin(
          response.data.user_details,
          response.data.wishlist?.length || 0
        );
      }
    }

    if (isLoggedIn) {
      getMyProfile();
    }
  }, [isLoggedIn]);

  return (
    <UserContext.Provider
      value={{
        isLoggedIn,
        user,
        setLogin,
        setLogout,
        wishlistCount,
        setWishlistCount,
      }}
    >
      {children}
    </UserContext.Provider>
  );
}

export const useUserContext = () => {
  return useContext(UserContext);
};
