import { Helmet } from "react-helmet-async";

interface ISeoProps {
  title: string;
  description: string;
  canonical?: string;
  dontIndex?: true;
  ldJson?: JSON;
}

export default function SEO(data: ISeoProps) {
  return (
    <div className="ldJson">
      {data.ldJson ? (
        <script
          type="application/ld+json"
          dangerouslySetInnerHTML={{ __html: JSON.stringify(data.ldJson) }}
        />
      ) : (
        <script
          type="application/ld+json"
          dangerouslySetInnerHTML={{
            __html: JSON.stringify({
              "@context": "https://schema.org/",
              "@type": "Fashion",
              name: `${data.title} | Boujeeher`,
              image: "../logo192.png",
              author: {
                "@type": "Company",
                name: "Boujeeher",
              },
              datePublished: "2024-01-01",
              description: data.description,
            }),
          }}
        />
      )}
      <Helmet>
        <title>{data.title} | Boujeeher</title>
        <meta property="og:title" content={`${data.title} | Boujeeher`} />
        <meta name="twitter:title" content={`${data.title} | Boujeeher`} />

        <meta name="description" content={data.description} />
        <meta name="twitter:description" content={data.description} />
        <meta property="og:description" content={data.description} />

        {data.dontIndex ? (
          <meta name="robots" content="noindex" />
        ) : (
          <meta name="robots" content="index, follow" />
        )}
        {data.canonical ? (
          <link
            rel="canonical"
            href={`https://www.boujeeher.com/${data.canonical}`}
          />
        ) : (
          <link rel="canonical" href="https://www.boujeeher.com/" />
        )}
      </Helmet>
    </div>
  );
}
