import IApiResponse from "../entities/api/IApiResponse";
import ILandingTrendingProduct from "../entities/landing/ILandingTrendingProduct";
import ILandingService from "../entities/services/ILandingService";
import LandingRepository from "../repositories/landing-repository";

export default class LandingService implements ILandingService {
  private landingRepository: LandingRepository;

  constructor(landingRepository: LandingRepository) {
    this.landingRepository = landingRepository;
  }

  async getDiscoverItem() {
    return await this.landingRepository.getDiscoverItem();
  }

  async getEssentialItems() {
    return await this.landingRepository.getEssentialItems();
  }

  async getTrendingProducts(category_name: string) {
    return await this.landingRepository.getTrendingProducts(category_name);
  }

  async getNewProducts(
    category_name: string
  ): Promise<IApiResponse<ILandingTrendingProduct[]>> {
    return await this.landingRepository.getNewProducts(category_name);
  }

  async getFeaturedProducts(): Promise<
    IApiResponse<ILandingTrendingProduct[]>
  > {
    return await this.landingRepository.getFeaturedProducts();
  }

  async getFeaturedItems() {
    return await this.landingRepository.getFeaturedItems();
  }

  async getAdditionalWidgets() {
    return await this.landingRepository.getAdditionalWidgets();
  }

  async getCommunities() {
    return await this.landingRepository.getCommunities();
  }
}
