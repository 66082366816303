import { NavLink } from "react-router-dom";
import ILandingDiscover from "../../../../../domain/entities/landing/ILandingDiscover";
import "./styles.css";

interface ExpandableCarouselItemProps {
  data: ILandingDiscover;
}

export default function ExpandableCarouselItem({
  data,
}: ExpandableCarouselItemProps) {
  return (
    <div className="expandable-carousel-item">
      <NavLink to={data.linkTo} state={{ from: window.location.pathname }}>
        <div className="expandable-carousel-item-heading">{data.heading}</div>
        <div className="expandable-carousel-item-body">
          <img
            src={encodeURI(data.imageUrl)}
            alt={data.heading}
            onError={({ currentTarget }) => {
              currentTarget.onerror = null;
              currentTarget.src = "/assets/placeholders/product.svg";
            }}
          />
        </div>
        <div className="grey-out"></div>
      </NavLink>
    </div>
  );
}
