import { useEffect, useState } from "react";
import "./styles.css";
import IProductSocialMedia, {
  ISocialMediaPlatform,
} from "../../../domain/entities/product/IProductSocialMedia";
import SocialPlatformButton from "./social-platform-button";
import YoutubeEmbed from "./youtube";
import InstagramEmbed from "./instagram";
import { TikTokEmbed } from "react-social-media-embed";
import parseYoutubeVideoIdFromUrl from "../../../utilities/url/parseYoutubeVideoIdFromUrl";

interface IProductSocialProps {
  productSocials: IProductSocialMedia;
}

const MAX_CONTENT = 4;

export default function ProductSocial({ productSocials }: IProductSocialProps) {
  const [currentPlatform, setCurrentPlatform] = useState<string | undefined>(
    undefined
  );
  const [socialPlatforms, setSocialPlatforms] = useState<
    ISocialMediaPlatform[]
  >([]);
  const [loadedItems, setLoadedItems] = useState(MAX_CONTENT);

  function getEmbedPlatform(media: string, platform: string): React.ReactNode {
    switch (platform) {
      case "youtube":
        return <YoutubeEmbed videoId={parseYoutubeVideoIdFromUrl(media)} />;
      case "instagram":
        return <InstagramEmbed videoId={media} />;
      case "tiktok":
        return <TikTokEmbed url={media} />;
      default:
        return <div />;
    }
  }

  useEffect(() => {
    if (!productSocials) return;
    const platforms = Object.keys(productSocials).map((platform) => ({
      name: platform,
      icon:
        platform === "youtube"
          ? "/assets/social-provider-logos/youtube.svg"
          : platform === "tiktok"
          ? "/assets/social-provider-logos/tiktok.png"
          : "",
    }));
    setSocialPlatforms(platforms);
    setCurrentPlatform(platforms.length > 0 ? platforms[0].name : undefined);
  }, [productSocials]);

  if (socialPlatforms.length === 0)
    return <div className="product-social"></div>;

  return (
    <div className="product-social">
      <h2>Checkout What's Trending in Social Media</h2>

      {socialPlatforms && socialPlatforms.length > 0 && (
        <div className="product-social-platforms-switch">
          {socialPlatforms.map((platform, index) => (
            <SocialPlatformButton
              key={`social-media-platform-${platform.name}-${index}`}
              data={platform}
              setCurrentPlatform={() => {
                setLoadedItems(MAX_CONTENT);
                setCurrentPlatform(platform.name);
              }}
              isCurrent={platform.name === currentPlatform}
            />
          ))}
        </div>
      )}
      {productSocials && currentPlatform && (
        <div className="product-social-content">
          {productSocials[currentPlatform].map(
            (media, index) =>
              index < loadedItems && (
                <div key={index} className="product-social-content-item">
                  {getEmbedPlatform(media, currentPlatform)}
                </div>
              )
          )}
          {loadedItems < productSocials[currentPlatform].length && (
            <button
              onClick={() =>
                setLoadedItems((oldLoadedItems) => oldLoadedItems + MAX_CONTENT)
              }
              className="product-socials-show-more"
            >
              Load More
            </button>
          )}
        </div>
      )}

      {socialPlatforms.length === 0 && (
        <div className="product-social-content">
          <h3>
            No social media content available for this product at the moment
          </h3>
        </div>
      )}
    </div>
  );
}
