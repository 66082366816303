import IApiResponse from "../entities/api/IApiResponse";
import IBrand from "../entities/brand/IBrand";
import IBrandList from "../entities/brand/IBrandList";
import IGetAllBrandsRequest from "../entities/brand/IGetAllBrandsRequest";
import IBrandsService from "../entities/services/IBrandsService";
import BrandRepository from "../repositories/brand-repository";

export default class BrandService implements IBrandsService {
  private brandRepository: BrandRepository;

  constructor() {
    this.brandRepository = new BrandRepository();
  }

  async getAllBrands(
    filter: IGetAllBrandsRequest
  ): Promise<IApiResponse<IBrand[]>> {
    try {
      const response = await this.brandRepository.getAllBrands(filter);

      return {
        ...response,
        data: response.data?.sort((a, b) => a.name.localeCompare(b.name)),
      };
    } catch (error) {
      return {
        status: false,
        message: "Error fetching brands.",
        requestDuration: 0,
      };
    }
  }

  filterBrandsByLetter(
    brandsList: IBrand[],
    letter?: string | undefined
  ): IBrandList {
    const filteredBrands: IBrandList = {};
    const brands = brandsList || [];

    brands.forEach((brand) => {
      const firstLetter = brand.name[0].match(/[a-zA-Z]/)
        ? brand.name[0].toUpperCase()
        : "#";

      if (letter) {
        if (firstLetter === letter.toUpperCase()) {
          if (!filteredBrands[firstLetter]) {
            filteredBrands[firstLetter] = [];
          }
          filteredBrands[firstLetter].push(brand);
        }
      } else {
        if (!filteredBrands[firstLetter]) {
          filteredBrands[firstLetter] = [];
        }
        filteredBrands[firstLetter].push(brand);
      }
    });

    const sortedKeys = Object.keys(filteredBrands).sort((a, b) => {
      if (a === "#") return 1;
      if (b === "#") return -1;
      return a.localeCompare(b);
    });

    const sortedBrands: IBrandList = {};
    sortedKeys.forEach((key) => {
      sortedBrands[key] = filteredBrands[key];
    });

    return sortedBrands;
  }

  async getBrand(slug: string) {
    try {
      return this.brandRepository.getBrand(slug);
    } catch (error) {
      return {
        status: false,
        message: "Error fetching brand.",
        requestDuration: 0,
      };
    }
  }
}
