import apiErrorChecks from "../../utilities/api/api-error-checks";
import api from "../api";
import IApiResponse from "../entities/api/IApiResponse";
import IBrand from "../entities/brand/IBrand";
import IBrandFull, { BrandManager } from "../entities/brand/IBrandFull";
import IGetAllBrandsRequest from "../entities/brand/IGetAllBrandsRequest";
import IBrandsRepository from "../entities/repositories/IBrandsRepository";

export default class BrandRepository implements IBrandsRepository {
  async getAllBrands(
    filter: IGetAllBrandsRequest
  ): Promise<IApiResponse<IBrand[]>> {
    try {
      const response = await api.get<{
        brands: IBrand[];
        requestDuration: number;
      }>("/api/v1/brands", {
        params: filter,
      });

      if (!response || !response.data)
        return {
          status: false,
          message: "Failed to fetch products from the search query",
          requestDuration: 0,
        };

      return {
        status: true,
        message: "Brands fetched successfully.",
        data: response.data.brands,
        requestDuration: response.data.requestDuration,
      };
    } catch (error: any) {
      const apiErrors = apiErrorChecks(error);
      if (apiErrors.isApiError && apiErrors.message)
        return {
          status: false,
          message: apiErrors.message,
          requestDuration: 0,
        };

      return {
        status: false,
        message: `An unknown error occurred on our end. Check your network connection and try again. Detail: ${
          error?.response?.data?.message ||
          error?.response?.message ||
          error?.message
        }`,
        requestDuration: 0,
      };
    }
  }

  async getBrand(slug: string): Promise<IApiResponse<IBrandFull | false>> {
    try {
      const response = await api.get<IBrandFull>("/api/v1/brand", {
        params: { slug },
      });

      if (!response || !response.data)
        return {
          status: false,
          message: "Failed to fetch the brand",
          requestDuration: 0,
        };

      const random = Math.random();

      return {
        status: true,
        message: "Brand fetched successfully.",
        data: {
          ...response.data,
          banner: "",
          bannerLinkTo: "",
          caption: "",
          displayBrandName: true,
          logoBackground: "",
          pageManagedBy:
            random > 0.7
              ? BrandManager.BRAND
              : random > 0.35
              ? BrandManager.BOUJEEHER
              : BrandManager.NOT_MANAGED,
        },
        requestDuration: 0,
      };
    } catch (error: any) {
      if (error?.response?.status === 404) {
        return {
          status: false,
          message: "404 | Not Found",
          requestDuration: 0,
          data: false,
        };
      }
      const apiErrors = apiErrorChecks(error);
      if (apiErrors.isApiError && apiErrors.message)
        return {
          status: false,
          message: apiErrors.message,
          requestDuration: 0,
        };

      return {
        status: false,
        message: `An unknown error occurred on our end. Check your network connection and try again. Detail: ${
          error?.response?.data?.message ||
          error?.response?.message ||
          error?.message
        }`,
        requestDuration: 0,
      };
    }
  }
}
