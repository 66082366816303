import Spinner from "../spinner";
import "./styles.css";

export default function SpinnerFullPage() {
  return (
    <div className="full-page-dropdown">
      <Spinner />
    </div>
  );
}
