import apiErrorChecks from "../../utilities/api/api-error-checks";
import api, { refreshApi } from "../api";
import IApiResponse from "../entities/api/IApiResponse";
import IAuthRepository from "../entities/repositories/IAuthRepository";
import IGetUserProfile from "../entities/user/IGetUserProfile";
import IUser from "../entities/user/IUser";

export default class AuthRepository implements IAuthRepository {
  async refreshToken(): Promise<IApiResponse<boolean>> {
    try {
      const response = await refreshApi.put<any>("/api/v1/auth/refresh");

      if (!response)
        return {
          status: false,
          data: false,
          message:
            "An unknown error occurred on our end. Check your network connection and try again.",
          requestDuration: 0,
        };

      return {
        status: true,
        data: true,
        message: "Successfully refreshed token",
        requestDuration: 0,
      };
    } catch (error: any) {
      if (error?.response?.status === 401)
        return {
          status: false,
          message: "Session expired. Please log in again.",
          requestDuration: 0,
          data: false,
        };

      const apiErrors = apiErrorChecks(error);
      if (apiErrors.isApiError && apiErrors.message)
        return {
          status: false,
          message: apiErrors.message,
          requestDuration: 0,
          data: false,
        };

      return {
        status: false,
        message:
          error?.response?.data?.message ||
          error?.response?.message ||
          error?.message ||
          "An unknown error occurred on our end. Check your network connection and try again.",
        requestDuration: 0,
        data: false,
      };
    }
  }

  async logout(): Promise<IApiResponse<boolean>> {
    try {
      const response = await api.delete<{ message: string }>(
        "/api/v1/auth/logout"
      );

      if (!response)
        return {
          status: false,
          data: false,
          message:
            "An unknown error occurred on our end. Check your network connection and try again.",
          requestDuration: 0,
        };

      return {
        status: true,
        data: true,
        message: response.data?.message || "Successfully logged out",
        requestDuration: 0,
      };
    } catch (error: any) {
      if (error?.response?.status === 401)
        return {
          status: false,
          message: "Session expired. Please log in again.",
          requestDuration: 0,
          data: false,
        };

      const apiErrors = apiErrorChecks(error);
      if (apiErrors.isApiError && apiErrors.message)
        return {
          status: false,
          message: apiErrors.message,
          requestDuration: 0,
          data: false,
        };

      return {
        status: false,
        message:
          error?.response?.data?.message ||
          error?.response?.message ||
          error?.message ||
          "An unknown error occurred on our end. Check your network connection and try again.",
        requestDuration: 0,
        data: false,
      };
    }
  }

  async getMyProfile(): Promise<IApiResponse<IGetUserProfile>> {
    try {
      const response = await api.get<IGetUserProfile>("/api/v1/user/profile");

      if (!response || !response.data)
        return {
          status: false,
          message:
            "An unknown error occurred on our end. Check your network connection and try again. Check if a firewall is blocking access to the server. Enable cookies for this website.",
          requestDuration: 0,
        };

      return {
        status: true,
        data: {
          ...response.data,
          wishlist: response.data.wishlist?.map((wishlistItem) => ({
            ...wishlistItem,
            images:
              wishlistItem.images?.sort((a, b) =>
                a.includes("main") ? -1 : 1
              ) || [],
          })),
        },
        message: "Successfully fetched user profile",
        requestDuration: 0,
      };
    } catch (error: any) {
      const apiErrors = apiErrorChecks(error);
      if (apiErrors.isApiError && apiErrors.message)
        return {
          status: false,
          message: apiErrors.message,
          requestDuration: 0,
        };

      return {
        status: false,
        message:
          error?.response?.data?.message ||
          error?.response?.message ||
          error?.message ||
          "An unknown error occurred on our end. Check your network connection and try again.",
        requestDuration: 0,
      };
    }
  }

  async updateMyProfile(
    data: IUser,
    userImage?: File
  ): Promise<IApiResponse<null>> {
    try {
      const formData = new FormData();
      if (userImage) formData.append("image", userImage);
      formData.append("first_name", data.first_name || "");
      formData.append("surname", data.surname || "");
      formData.append("short_description", data.short_description || "");
      formData.append("socials_facebook", data.socials?.facebook || "");
      formData.append("socials_instagram", data.socials?.instagram || "");
      formData.append("socials_tiktok", data.socials?.tiktok || "");
      formData.append("socials_twitter", data.socials?.twitter || "");
      formData.append("wishlist_type", data.wishlist_type);

      const response = await api.post<null>("/api/v1/user/profile", formData);

      if (!response)
        return {
          status: false,
          message:
            "An unknown error occurred on our end. Check your network connection and try again. Check if a firewall is blocking access to the server. Enable cookies for this website.",
          requestDuration: 0,
        };

      return {
        status: true,
        data: null,
        message: "Successfully updated profile",
        requestDuration: 0,
      };
    } catch (error: any) {
      const apiErrors = apiErrorChecks(error);
      if (apiErrors.isApiError && apiErrors.message)
        return {
          status: false,
          message: apiErrors.message,
          requestDuration: 0,
        };

      return {
        status: false,
        message:
          error?.response?.data?.message ||
          error?.response?.message ||
          error?.message ||
          "An unknown error occurred on our end. Check your network connection and try again.",
        requestDuration: 0,
      };
    }
  }
}
