import { useEffect, useRef, useState } from "react";
import "./styles.css";
import IApiStatus from "../../../domain/entities/api/IApiStatus";
import ProductService from "../../../domain/services/product-service";
import ErrorComponent from "../../error";
import ShadeFinderProductsList from "../shade-finder-products-list";
import IShadeFinderProduct from "../../../domain/entities/product/IShadeFinderProduct";

interface IShadeFinderProductsProps {
  variantId: number;
  productService: ProductService;
}

export default function ShadeFinderProducts({
  variantId,
  productService,
}: IShadeFinderProductsProps) {
  const [products, setProducts] = useState<IShadeFinderProduct[]>([]);
  const [apiStatus, setApiStatus] = useState<IApiStatus>({
    loading: true,
    status: false,
    message: "",
  });
  const productsRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    async function fetchSimilarShades() {
      try {
        setApiStatus({
          loading: true,
          status: false,
          message: "",
        });
        setProducts([]);

        const response =
          await productService.searchSimilarProductsBasedOnVariant(variantId);

        if (!response) {
          setApiStatus({
            loading: false,
            status: false,
            message: "An error occurred while searching for products.",
          });
          return;
        }

        if (!response.status || !response.data) {
          setApiStatus({
            loading: false,
            status: response.status,
            message: response.message,
          });
          return;
        }

        setProducts(response.data);
        const productsElementLocation = productsRef.current?.offsetTop;
        if (productsElementLocation && response.data.length > 0) {
          window.scrollTo({
            top: productsElementLocation - 200,
            behavior: "smooth",
          });
        }

        setApiStatus({
          loading: false,
          status: true,
          message: response.message,
        });
      } catch (error) {
        setApiStatus({
          loading: false,
          status: false,
          message: "An error occurred while searching for products.",
        });
      }
    }

    fetchSimilarShades();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [variantId]);

  return (
    <div
      id="shade-finder-products"
      className="shade-finder-products"
      ref={productsRef}
    >
      <div className="shade-finder-products-heading">
        We Recommend You These
      </div>

      {apiStatus.loading ? null : !apiStatus.status ? (
        <ErrorComponent
          title="An error occurred"
          description="An error occurred while searching for products."
          errorCode={apiStatus.message}
        />
      ) : products.length === 0 ? (
        <ErrorComponent
          title="No matching products found for this shade"
          description="Check back later? We are as unique as you are! and will be back with more shades soon."
          errorCode="Try some other shade in the meantime >.<"
        />
      ) : null}

      <ShadeFinderProductsList
        products={products}
        isLoading={apiStatus.loading}
      />
    </div>
  );
}
