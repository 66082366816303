import { useState, useRef, useEffect } from "react";
import ILandingTrendingProduct from "../../domain/entities/landing/ILandingTrendingProduct";
import ProductsCarouselItem from "./products-carousel-item";
import "./styles.css";
import LeftArrow from "../../assets/icons/left-arrow";
import RightArrow from "../../assets/icons/right-arrow";
import ProductService from "../../domain/services/product-service";

interface IProductsCarouselProps {
  data: ILandingTrendingProduct[];
  type: string;
}

const MIN_CAROUSEL_ITEM_WIDTH = 160;
const MAX_CAROUSEL_ITEM_WIDTH = 250;

const productService = new ProductService();

export default function ProductsCarousel({
  data,
  type,
}: IProductsCarouselProps) {
  const [displayNextButton, setDisplayNextButton] = useState(false);
  const [displayPrevButton, setDisplayPrevButton] = useState(false);
  const [carouselItemWidth, setCarouselItemWidth] = useState<number>(
    MIN_CAROUSEL_ITEM_WIDTH
  );
  const carouselRef = useRef<HTMLUListElement>(null);

  function checkScrollButtonVisibility() {
    if (data.length === 0) return;

    const minimumCarouselWidth = carouselRef.current?.scrollWidth || 0;
    const currentScrollLeft = carouselRef.current?.scrollLeft || 0;
    const currentClientWidth = carouselRef.current?.clientWidth || 0;

    setDisplayPrevButton(currentScrollLeft > 0);
    setDisplayNextButton(minimumCarouselWidth > currentClientWidth);
  }

  useEffect(() => {
    const currentCarouselRef = carouselRef.current;
    function checkScrollButtonVisibility() {
      if (data.length === 0) return;

      const minimumCarouselWidth = carouselRef.current?.scrollWidth || 0;
      const currentScrollLeft = carouselRef.current?.scrollLeft || 0;
      const currentClientWidth = carouselRef.current?.clientWidth || 0;

      setDisplayPrevButton(currentScrollLeft > 0);
      setDisplayNextButton(minimumCarouselWidth > currentClientWidth);
      if (currentScrollLeft + currentClientWidth >= minimumCarouselWidth) {
        setDisplayNextButton(false);
      }
    }

    checkScrollButtonVisibility();
    window.addEventListener("resize", checkScrollButtonVisibility);
    currentCarouselRef?.addEventListener("scroll", checkScrollButtonVisibility);

    return () => {
      window.removeEventListener("resize", checkScrollButtonVisibility);
      currentCarouselRef?.removeEventListener(
        "scroll",
        checkScrollButtonVisibility
      );
    };
  }, [data]);

  function handleNextButtonClick() {
    const carousel = carouselRef.current;
    if (carousel) {
      carousel.scrollBy({
        top: 0,
        left: 310,
        behavior: "smooth",
      });
    }
    checkScrollButtonVisibility();
  }
  function handlePrevButtonClick() {
    const carousel = carouselRef.current;
    if (carousel) {
      carousel.scrollBy({
        top: 0,
        left: -310,
        behavior: "smooth",
      });
    }

    checkScrollButtonVisibility();
  }

  function findCarouselItemWidth() {
    const carousel = carouselRef.current;
    if (!carousel) return;

    const displayedWidth = carousel.clientWidth;

    let widthOfItem = MIN_CAROUSEL_ITEM_WIDTH;
    const numberOfItemsToBeShown = Math.round(displayedWidth / widthOfItem);

    widthOfItem =
      (displayedWidth - 10 * numberOfItemsToBeShown) / numberOfItemsToBeShown +
      1;
    widthOfItem = Math.min(widthOfItem, MAX_CAROUSEL_ITEM_WIDTH);

    setCarouselItemWidth(widthOfItem);
  }

  useEffect(() => {
    findCarouselItemWidth();
    window.addEventListener("resize", findCarouselItemWidth);

    return () => {
      window.removeEventListener("resize", findCarouselItemWidth);
    };
  }, []);

  return (
    <div className="products-carousel">
      <div
        className={`scroll-button left${displayPrevButton ? " visible" : ""}`}
        onClick={handlePrevButtonClick}
      >
        <LeftArrow />
      </div>
      <ul className="products" ref={carouselRef}>
        {data.map((product, index) => (
          <ProductsCarouselItem
            key={`${type}-product-${index}`}
            data={product}
            itemWidth={carouselItemWidth}
            productService={productService}
          />
        ))}
      </ul>
      <div
        className={`scroll-button right${displayNextButton ? " visible" : ""}`}
        onClick={handleNextButtonClick}
      >
        <RightArrow />
      </div>
    </div>
  );
}
