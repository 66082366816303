import { useEffect, useState } from "react";
import Breadcrumbs from "../../../components/breadcrumbs";
import SpinnerFullPage from "../../../components/loading/spinner-fullpage";
import { useUserContext } from "../../../contexts/user";
import { Navigate } from "react-router-dom";
import SEO from "../../../components/seo";
import "./styles.css";
import TopBarLoading from "../../../components/loading/top-bar";
import SessionStorage from "../../../domain/storage/session-storage";
import SessionStorageKey from "../../../domain/entities/storage/SessionStorageKey";
import { ISessionState } from "../../../domain/entities/storage/ISessionStorageValue";
import AuthRepository from "../../../domain/repositories/auth-repository";

const authRepository = new AuthRepository();
const sessionStorage = new SessionStorage();

export default function VerifyLogin() {
  const { setLogin, isLoggedIn } = useUserContext();
  const [error, setError] = useState("");
  const [redirectToLogin, setRedirectToLogin] = useState(false);
  const sessionState = sessionStorage.get(
    SessionStorageKey.STATE
  ) as ISessionState | null;

  useEffect(() => {
    async function verifyUser() {
      try {
        const response = await authRepository.getMyProfile();

        if (response.status && response.data) {
          setLogin(
            response.data.user_details,
            response.data.wishlist?.length || 0
          );
        } else {
          setError(
            `We were unable to verify your account. Redirecting you to login page in 3 seconds, ${response.message}`
          );
        }
      } catch (error) {
        setError(
          "We were unable to verify your account. Redirecting you to login page in 3 seconds"
        );
      }
    }

    verifyUser();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (error)
      setTimeout(() => {
        setRedirectToLogin(true);
      }, 3000);
  }, [error]);

  if (isLoggedIn) return <Navigate to={sessionState?.from || "/"} />;
  if (redirectToLogin) return <Navigate to="/account/login" />;

  return (
    <div className="login-page">
      {!error && <TopBarLoading />}
      <SEO
        title="Login or Connect Your Account"
        description="Login or connect your account to Boujeeher to get started with your shopping experience."
      />
      <Breadcrumbs
        data={[{ label: "Logging In", to: window.location.pathname }]}
      />
      {error ? (
        <div className="error-content">
          <div className="error-heading">{error}</div>
        </div>
      ) : (
        <SpinnerFullPage />
      )}
    </div>
  );
}
