import ICategory from "../../../domain/entities/category/ICategory";
import "./styles.css";

interface ICategoryInformationProps {
  data: ICategory;
}

export default function CategoryInformation({
  data,
}: ICategoryInformationProps) {
  return (
    <div className="category-information">
      <div className="category-banner">
        <img className="category-logo-image" src={data.logo} alt={data.name} />
      </div>
      <div className="category-details">
        <h1>{data.name}</h1>
        <p>{data.description}</p>
      </div>
    </div>
  );
}
