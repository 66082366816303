/**
 * Reduces quantity to a string with a suffix
 *
 * Example:
 *  1000 -> 1K
 *  1000000 -> 1M
 *  1251851085 -> 1.25B
 *  12600 -> 12.6K
 *
 * @param quantity Quantity to be shortened
 */
export default function shortenQuantityToString(quantity: number): string {
  if (quantity < 1000) {
    return quantity.toString();
  }

  if (quantity < 1000000) {
    const shortened = (quantity / 1000).toFixed(1);
    return shortened.endsWith(".0")
      ? shortened.slice(0, -2) + "K"
      : shortened + "K";
  }

  if (quantity < 1000000000) {
    const shortened = (quantity / 1000000).toFixed(2);
    return shortened.endsWith(".00")
      ? shortened.slice(0, -3) + "M"
      : shortened + "M";
  }

  const shortened = (quantity / 1000000000).toFixed(2);
  return shortened.endsWith(".00")
    ? shortened.slice(0, -3) + "B"
    : shortened + "B";
}
