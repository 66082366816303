import IceCreamIcon from "../../assets/icons/ice-cream";
import "./styles.css";

interface IErrorComponent {
  title: string;
  description: string;
  errorCode?: string;
}

export default function ErrorComponent(error: IErrorComponent) {
  return (
    <div className="error-component">
      <IceCreamIcon />
      <div className="error-heading">{error.title}</div>
      <div className="error-description">{error.description}</div>
      <div className="error-code">{error.errorCode}</div>
    </div>
  );
}
