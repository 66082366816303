import { NavLink } from "react-router-dom";
import "./styles.css";

export default function AppLogo() {
  return (
    <div className="app-logo">
      <h1>
        <NavLink to="/" state={{ from: window.location.pathname }}>
          Boujeeher
        </NavLink>
      </h1>
    </div>
  );
}
