import { useState } from "react";
import "./styles.css";
import ErrorComponent from "../../../error";
import ProductDescription from "../product-description";
import ProductIngredients from "../product-ingredients";
import IProductIngredients from "../../../../domain/entities/product/IProductIngredients";
import IProductReview from "../../../../domain/entities/product/IProductReview";
import ProductReviews from "../../product-reviews";
import IProductReviewBrief from "../../../../domain/entities/product/IProductReviewBrief";
import IApiStatus from "../../../../domain/entities/api/IApiStatus";
import StarRatings from "react-star-ratings";

interface IProductDetailsSwitchProps {
  description: string;
  ingredients: IProductIngredients;
  reviews: {
    apiStatus: IApiStatus;
    reviews: IProductReview[];
    reviewBrief?: IProductReviewBrief;
  };
}

enum ProductDetailsSwitchTabs {
  DETAILS = "details",
  INGREDIENTS = "ingredients",
  REVIEWS = "reviews",
}

export default function ProductDetailsSwitch({
  description,
  ingredients,
  reviews,
}: IProductDetailsSwitchProps) {
  const [currentTab, setCurrentTab] = useState<ProductDetailsSwitchTabs>(
    ProductDetailsSwitchTabs.DETAILS
  );

  function displayTabContent() {
    switch (currentTab) {
      case ProductDetailsSwitchTabs.DETAILS:
        return <ProductDescription description={description} />;

      case ProductDetailsSwitchTabs.INGREDIENTS:
        return <ProductIngredients ingredients={ingredients} />;

      case ProductDetailsSwitchTabs.REVIEWS:
        return (
          <ProductReviews
            apiStatus={reviews.apiStatus}
            reviewBrief={reviews.reviewBrief}
            data={reviews.reviews}
          />
        );

      default:
        return (
          <ErrorComponent
            title="This tab is not available"
            description="We are unable to show you this at the moment"
          />
        );
    }
  }

  return (
    <div className="product-details-switch">
      <div className="product-details-switch-action">
        <div
          className={`product-details-switch-action-button${
            currentTab === ProductDetailsSwitchTabs.DETAILS ? " active" : ""
          }`}
          onClick={() => setCurrentTab(ProductDetailsSwitchTabs.DETAILS)}
        >
          Details
        </div>
        <div
          onClick={() => setCurrentTab(ProductDetailsSwitchTabs.INGREDIENTS)}
          className={`product-details-switch-action-button${
            currentTab === ProductDetailsSwitchTabs.INGREDIENTS ? " active" : ""
          }`}
        >
          Ingredients
        </div>
        <div
          onClick={() => setCurrentTab(ProductDetailsSwitchTabs.REVIEWS)}
          className={`product-details-switch-action-button${
            currentTab === ProductDetailsSwitchTabs.REVIEWS ? " active" : ""
          }`}
        >
          User Reviews{" "}
          <StarRatings
            rating={reviews.reviewBrief?.totalRating || 0}
            starDimension="0.8em"
            starSpacing="0px"
            starRatedColor="var(--theme-color)"
          />{" "}
          {reviews.reviewBrief?.totalRating || 0}
        </div>
      </div>
      <div className="product-details-switch-content">
        {displayTabContent()}
      </div>
    </div>
  );
}
