import { useEffect, useState } from "react";
import IApiStatus from "../../../domain/entities/api/IApiStatus";
import LandingService from "../../../domain/services/landing-service";
import "./styles.css";
import IProductMini from "../../../domain/entities/product/IProductMini";
import SkeletonLoading from "../../loading/skeleton-loading";
import ProductsCarousel from "../../products-carousel";
import ErrorComponentTiny from "../../error/tiny";

interface ILandingFeaturedProps {
  landingService: LandingService;
}

export default function LandingFeatured({
  landingService,
}: ILandingFeaturedProps) {
  const [featuredItems, setFeaturedItems] = useState<IProductMini[]>([]);
  const [apiDetails, setApiDetails] = useState<IApiStatus>({
    status: false,
    message: "",
    loading: true,
  });

  useEffect(() => {
    async function fetchFeaturedItems() {
      setApiDetails({
        status: false,
        loading: true,
        message: "",
      });
      const response = await landingService.getFeaturedProducts();

      if (response.status && response.data) {
        setFeaturedItems(response.data);
      }

      setApiDetails({
        status: response.status,
        message: response.message || "Unknown Error Occurred",
        loading: false,
      });
    }

    fetchFeaturedItems();
  }, [landingService]);

  return (
    <div className="landing-featured">
      <div className="landing-featured-header">Featured Products</div>
      {apiDetails.loading ? (
        <div className="products-carousel">
          <ul className="products">
            {Array.from({ length: 10 }).map((_, index) => (
              <li key={`sk-ld-new-${index}`}>
                <SkeletonLoading />
              </li>
            ))}
          </ul>
        </div>
      ) : apiDetails.status && featuredItems ? (
        featuredItems.length === 0 ? (
          <ErrorComponentTiny
            title="Check back later for featured products."
            description="Welcome to Boujeeher! While there aren't any featured products, fear not! There’s a whole world of exciting discoveries waiting for you. Explore other categories, from fashion to gadgets, home decor to wellness. Who knows? The next big trend might be just a click away. And don’t forget to check back later—we’re always updating our offerings. Happy exploring! 🌟"
          />
        ) : (
          <ProductsCarousel data={featuredItems} type="featured" />
        )
      ) : (
        <ErrorComponentTiny
          title="An Error Occurred"
          description="Try again later"
        />
      )}
    </div>
  );
}
