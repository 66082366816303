import { useEffect, useState } from "react";
import "./styles.css";
import HeaderDrawerContent from "./drawer-content";
import CloseIcon2 from "../../../assets/icons/close-2";

interface IHeaderDrawerProps {
  visible: boolean;
  close(): void;
}

export default function HeaderDrawer({ visible, close }: IHeaderDrawerProps) {
  const [isVisible, setIsVisible] = useState(visible);
  const [closing, setClosing] = useState(false);

  useEffect(() => {
    if (visible) {
      setIsVisible(true);
      document.body.style.overflow = "hidden";
    } else {
      setClosing(true);
      setTimeout(() => {
        setIsVisible(false);
        setClosing(false);
        document.body.style.overflow = "auto";
      }, 250);
    }
  }, [visible]);

  return (
    <div className="drawer-header">
      <div
        className={`drawer-header-menu${isVisible ? " visible" : ""}${
          closing ? " closing" : ""
        }`}
      >
        <div className="drawer-heading">
          <div className="app-logo">
            <h2>Boujeeher</h2>
          </div>
          <div className="close-drawer" onClick={close} title="Close this menu">
            <CloseIcon2 />
          </div>
        </div>
        <HeaderDrawerContent />
      </div>
      <div className="grey-out" onClick={close}></div>
    </div>
  );
}
