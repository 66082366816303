import apiErrorChecks from "../../utilities/api/api-error-checks";
import api from "../api";
import IApiResponse from "../entities/api/IApiResponse";
import ICategory from "../entities/category/ICategory";
import IGetAllCategoriesRequest from "../entities/category/IGetAllCategoriesRequest";
import ICategoryRepository from "../entities/repositories/ICategoryRepository";

export default class CategoryRepository implements ICategoryRepository {
  async getAllCategories(
    filter: IGetAllCategoriesRequest
  ): Promise<IApiResponse<ICategory[]>> {
    try {
      const response = await api.get<{ categories: ICategory[] }>(
        "/api/v1/categories",
        {
          params: filter,
        }
      );

      if (!response || !response.data)
        return {
          status: false,
          message: "Failed to fetch categories",
          requestDuration: 0,
        };

      return {
        status: true,
        message: "Categories fetched successfully.",
        data: response.data.categories,
        requestDuration: 0,
      };
    } catch (error: any) {
      const apiErrors = apiErrorChecks(error);
      if (apiErrors.isApiError && apiErrors.message)
        return {
          status: false,
          message: apiErrors.message,
          requestDuration: 0,
        };

      return {
        status: false,
        message: `An unknown error occurred on our end. Check your network connection and try again. Detail: ${
          error?.response?.data?.message ||
          error?.response?.message ||
          error?.message
        }`,
        requestDuration: 0,
      };
    }
  }
  async getCategory(slug: string): Promise<IApiResponse<ICategory | false>> {
    try {
      const response = await api.get<ICategory>("/api/v1/category", {
        params: { slug },
      });

      if (!response || !response.data)
        return {
          status: false,
          message: "Failed to fetch the category",
          requestDuration: 0,
        };

      return {
        status: true,
        message: "Category fetched successfully.",
        data: response.data,
        requestDuration: 0,
      };
    } catch (error: any) {
      if (error?.response?.status === 404) {
        return {
          status: false,
          message: "404 | Not Found",
          requestDuration: 0,
          data: false,
        };
      }
      const apiErrors = apiErrorChecks(error);
      if (apiErrors.isApiError && apiErrors.message)
        return {
          status: false,
          message: apiErrors.message,
          requestDuration: 0,
        };

      return {
        status: false,
        message: `An unknown error occurred on our end. Check your network connection and try again. Detail: ${
          error?.response?.data?.message ||
          error?.response?.message ||
          error?.message
        }`,
        requestDuration: 0,
      };
    }
  }
}
