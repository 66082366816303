import { PropsWithChildren } from "react";
import "./IGBodyDiv.css";

interface IGBodyDivProps extends PropsWithChildren {
  backgroundBlurAnimationDuration: number;
  className: string;
  style: React.CSSProperties;
}

export default function IGBodyDiv({
  backgroundBlurAnimationDuration,
  className,
  style,
  children,
}: IGBodyDivProps) {
  return (
    <div
      className="backdrop-blur"
      style={{
        animation: `bgBlur
      ${backgroundBlurAnimationDuration / 1000}s
      ease 1`,
      }}
    >
      <div className={className} style={style}>
        {children}
      </div>
    </div>
  );
}
