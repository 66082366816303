import { useEffect, useState } from "react";
import Breadcrumbs from "../../components/breadcrumbs";
import SEO from "../../components/seo";
import ShadeFinderCard from "../../components/shade-finder/shade-finder-card";
import BrandService from "../../domain/services/brand-service";
import "./styles.css";
import IBrand from "../../domain/entities/brand/IBrand";
import IApiStatus from "../../domain/entities/api/IApiStatus";
import IProductMini from "../../domain/entities/product/IProductMini";
import ProductService from "../../domain/services/product-service";
import IProductShade from "../../domain/entities/product/IProductShade";
import ShadeFinderProducts from "../../components/shade-finder/shade-finder-products";
import IProductFilter from "../../domain/entities/product/IProductFilter";
import { ISearchProductSortBy } from "../../domain/entities/product/ISearchProductRequest";
import shortenQuantityToString from "../../utilities/text/shorten-quantity-to-string";
import ErrorComponentTiny from "../../components/error/tiny";

const brandService = new BrandService();

const productService = new ProductService();

export default function ShadeFinderPage() {
  const [brands, setBrands] = useState<IBrand[]>([]);
  const [selectedBrand, setSelectedBrand] = useState<string>("");
  const [selectedProduct, setSelectedProduct] = useState<string>("");
  const [selectedShade, setSelectedShade] = useState<number | undefined>(
    undefined
  );
  const [products, setProducts] = useState<IProductMini[]>([]);
  const [filters, setFilters] = useState<IProductFilter | undefined>(undefined);
  const [shades, setShades] = useState<IProductShade[] | undefined>(undefined);
  const [apiStatus, setApiStatus] = useState<IApiStatus>({
    loading: true,
    message: "",
    status: false,
  });
  const [productApiStatus, setProductApiStatus] = useState<IApiStatus>({
    loading: false,
    message: "",
    status: false,
  });

  useEffect(() => {
    async function getAllBrands() {
      try {
        const brands = await brandService.getAllBrands({
          only_with_product_variants: true,
        });
        if (!brands || !brands.data) {
          setApiStatus({
            loading: false,
            message:
              brands.message || "An error occurred while fetching brands",
            status: false,
          });

          return;
        }

        setBrands(brands.data);
        setApiStatus({
          loading: false,
          message: "",
          status: true,
        });
      } catch (error) {
        setApiStatus({
          loading: false,
          message: "An error occurred while fetching brands",
          status: false,
        });
      }
    }

    getAllBrands();
  }, []);

  async function fetchProducts(brandSlug: string, keepFilters?: true) {
    try {
      setProductApiStatus({
        loading: true,
        message: "",
        status: false,
      });

      const products = await productService.getProductsFromBrand(
        brandSlug || selectedBrand,
        {
          page_limit: 9999999,
          page_number: 1,
          must_have_variant: true,
          brand_slugs: [],
          sort_by: ISearchProductSortBy.NAME,
        }
      );
      if (!products || !products.data) {
        setProductApiStatus({
          loading: false,
          message:
            products.message || "An error occurred while fetching products",
          status: false,
        });

        return;
      }

      setProducts(
        products.data.products.filter((product) => product.variants.length > 0)
      );
      if (!keepFilters) setFilters(products.data.filters);
      setProductApiStatus({
        loading: false,
        message: "",
        status: true,
      });
    } catch (error) {
      setProductApiStatus({
        loading: false,
        message: "An error occurred while fetching products",
        status: false,
      });
    }
  }

  function sortProducts(sortBy: ISearchProductSortBy) {
    switch (sortBy) {
      case ISearchProductSortBy.NAME:
        setProducts([...products].sort((a, b) => a.name.localeCompare(b.name)));
        break;

      case ISearchProductSortBy.PRICE_ASC:
        setProducts([...products].sort((a, b) => a.price - b.price));
        break;

      case ISearchProductSortBy.PRICE_DESC:
        setProducts([...products].sort((a, b) => b.price - a.price));
        break;

      case ISearchProductSortBy.RATING:
        setProducts([...products].sort((a, b) => b.rating - a.rating));
        break;

      case ISearchProductSortBy.REVIEWS:
        setProducts([...products].sort((a, b) => b.reviews - a.reviews));
        break;

      default:
        break;
    }
  }

  return (
    <div className="shade-finder-page">
      <SEO
        title="Match Your Shade"
        description="Find What You Are Wearing Next. Our shade finder enables you to find other products that closely match the shade of a product you are currently using. It's very simple to use"
      />
      <Breadcrumbs
        data={[
          {
            label: "Match Your Shade",
            to: window.location.pathname,
          },
        ]}
      />
      <div className="shade-finder-heading">Find What You Are Wearing Next</div>
      <div className="shade-finder-help">
        <p>
          Our shade finder enables you to find other products that closely match
          the shade of a product you are currently using. It's very simple to
          use
        </p>
      </div>
      {!apiStatus.status && !apiStatus.loading ? (
        <ErrorComponentTiny
          title="An Error Occurred"
          description="We are unable to show you this content at the moment. Kindly retry"
          errorCode={apiStatus.message}
        />
      ) : (
        <div className="shade-finder-main">
          <ShadeFinderCard
            step={1}
            heading="Choose a brand of foundation or concealer that you wear"
            showFilters={false}
            textBoxPlaceHolder="Choose a brand"
            available={true}
            loading={apiStatus.loading}
            disableMessage=""
            data={brands.map((brand) => {
              return {
                name: brand.name,
                description: brand.description,
                slug: brand.slug,
                image: brand.logo,
              };
            })}
            selectedSlug={selectedBrand}
            onSelect={async (brandSlug) => {
              setShades(undefined);
              setProducts([]);
              setSelectedProduct("");
              setSelectedBrand(brandSlug as string);
              await fetchProducts(brandSlug as string);
            }}
          />
          <ShadeFinderCard
            step={2}
            heading="Choose the product you use from the brand"
            textBoxPlaceHolder="Choose a product from the brand"
            showFilters={true}
            loading={productApiStatus.loading}
            available={!!selectedBrand}
            disableMessage="Select a brand to continue"
            data={products.map((prod) => {
              return {
                name: prod.name,
                slug: prod.slug,
                category_slug: prod.category_slug,
                description: `${prod.category} | ${prod.currency}${
                  prod.price
                } | ${
                  Math.round(prod.rating * 100) / 100
                } stars from ${shortenQuantityToString(prod.reviews)} reviews`,
                image: prod.images[0],
              };
            })}
            selectedSlug={selectedProduct}
            onSelect={(productSlug) => {
              setSelectedProduct(productSlug as string);
              setShades(
                products.find((prod) => prod.slug === productSlug)?.variants ||
                  []
              );
            }}
            filters={filters}
            setSortBy={sortProducts}
          />
          <ShadeFinderCard
            step={3}
            heading="Finally, select the shade you wear in the product"
            textBoxPlaceHolder="Choose a shade from the product"
            showFilters={false}
            loading={false}
            available={!!selectedProduct}
            disableMessage="Select a product to continue"
            data={
              shades?.map((shade) => {
                return {
                  name: shade.name,
                  slug: shade.id,
                  url: `/products/${selectedProduct}?variant=${shade.id}`,
                  color: shade.value,
                };
              }) || []
            }
            selectedSlug={selectedShade}
            onSelect={(slug) => {
              setSelectedShade(slug as number);
            }}
          />
        </div>
      )}
      {selectedShade && (
        <ShadeFinderProducts
          variantId={selectedShade}
          productService={productService}
        />
      )}
    </div>
  );
}
