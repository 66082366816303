import { PropsWithChildren } from "react";
import UserProvider from "./user";
import HelmetProviderComp from "./helmet";
import CategoryProvider from "./category";

export default function AppContexts({ children }: PropsWithChildren) {
  return (
    <UserProvider>
      <CategoryProvider>
        <HelmetProviderComp>{children}</HelmetProviderComp>
      </CategoryProvider>
    </UserProvider>
  );
}
