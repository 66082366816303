import {
  ErrorResponse,
  isRouteErrorResponse,
  useRouteError,
} from "react-router-dom";
import SunBathingIcon from "../../../assets/icons/sunbathing";
import SEO from "../../../components/seo";
import "./styles.css";

export default function RouterErrorPage() {
  const error: ErrorResponse | Error | any = useRouteError() as any;

  return (
    <div className="error-page">
      <SEO title="Oh Snap!" dontIndex={true} description="" />
      <SunBathingIcon />
      <div className="error-heading">
        Oh Snap!{" "}
        {isRouteErrorResponse(error) ? "" : "An Unknown Error Occurred"}
      </div>
      <div className="error-description">
        We encountered an issue while trying to load the page. Please try again.
      </div>
      {(error.status || error.statusText) && (
        <div className="error-code">
          {error.status} | {error.statusText}
        </div>
      )}
      <div className="error-code">
        {error.data || error?.message || `${error}`}
      </div>
    </div>
  );
}
