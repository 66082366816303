import { ISearchProductSortBy } from "../../../domain/entities/product/ISearchProductRequest";

interface IProductSortSelectProps {
  label: string;
  value: ISearchProductSortBy;
  selected: boolean;
  onSelect: (value: ISearchProductSortBy) => void;
}

export default function ProductSortSelect(data: IProductSortSelectProps) {
  return (
    <label className="sort-radio-container">
      {data.label}
      <input
        type="checkbox"
        checked={data.selected}
        onChange={() => data.onSelect(data.value)}
      />
      <span className="checkmark"></span>
    </label>
  );
}
