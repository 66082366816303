import IProductPrice from "../../../domain/entities/product/IProductPrice";
import "./styles.css";

interface IProductPriceProps {
  data: IProductPrice[];
}

export default function ProductPrice({ data }: IProductPriceProps) {
  return (
    <div className="product-price">
      <div className="product-price-heading">Buy from these Retailers</div>
      <div className="product-price-display">
        {data.map((price, index) => (
          <div
            className="main-product-price"
            key={`product-price-${price.name}-${index}`}
          >
            <div className="main-product-price-heading">{price.name}</div>
            <a href={price.url} target="_blank" rel="noreferrer">
              <div className="product-price-display-item-main">
                <p>
                  {price.currency} {price.price.toFixed(2)}
                </p>
                <button>Buy Now</button>
              </div>
            </a>
          </div>
        ))}
        {data.length === 0 && (
          <p>We are working on finding the price for this item! Hang tight!</p>
        )}
      </div>
    </div>
  );
}
