import { useEffect, useState } from "react";
import IUser from "../../../domain/entities/user/IUser";
import "./styles.css";
import CloseIcon2 from "../../../assets/icons/close-2";
import EditProfileForm from "./edit-profile-form";

interface IEditProfileProps {
  data: IUser;
  display: boolean;
  close(): void;
  reload(): void;
}

export default function EditProfile({
  data,
  display,
  close,
  reload,
}: IEditProfileProps) {
  const [visible, setVisible] = useState(display);
  const [closing, setClosing] = useState(false);

  useEffect(() => {
    if (display) {
      setVisible(true);
      setClosing(false);
      document.body.style.overflow = "hidden";
    } else {
      setClosing(true);
      setTimeout(() => {
        setVisible(false);
        setClosing(false);
        document.body.style.overflow = "auto";
      }, 250);
    }
  }, [display]);

  return (
    <div className="edit-profile-component">
      <div
        className={`edit-profile-content${visible ? " visible" : ""}${
          closing ? " closing" : ""
        }`}
      >
        <div className="edit-profile-heading">
          <div className="edit-profile-heading-content">Edit Your Profile</div>
          <div className="close-drawer" onClick={close} title="Close this menu">
            <CloseIcon2 />
          </div>
        </div>
        <EditProfileForm data={data} close={close} reload={reload} />
      </div>
      <div className="grey-out" onClick={close}></div>
    </div>
  );
}
