import { useEffect, useState } from "react";
import "./styles.css";

export default function FooterFloatingButton() {
  const [hideFloatingButton, setHideFloatingButton] = useState<boolean>(false);

  useEffect(() => {
    function checkIfScrollIsAtEnd() {
      setHideFloatingButton(
        window.scrollY + window.innerHeight + 100 >= document.body.scrollHeight
      );
    }

    checkIfScrollIsAtEnd();
    window.addEventListener("scroll", checkIfScrollIsAtEnd);

    return () => {
      window.removeEventListener("scroll", checkIfScrollIsAtEnd);
    };
  }, []);

  function scrollToNewsletter() {
    window.scrollTo({
      top: document.body.scrollHeight,
      behavior: "smooth",
    });
  }

  return (
    <div className={`floating-button${hideFloatingButton ? " hidden" : ""}`}>
      <div className="floating-newsletter-button" onClick={scrollToNewsletter}>
        Newsletter
      </div>
    </div>
  );
}
