import { NavLink } from "react-router-dom";
import "./styles.css";

export default function HeaderNav() {
  return (
    <nav>
      <ul>
        <li>
          <NavLink to="/" state={{ from: window.location.pathname }}>
            Home
          </NavLink>
        </li>
        <li>
          <NavLink to="/brands" state={{ from: window.location.pathname }}>
            Brands
          </NavLink>
        </li>
        <li>
          <NavLink
            to="/match-your-shade"
            state={{ from: window.location.pathname }}
          >
            Match Your Shade
          </NavLink>
        </li>
        <li>
          <NavLink to="/blog" state={{ from: window.location.pathname }}>
            Blog
          </NavLink>
        </li>
        <li>
          <NavLink
            to="/informations/about-us"
            state={{ from: window.location.pathname }}
          >
            About Us
          </NavLink>
        </li>
      </ul>
    </nav>
  );
}
