import Breadcrumbs from "../../../components/breadcrumbs";
import SEO from "../../../components/seo";
import "./styles.css";

export default function AboutUsPage() {
  return (
    <div className="about-us-page">
      <SEO
        title="About Us"
        description="About Boujeeher. We are always committed to provide best match for your lifestyle. Learn more about who we are"
      />
      <Breadcrumbs
        data={[{ label: "About Boujeeher", to: window.location.pathname }]}
      />
      <div className="about-us-content">
        <div className="about-us-content-split">
          <div className="about-us-content-split-image">
            <img src="/assets/images/about-us-1.jpg" alt="About Boujeeher" />
          </div>
          <div className="about-us-content-split-text">
            <div className="about-us-header main theme-color">About Us</div>
            <div className="about-us-text">
              Welcome to Boujeeher, your ultimate destination for unbiased
              beauty product comparisons and reviews. Our mission is to empower
              you with the knowledge and tools to make informed decisions about
              the beauty brands products you use every day.
            </div>
            <div className="about-us-header">Who We Are</div>
            <div className="about-us-text">
              We are a team of beauty enthusiasts, skincare experts, and
              tech-savvy individuals dedicated to bringing you the most
              comprehensive and reliable beauty product comparisons. We
              understand that the beauty industry is vast and ever evolving, and
              finding the right products can be overwhelming. That’s where we
              come in.
            </div>
            <div className="about-us-header">What We Do</div>
            <div className="about-us-text">
              Our platform offers detailed comparisons of a wide range of beauty
              products, from makeup to nails. We meticulously analyse
              ingredients, real user reviews and price points to provide you
              with clear, concise, and accurate information. Whether you’re
              looking for the trending foundation for your skin or the latest
              trending lipstick, we've got you covered.
            </div>
          </div>
        </div>
        <div className="about-us-content-split">
          <div className="about-us-content-split-text">
            <div className="about-us-header">Our Values</div>
            <div className="about-us-text">
              <ul>
                <li>
                  <strong>Transparency</strong>: We believe in providing honest
                  and transparent information. Our comparisons are unbiased and
                  based on thorough research.
                </li>
                <li>
                  <strong>Inclusivity</strong>: Beauty is for everyone. We
                  strive to include products that cater to all skin tones,
                  types, and concerns.
                </li>
                <li>
                  <strong>Sustainability</strong>: We are committed to promoting
                  products that are environmentally friendly and ethically
                  produced.
                </li>
              </ul>
            </div>
            <div className="about-us-header">Why Choose Us?</div>
            <div className="about-us-text">
              <ul>
                <li>
                  <strong>Expert Insights</strong>: Our team includes beauty
                  professionals who bring their expertise to every comparison.
                </li>
                <li>
                  <strong>User-Friendly Interface</strong>: Our website is
                  designed to be easy to navigate, so you can find the
                  information you need quickly and effortlessly.
                </li>
                <li>
                  <strong>Community Driven</strong>: We value your feedback and
                  encourage you to share your experiences with the products we
                  review.
                </li>
              </ul>
            </div>
            <div className="about-us-header">Join Our Community</div>
            <div className="about-us-text">
              Stay updated with the latest beauty trends, product launches, and
              exclusive offers by subscribing to our newsletter. Follow us on
              social media to join a community of beauty lovers who share your
              passion for finding the best products.
            </div>
            <div className="about-us-text">
              Thank you for choosing Boujeeher. We are here to help you look and
              feel your best!
            </div>
          </div>
          <div className="about-us-content-split-image">
            <img src="/assets/images/about-us-2.jpg" alt="About Boujeeher" />
          </div>
        </div>
      </div>
    </div>
  );
}
