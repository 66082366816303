import { NavLink } from "react-router-dom";
import "./styles.css";
import HomeIcon from "../../../../assets/icons/home";
import LipstickIcon from "../../../../assets/icons/lipstick";
import BlogIcon from "../../../../assets/icons/blog";
import HappyBoxIcon from "../../../../assets/icons/happy-box";
import CosmeticPersonIcon from "../../../../assets/icons/cosmetic-person";
import { useUserContext } from "../../../../contexts/user";
import AvatarIcon from "../../../../assets/icons/avatar";
import HeartIcon from "../../../../assets/icons/heart";

export default function HeaderDrawerNav() {
  const { isLoggedIn, user, wishlistCount } = useUserContext();

  return (
    <nav className="header-drawer-nav">
      <NavLink
        to="/"
        state={{
          from: window.location.pathname,
        }}
      >
        <HomeIcon /> Home
      </NavLink>
      <NavLink to="/brands" state={{ from: window.location.pathname }}>
        <HappyBoxIcon /> Brands
      </NavLink>
      <NavLink to="/match-your-shade" state={{ from: window.location.pathname }}>
        <LipstickIcon /> Match Your Shade
      </NavLink>
      <NavLink to="/blog" state={{ from: window.location.pathname }}>
        <BlogIcon /> Blog
      </NavLink>
      <NavLink
        to="/informations/about-us"
        state={{ from: window.location.pathname }}
      >
        <CosmeticPersonIcon /> About Us
      </NavLink>
      {isLoggedIn && (
        <NavLink
          to={{
            pathname: "/account",
            search: "showWishlist=true",
          }}
          state={{ from: window.location.pathname }}
        >
          <HeartIcon /> Your Wishlist{" "}
          {wishlistCount > 0 && `(${wishlistCount})`}
        </NavLink>
      )}
      {isLoggedIn && (
        <NavLink to="/account" state={{ from: window.location.pathname }}>
          {user?.image_url ? (
            <img src={user.image_url} alt={user.full_name} />
          ) : (
            <AvatarIcon />
          )}
          Your Account
        </NavLink>
      )}
    </nav>
  );
}
