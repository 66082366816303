import "./styles.css";

interface IIndividualReviewBarProps {
  ratingPercentage: number;
}

export default function IndividualReviewBar({
  ratingPercentage,
}: IIndividualReviewBarProps) {
  return (
    <div className="individual-review-bar">
      <div
        className="individual-review-bar-fill"
        style={{
          width: `${ratingPercentage}%`,
        }}
      ></div>
    </div>
  );
}
