import { NavLink } from "react-router-dom";
import { useUserContext } from "../../../contexts/user";
import "./styles.css";
import HeartIcon from "../../../assets/icons/heart";
import AvatarIcon from "../../../assets/icons/avatar";
import shortenCountToThreeCharacters from "../../../utilities/text/shorten-count-to-three-characters";

export default function HeaderUserProfile() {
  const { isLoggedIn, user, wishlistCount } = useUserContext();

  if (isLoggedIn) {
    return (
      <div className="header-user">
        <NavLink
          to={{
            pathname: "/account",
            search: "showWishlist=true",
          }}
          state={{ from: window.location.pathname }}
        >
          {wishlistCount > 0 && (
            <div className="header-user-alert-count">
              {shortenCountToThreeCharacters(wishlistCount)}
            </div>
          )}
          <HeartIcon />
        </NavLink>
        <NavLink to="/account" state={{ from: window.location.pathname }}>
          <div className="user-image">
            {user?.image_url ? (
              <img src={user.image_url} alt={user.full_name} />
            ) : (
              <AvatarIcon />
            )}
          </div>
        </NavLink>
      </div>
    );
  }

  return (
    <div className="header-user">
      <NavLink
        to="/account/login"
        state={{
          from: window.location.pathname,
        }}
        className="login-button"
      >
        Login | Signup
      </NavLink>
    </div>
  );
}
