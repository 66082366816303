import ILandingDiscover from "../../../../domain/entities/landing/ILandingDiscover";
import ExpandableCarouselItem from "./carousel-item";
import "./styles.css";

interface ExpandableCarouselProps {
  data: ILandingDiscover[];
}

const ExpandableCarousel = ({ data }: ExpandableCarouselProps) => {
  return (
    <div className="expandable-carousel">
      {data.map((item, index) => (
        <ExpandableCarouselItem key={`expandable-${index}`} data={item} />
      ))}
    </div>
  );
};

export default ExpandableCarousel;
