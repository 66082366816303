import IBrandFull from "../../../domain/entities/brand/IBrandFull";
import "./styles.css";

interface IBrandInformationProps {
  data: IBrandFull;
}

export default function BrandInformation({ data }: IBrandInformationProps) {
  return (
    <div className="brand-information">
      {data.logo && (
        <div className="brand-banner">
          <img src={encodeURI(data.logo)} alt={data.name} />
        </div>
      )}
      <div className="brand-profile">
        {/* <div className="brand-logo">
          <div className="white-out"></div>
          <img
            className="brand-logo-background"
            src={data.logoBackground}
            alt={data.name}
          />
          <div className="brand-logo-content">
            <img className="brand-logo-image" src={data.logo} alt={data.name} />
            <p>{data.caption}</p>
            {data.displayBrandName && <span>{data.name}</span>}
            {data.pageManagedBy !== BrandManager.NOT_MANAGED && (
              <BrandManagerTag managedBy={data.pageManagedBy} />
            )}
          </div>
        </div> */}

        <div className="brand-details">
          <h1>{data.name}</h1>
          <p>{data.description}</p>
        </div>
      </div>
    </div>
  );
}
