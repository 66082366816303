import { useState } from "react";
import CloseIcon2 from "../../../assets/icons/close-2";
import IApiStatus from "../../../domain/entities/api/IApiStatus";
import IProductMini from "../../../domain/entities/product/IProductMini";
import ProductService from "../../../domain/services/product-service";
import ErrorComponentTiny from "../../error/tiny";
import SkeletonLoading from "../../loading/skeleton-loading";
import ProductsListItem from "../../products-list/products-list-item";
import "./styles.css";

interface IAccountWishlistProps {
  apiStatus: IApiStatus;
  wishlist: IProductMini[];
  currentUser: boolean;
  reload: () => void;
}

const productService = new ProductService();

export default function AccountWishlist({
  apiStatus,
  wishlist,
  currentUser,
  reload,
}: IAccountWishlistProps) {
  const [loading, setLoading] = useState(false);

  async function removeFromWishlist(productSlug: string) {
    if (loading) return;
    setLoading(true);
    const response = await productService.removeProductFromWishlist(
      productSlug
    );
    setLoading(false);
    if (response.status) {
      reload();
    } else {
      console.error(response.message);
    }
  }

  return apiStatus.loading ? (
    <div className="account-page-wishlist">
      <div className="account-page-wishlist-item">
        <SkeletonLoading />
      </div>
      <div className="account-page-wishlist-item">
        <SkeletonLoading />
      </div>
      <div className="account-page-wishlist-item">
        <SkeletonLoading />
      </div>
      <div className="account-page-wishlist-item">
        <SkeletonLoading />
      </div>
      <div className="account-page-wishlist-item">
        <SkeletonLoading />
      </div>
      <div className="account-page-wishlist-item">
        <SkeletonLoading />
      </div>
      <div className="account-page-wishlist-item">
        <SkeletonLoading />
      </div>
      <div className="account-page-wishlist-item">
        <SkeletonLoading />
      </div>
      <div className="account-page-wishlist-item">
        <SkeletonLoading />
      </div>
      <div className="account-page-wishlist-item">
        <SkeletonLoading />
      </div>
      <div className="account-page-wishlist-item">
        <SkeletonLoading />
      </div>
      <div className="account-page-wishlist-item">
        <SkeletonLoading />
      </div>
    </div>
  ) : apiStatus.status ? (
    wishlist.length > 0 ? (
      <div className="account-page-wishlist">
        {wishlist.map((item, index) => (
          <div
            className="account-page-wishlist-item"
            key={`account-page-wishlist-item-${index}`}
          >
            {currentUser && (
              <div
                className="remove-icon"
                title={`Remove from your wishlist (${item.name})`}
                onClick={() => {
                  removeFromWishlist(item.slug);
                }}
              >
                <CloseIcon2 />
              </div>
            )}
            <ProductsListItem data={item} />
          </div>
        ))}
      </div>
    ) : currentUser ? (
      <ErrorComponentTiny
        title="You have not added any products to your wishlist yet"
        description="Add products to your wishlist to keep track of them. You can add products to your wishlist by clicking the heart icon on the product page. You can help other users by making your wishlist public."
      />
    ) : (
      <ErrorComponentTiny
        title="This user has not added any products to their wishlist yet"
        description="The user haven't added any products to their wishlist yet or the wishlist is private."
      />
    )
  ) : (
    <div className="account-page-wishlist">{apiStatus.message}</div>
  );
}
