import { NavLink } from "react-router-dom";
import "./styles.css";

export interface IBreadcrumbsNavUrlProps {
  to: string;
  label: string;
}

export default function BreadcrumbsNavUrl({
  to,
  label,
}: IBreadcrumbsNavUrlProps) {
  return (
    <li>
      <NavLink
        to={to}
        state={{
          from: window.location.pathname,
        }}
      >
        {label}
      </NavLink>
    </li>
  );
}
