/**
 * Reduces a string to specified number of characters (or defaults to 50)
 *
 * @param text The text to be reduced
 * @param maxCharacters optional - Maximum number of characters allowed, default: 50
 * @returns The reduced text with added ellipsis
 */
export default function reduceText(text: string, maxCharacters?: number) {
  if (text.length <= (maxCharacters || 50)) return text;

  return text.slice(0, maxCharacters || 50) + "...";
}
