import { useState } from "react";
import Markdown from "react-markdown";
import reduceText from "../../../../utilities/text/text-reducer";
import "./styles.css";

interface IProductDescriptionProps {
  description: string;
}

const DESCRIPTION_HIDDEN_LENGTH = 500;

export default function ProductDescription({
  description,
}: IProductDescriptionProps) {
  const [displayFullDescription, setDisplayFullDescription] =
    useState<boolean>(false);

  return (
    <div className="product-details-product-description">
      <Markdown>
        {displayFullDescription
          ? description
          : reduceText(description, DESCRIPTION_HIDDEN_LENGTH)}
      </Markdown>
      {description.length > DESCRIPTION_HIDDEN_LENGTH && (
        <button
          type="button"
          onClick={() => {
            if (displayFullDescription)
              window.scrollTo({
                top: 0,
                behavior: "smooth",
              });
            setDisplayFullDescription(!displayFullDescription);
          }}
          className="product-description-show-more"
        >
          {displayFullDescription ? "Hide" : "Show More"}
        </button>
      )}
    </div>
  );
}
