import "./spinner.css";

interface SpinnerProps {
  size: number;
}

function Spinner(props: SpinnerProps) {
  return (
    <div
      className="spinner"
      style={{
        border: `${Math.max(
          Math.round(0.13333 * props.size),
          1
        )}px solid #f3f3f3 `,
        borderTop: `${Math.max(
          Math.round(0.13333 * props.size),
          1
        )}px solid #000000`,
        borderRadius: "50%",
        width: `${Math.max(props.size, 1)}px`,
        height: `${Math.max(props.size, 1)}px`,
      }}
    ></div>
  );
}

export default Spinner;
