import ISessionStorageValue from "../entities/storage/ISessionStorageValue";
import IStorageService from "../entities/storage/IStorageService";
import SessionStorageKey from "../entities/storage/SessionStorageKey";

export default class SessionStorage
  implements IStorageService<SessionStorageKey, ISessionStorageValue>
{
  get(key: SessionStorageKey): ISessionStorageValue[SessionStorageKey] | null {
    const value = sessionStorage.getItem(key);
    return value ? JSON.parse(value) : null;
  }

  set(
    key: SessionStorageKey,
    value: ISessionStorageValue[SessionStorageKey]
  ): boolean {
    try {
      sessionStorage.setItem(key, JSON.stringify(value));
      return true;
    } catch (error) {
      return false;
    }
  }

  remove(key: SessionStorageKey): boolean {
    try {
      sessionStorage.removeItem(key);
      return true;
    } catch (error) {
      return false;
    }
  }

  clear(): boolean {
    try {
      sessionStorage.clear();
      return true;
    } catch (error) {
      return false;
    }
  }
}
