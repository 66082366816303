import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import App from "./App";
import * as serviceWorkerRegistration from "./serviceWorkerRegistration";
import reportWebVitals from "./reportWebVitals";
import TagManager, { TagManagerArgs } from "react-gtm-module";

const tagManagerArgs: TagManagerArgs = {
  gtmId: "GTM-P8X6RWZG",
};
TagManager.initialize(tagManagerArgs);

const root = ReactDOM.createRoot(
  document.getElementById("root") as HTMLDivElement
);
root.render(<App />);

serviceWorkerRegistration.register({
  onUpdate: (registration) => {
    if (registration && registration.waiting) {
      registration.waiting.postMessage({ type: "SKIP_WAITING" });
      registration.waiting.addEventListener("statechange", (e: any) => {
        if (e.target?.state === "activated") {
          const statusEl = document.getElementById("cache-control-status");
          if (statusEl) {
            statusEl.classList.add("visible");
          }
        }
      });
    }
  },
});
reportWebVitals((metric) => {
  // console.info("reportWebVitals", metric);
});
