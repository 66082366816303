import { ISocialMediaPlatform } from "../../../../domain/entities/product/IProductSocialMedia";
import "./styles.css";

interface ISocialPlatformButtonProps {
  data: ISocialMediaPlatform;
  setCurrentPlatform: () => void;
  isCurrent: boolean;
}

export default function SocialPlatformButton({
  data,
  setCurrentPlatform,
  isCurrent,
}: ISocialPlatformButtonProps) {
  return (
    <button
      onClick={() => setCurrentPlatform()}
      className={`product-social-platform-button${isCurrent ? " active" : ""}`}
    >
      <img src={data.icon} alt={data.name} />
    </button>
  );
}
