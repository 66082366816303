import { useEffect, useState } from "react";
import Breadcrumbs from "../../../components/breadcrumbs";
import SEO from "../../../components/seo";
import IUser from "../../../domain/entities/user/IUser";
import IProductMini from "../../../domain/entities/product/IProductMini";
import IApiStatus from "../../../domain/entities/api/IApiStatus";
import SkeletonLoading from "../../../components/loading/skeleton-loading";
import AccountReviews from "../../../components/account/reviews";
import AccountWishlist from "../../../components/account/wishlist";
import ErrorComponent from "../../../components/error";
import ErrorComponentTiny from "../../../components/error/tiny";
import shortenQuantityToString from "../../../utilities/text/shorten-quantity-to-string";
import { useSearchParams } from "react-router-dom";
import { useUserContext } from "../../../contexts/user";
import IUserProfileReview from "../../../domain/entities/user/IUserProfileReview";
import AuthService from "../../../domain/services/auth-service";
import OutIcon from "../../../assets/icons/out";
import EditProfile from "../../../components/account/edit-profile";
import AvatarIcon from "../../../assets/icons/avatar";
import "./styles.css";

const authService = new AuthService();

enum AccountPageState {
  REVIEWS = "reviews",
  WISHLIST = "wishlist",
}

export default function AccountPage() {
  const [user, setUser] = useState<IUser | undefined>(undefined);
  const [apiStatus, setApiStatus] = useState<IApiStatus>({
    loading: true,
    status: false,
    message: "",
  });
  const [wishlist, setWishlist] = useState<IProductMini[]>([]);
  const [reviews, setReviews] = useState<IUserProfileReview[]>([]);
  const [searchParams, setSearchParams] = useSearchParams();
  const [state, setState] = useState<AccountPageState>(
    searchParams.get("showWishlist")
      ? AccountPageState.WISHLIST
      : AccountPageState.REVIEWS
  );
  const { setLogin, setLogout } = useUserContext();
  const [showEditProfile, setShowEditProfile] = useState(false);

  useEffect(() => {
    const showWishlist = searchParams.get("showWishlist");
    if (showWishlist && state !== AccountPageState.WISHLIST)
      setState(AccountPageState.WISHLIST);
    else if (!showWishlist && state !== AccountPageState.REVIEWS)
      setState(AccountPageState.REVIEWS);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [searchParams]);

  const fetchUser = async () => {
    setApiStatus({
      loading: true,
      status: false,
      message: "",
    });

    const userResponse = await authService.getMyProfile();

    if (userResponse.status && userResponse.data) {
      setUser(userResponse.data.user_details);
      setReviews(userResponse.data.reviews);
      setWishlist(userResponse.data.wishlist);
      setLogin(
        userResponse.data.user_details,
        userResponse.data.wishlist.length
      );
    }

    setApiStatus({
      loading: false,
      status: userResponse.status,
      message: userResponse.message,
    });
  };

  useEffect(() => {
    fetchUser();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  function stateComponentSelector() {
    switch (state) {
      case AccountPageState.REVIEWS:
        return (
          <AccountReviews
            reviews={reviews}
            apiStatus={apiStatus}
            currentUser={true}
          />
        );

      case AccountPageState.WISHLIST:
        return (
          <AccountWishlist
            currentUser={true}
            wishlist={wishlist}
            apiStatus={apiStatus}
            reload={() => fetchUser()}
          />
        );

      default:
        return (
          <ErrorComponentTiny
            title="This tab is not available"
            description="Uh oh! The tab you're trying to access is not available at the moment"
          />
        );
    }
  }

  return (
    <div className="account-page">
      <SEO
        title="Your Account"
        description="Manage your account settings, wishlists, and more."
      />
      <Breadcrumbs
        data={[
          {
            label: "Your Account",
            to: window.location.pathname,
          },
        ]}
      />
      <div className="account-page-heading">My Profile</div>
      {!apiStatus.status && !apiStatus.loading ? (
        <ErrorComponent
          title="Uh Oh! An Error Occurred"
          description="We were unable to load your profile at the moment!"
          errorCode={apiStatus.message}
        />
      ) : (
        <div className="account-page-content">
          <div className="account-page-profile">
            {apiStatus.loading ? (
              <div className="account-page-user">
                <div className="user-profile-picture">
                  <SkeletonLoading />
                </div>
                <div className="account-page-user-name">
                  <SkeletonLoading />
                </div>
                <div className="account-page-user-social">
                  <SkeletonLoading />
                  <SkeletonLoading />
                  <SkeletonLoading />
                  <SkeletonLoading />
                </div>
                <div className="account-page-user-bio">
                  <SkeletonLoading />
                </div>
                <div className="account-page-user-followers">
                  <SkeletonLoading />
                </div>
              </div>
            ) : (
              user && (
                <div className="account-page-user">
                  <div className="user-profile-picture">
                    {user.image_url ? (
                      <img src={user.image_url} alt="Your Profile" />
                    ) : (
                      <AvatarIcon />
                    )}
                  </div>
                  <div className="account-page-user-name">{user.full_name}</div>
                  <div className="account-page-user-social">
                    {user.socials.facebook && (
                      <a
                        href={user.socials.facebook}
                        target="_blank"
                        rel="noreferrer"
                        className="social-button"
                        title={`Facebook profile of ${user.full_name}`}
                      >
                        <img
                          src="/assets/social-icons/facebook.png"
                          alt="Facebook"
                        />
                      </a>
                    )}
                    {user.socials.instagram && (
                      <a
                        href={user.socials.instagram}
                        target="_blank"
                        rel="noreferrer"
                        className="social-button"
                        title={`Instagram profile of ${user.full_name}`}
                      >
                        <img
                          src="/assets/social-icons/instagram.png"
                          alt="Instagram"
                        />
                      </a>
                    )}
                    {user.socials.tiktok && (
                      <a
                        href={user.socials.tiktok}
                        target="_blank"
                        rel="noreferrer"
                        className="social-button"
                        title={`Tiktok profile for ${user.full_name}`}
                      >
                        <img
                          src="/assets/social-icons/tiktok.png"
                          alt="TikTok"
                        />
                      </a>
                    )}
                    {user.socials.twitter && (
                      <a
                        href={user.socials.twitter}
                        target="_blank"
                        rel="noreferrer"
                        className="social-button"
                        title={`Twitter (X) profile for ${user.full_name}`}
                      >
                        <img
                          src="/assets/social-icons/twitter.png"
                          alt="X (Previously Twitter)"
                        />
                      </a>
                    )}
                  </div>
                  <div className="account-page-user-bio">
                    {user.short_description}
                  </div>
                  <div className="account-page-user-followers">
                    {shortenQuantityToString(user.num_followers)} followers
                  </div>
                  <EditProfile
                    data={user}
                    display={showEditProfile}
                    close={() => {
                      setShowEditProfile(false);
                    }}
                    reload={() => {
                      fetchUser();
                    }}
                  />
                  <button
                    className="account-page-user-button"
                    type="button"
                    onClick={() => {
                      setShowEditProfile(true);
                    }}
                  >
                    Edit Profile
                  </button>
                  <button
                    className="account-page-user-button"
                    type="button"
                    onClick={setLogout}
                  >
                    <OutIcon /> Logout
                  </button>
                </div>
              )
            )}
          </div>
          <div className="account-page-details">
            <div className="account-page-action">
              <div
                className={`account-page-action-item${
                  state === AccountPageState.REVIEWS ? " active" : ""
                }`}
                onClick={() => {
                  setSearchParams();
                  setState(AccountPageState.REVIEWS);
                }}
              >
                Reviews{" "}
                {!apiStatus.status &&
                  !apiStatus.loading &&
                  `(${reviews.length})`}
              </div>
              <div
                className={`account-page-action-item${
                  state === AccountPageState.WISHLIST ? " active" : ""
                }`}
                onClick={() => {
                  setSearchParams({ showWishlist: "true" });
                  setState(AccountPageState.WISHLIST);
                }}
              >
                Wishlist{" "}
                {!apiStatus.status &&
                  !apiStatus.loading &&
                  `(${wishlist.length})`}
              </div>
            </div>
            <div className="account-page-details-content">
              {stateComponentSelector()}
            </div>
          </div>
        </div>
      )}
    </div>
  );
}
