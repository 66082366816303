import { Outlet, RouterProvider, createBrowserRouter } from "react-router-dom";
import Root from "./root";
import LandingPage from "../pages/landing";
import ProductPage from "../pages/product";
import BrandsListPage from "../pages/brands/brands-list";
import BrandPage from "../pages/brands/brand";
import SearchResultsPage from "../pages/search/search-results";
import CategoryPage from "../pages/category/category-detail";
import ShadeFinderPage from "../pages/shade-finder";
import LoginPage from "../pages/auth/login";
import ProtectedRoute from "./protected-route";
import AboutUsPage from "../pages/informations/about-us";
import ContactUsPage from "../pages/informations/contact-us";
import SpinnerFullPage from "../components/loading/spinner-fullpage";
import Error404Page from "../pages/error/error404";
import RouterErrorPage from "../pages/error/routerError";
import TermsAndConditionsPage from "../pages/informations/terms";
import PrivacyPolicyPage from "../pages/informations/privacy";
import BlogDiscoverPage from "../pages/blogs/blog-discover";
import BlogPage from "../pages/blogs/blog";
import AccountPage from "../pages/auth/account";
import VerifyLogin from "../pages/auth/verify-login";
import UserProfile from "../pages/user/profile";
import SiteMapPage from "../pages/informations/sitemap";

const browserRouter = createBrowserRouter(
  [
    {
      path: "/",
      element: <Root element={<Outlet />} />,
      errorElement: <Root element={<RouterErrorPage />} />,
      children: [
        {
          path: "",
          element: <LandingPage />,
        },
        {
          path: "/account",
          children: [
            {
              path: "",
              element: (
                <ProtectedRoute>
                  <AccountPage />
                </ProtectedRoute>
              ),
            },
            {
              path: "/account/login",
              children: [
                {
                  path: "",
                  element: <LoginPage />,
                },
                {
                  path: "/account/login/verify",
                  element: <VerifyLogin />,
                },
              ],
            },
          ],
        },
        {
          path: "/profile/:userId",
          element: (
            <ProtectedRoute>
              <UserProfile />
            </ProtectedRoute>
          ),
        },
        {
          path: "/informations",
          children: [
            {
              path: "/informations/about-us",
              element: <AboutUsPage />,
            },
            {
              path: "/informations/contact-us",
              element: <ContactUsPage />,
            },
            {
              path: "/informations/terms-and-conditions",
              element: <TermsAndConditionsPage />,
            },
            {
              path: "/informations/privacy-policy",
              element: <PrivacyPolicyPage />,
            },
            {
              path: "/informations/sitemap",
              element: <SiteMapPage />,
            },
          ],
        },
        {
          path: "/products",
          children: [
            {
              path: "/products",
              element: <div>Products</div>,
            },
            {
              path: "/products/:slug",
              children: [
                {
                  path: "/products/:slug",
                  element: <ProductPage />,
                },
              ],
            },
          ],
        },
        {
          path: "/brands",
          children: [
            {
              path: "",
              element: <BrandsListPage />,
            },
            {
              path: "/brands/:slug",
              element: <BrandPage />,
            },
          ],
        },
        {
          path: "/search",
          children: [
            // {
            //   path: "",
            //   element: <AdvancedSearchPage />,
            // },
            {
              path: "/search/:query",
              element: <SearchResultsPage />,
            },
          ],
        },
        {
          path: "/categories",
          children: [
            {
              path: "",
              element: <Error404Page />,
            },
            {
              path: "/categories/:slug",
              element: <CategoryPage />,
            },
          ],
        },
        {
          path: "/match-your-shade",
          children: [
            {
              path: "",
              element: <ShadeFinderPage />,
            },
          ],
        },
        {
          path: "/blog",
          children: [
            {
              path: "",
              element: <BlogDiscoverPage />,
            },
            {
              path: "/blog/:slug",
              element: <BlogPage />,
            },
          ],
        },
        {
          path: "*",
          element: <Error404Page />,
        },
      ],
    },
  ],
  {
    future: {
      v7_normalizeFormMethod: true,
    },
  }
);

export default function Router() {
  return (
    <RouterProvider
      router={browserRouter}
      fallbackElement={<Root element={<SpinnerFullPage />} />}
    />
  );
}
