import { useEffect, useState } from "react";
import IProductPriceFilter from "../../../domain/entities/product/IProductPriceFilter";
import MultiRangeSlider from "multi-range-slider-react";
import "./styles.css";

interface IProductPriceFilterProps {
  data: IProductPriceFilter;
  setPrice(minPrice: number, maxPrice: number): void;
}

export default function ProductPriceFilter({
  data,
  setPrice,
}: IProductPriceFilterProps) {
  const [minValue, setMinValue] = useState<number>(Math.floor(data.minPrice));
  const [maxValue, setMaxValue] = useState<number>(Math.ceil(data.maxPrice));

  useEffect(() => {
    setMinValue(Math.floor(data.minPrice));
    setMaxValue(Math.ceil(data.maxPrice));
  }, [data]);

  if (data.minPrice === data.maxPrice)
    return <div className="product-price-filter"></div>;

  return (
    <div className="product-price-filter">
      <div className="product-price-filter-type">Price</div>
      <div className="product-price-filter-range">
        {(Math.floor(data.minPrice) !== minValue ||
          Math.ceil(data.maxPrice) !== maxValue) && (
          <span className="current-filtered-value">
            Current: {data.currency}
            {Math.floor(minValue)} - {data.currency}
            {Math.ceil(maxValue)}
          </span>
        )}
        <MultiRangeSlider
          min={Math.floor(data.minPrice)}
          max={Math.ceil(data.maxPrice)}
          labels={[
            `${data.currency}${Math.floor(data.minPrice)}`,
            `${data.currency}${Math.ceil(data.maxPrice)}`,
          ]}
          step={1}
          minValue={minValue}
          maxValue={maxValue}
          onChange={(e) => {
            if (e.maxValue !== maxValue) setMaxValue(e.maxValue);
            if (e.minValue !== minValue) setMinValue(e.minValue);
            if (e.maxValue !== maxValue || e.minValue !== minValue)
              setPrice(e.minValue, e.maxValue);
          }}
          ruler={false}
          style={{
            boxShadow: "none",
            borderRadius: 0,
            border: "none",
          }}
          className="slider-no-border"
          canMinMaxValueSame={false}
          barLeftColor="var(--theme-color-transparent)"
          barRightColor="var(--theme-color-transparent)"
          barInnerColor="var(--theme-color)"
          thumbLeftColor="var(--theme-color)"
          thumbRightColor="var(--theme-color)"
        />
      </div>
    </div>
  );
}
