import { useState } from "react";
import { ISearchProductSortBy } from "../../../domain/entities/product/ISearchProductRequest";
import "./styles.css";
import ProductSortSelect from "./product-sort-select";

interface IProductSortProps {
  options: {
    label: string;
    value: ISearchProductSortBy;
  }[];
  onSelect: (value: ISearchProductSortBy) => void;
}

export default function ProductSort(data: IProductSortProps) {
  const [selected, setSelected] = useState<ISearchProductSortBy>(
    ISearchProductSortBy.RATING
  );

  function selectionHandler(value: ISearchProductSortBy) {
    if (value === selected) {
      setSelected(ISearchProductSortBy.RATING);
      data.onSelect(ISearchProductSortBy.RATING);
    } else {
      setSelected(value);
      data.onSelect(value);
    }
  }

  return (
    <div className="product-sort">
      <ProductSortSelect
        label="Ratings"
        value={ISearchProductSortBy.RATING}
        selected={selected === ISearchProductSortBy.RATING}
        onSelect={() => {
          selectionHandler(ISearchProductSortBy.RATING);
        }}
      />
      <ProductSortSelect
        label="Popularity"
        value={ISearchProductSortBy.REVIEWS}
        selected={selected === ISearchProductSortBy.REVIEWS}
        onSelect={() => {
          selectionHandler(ISearchProductSortBy.REVIEWS);
        }}
      />
      <ProductSortSelect
        label="Price (Low to High)"
        value={ISearchProductSortBy.PRICE_ASC}
        selected={selected === ISearchProductSortBy.PRICE_ASC}
        onSelect={() => {
          selectionHandler(ISearchProductSortBy.PRICE_ASC);
        }}
      />
      <ProductSortSelect
        label="Price (High to Low)"
        value={ISearchProductSortBy.PRICE_DESC}
        selected={selected === ISearchProductSortBy.PRICE_DESC}
        onSelect={() => {
          selectionHandler(ISearchProductSortBy.PRICE_DESC);
        }}
      />
    </div>
  );
}
