import Spinner from "./Spinner";

const IGHeader = (props: { showSpinner: boolean }) => {
  return (
    <div
      className="instagram-media-header"
      style={{
        display: "flex",
        flexDirection: "row",
        alignItems: "center",
        padding: "0 16px",
      }}
    >
      <div
        style={{
          backgroundColor: "#F4F4F4",
          borderRadius: "50%",
          flexGrow: 0,
          height: "40px",
          marginRight: "14px",
          width: "40px",
        }}
      />
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          flexGrow: 1,
          justifyContent: "center",
        }}
      >
        <div
          style={{
            backgroundColor: "#F4F4F4",
            borderRadius: "4px",
            flexGrow: 0,
            height: "14px",
            marginBottom: "6px",
            width: "100px",
          }}
        />
        <div
          style={{
            backgroundColor: "#F4F4F4",
            borderRadius: "4px",
            flexGrow: 0,
            height: "14px",
            width: "60px",
          }}
        />
      </div>
      {props.showSpinner && (
        <div>
          <Spinner size={30} />
        </div>
      )}
    </div>
  );
};

export default IGHeader;
