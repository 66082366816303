import IProductEcoFriendliness from "../../../domain/entities/product/IProductEcoFriendliness";
import ProductAdditionalInformationItem from "./product-additional-item";
import "./styles.css";

interface IProductAdditionalInformationProps {
  data: IProductEcoFriendliness[];
}

export default function ProductAdditionalInformation({
  data,
}: IProductAdditionalInformationProps) {
  return (
    <div className="product-additional-information">
      <div className="product-additional-information-heading">
        Product Certifications
      </div>
      <div className="product-additional-information__eco-friendliness">
        {data.map((item, index) => (
          <ProductAdditionalInformationItem
            key={`product-additional-information-item-${item.name}-${index}`}
            {...item}
          />
        ))}
      </div>
      {/* <div className="product-additional-information-legend">
        <div className="product-additional-information-legend-item green">
          Certified
        </div>
        <div className="product-additional-information-legend-item red">
          Not Certified
        </div>
        <div className="product-additional-information-legend-item unavailable">
          Data Unavailable
        </div>
      </div> */}
    </div>
  );
}
