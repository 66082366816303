import "./styles.css";

interface IProductFilterCheckboxProps {
  name: string;
  slug: string;
  selected: boolean;
  onSelect: (value: string) => void;
}

export default function ProductFilterCheckbox(
  data: IProductFilterCheckboxProps
) {
  return (
    <label className="filter-checkbox-container">
      {data.name}
      <input
        type="checkbox"
        checked={data.selected}
        onChange={() => data.onSelect(data.slug)}
      />
      <span className="checkmark"></span>
    </label>
  );
}
