enum ValidationErrorType {
  MISSING = "missing",
  INVALID = "invalid",
  REQUIRED = "required",
  DUPLICATE = "duplicate",
}

export interface IValidationError {
  loc: string[];
  msg: string;
  type: ValidationErrorType;
}

export interface IValidationErrorDetailResponse {
  field: string;
  message: string;
}

interface IValidationErrorResponse {
  isValidationError: boolean;
  message: string;
  validationErrors: IValidationErrorDetailResponse[];
}

/**
 * This function is used to check if the error is a validation error
 *
 * @param error - AxiosError
 * @returns
 */
export default function validationErrorCheck(
  detail: IValidationError[]
): IValidationErrorResponse {
  try {
    let errorMessage = "";

    const validationErrors = detail.map((error) => {
      errorMessage += `${error.loc.join(", ")}: ${error.msg} \n`;

      return {
        field: error.loc.join(", "),
        message: error.msg,
      };
    });

    return {
      isValidationError: true,
      message: errorMessage,
      validationErrors,
    };
  } catch (error) {
    return {
      isValidationError: false,
      message: "Internal Error Occurred",
      validationErrors: [],
    };
  }
}
