import { useState } from "react";
import HeartIcon from "../../../assets/icons/heart";
import { useUserContext } from "../../../contexts/user";
import ILandingTrendingProduct from "../../../domain/entities/landing/ILandingTrendingProduct";
import ProductService from "../../../domain/services/product-service";
import reduceText from "../../../utilities/text/text-reducer";
import "./styles.css";
import { NavLink } from "react-router-dom";

interface IProductsCarouselItemProps {
  data: ILandingTrendingProduct;
  itemWidth: number;
  productService: ProductService;
}

export default function ProductsCarouselItem({
  data,
  itemWidth,
  productService,
}: IProductsCarouselItemProps) {
  const { isLoggedIn, setWishlistCount } = useUserContext();
  const [inWishlist, setInWishlist] = useState(false);

  async function addToWishlist() {
    setInWishlist(true);
    await productService.addProductToWishlist(data.slug);
    setWishlistCount((prev) => prev + 1);
  }

  return (
    <li
      className="product"
      style={{
        width: `${itemWidth}px`,
        minWidth: `${itemWidth}px`,
      }}
    >
      <div className="products-carousel-item-image">
        {!inWishlist && (
          <div className="products-carousel-item-image-hover">
            {isLoggedIn ? (
              <div
                className="add-to-wishlist"
                title={`Add to your wishlist (${data.name})`}
                onClick={addToWishlist}
              >
                <HeartIcon />
              </div>
            ) : (
              <NavLink
                to="/account/login"
                className="add-to-wishlist"
                state={{ from: window.location.pathname }}
              >
                <div
                  className="add-to-wishlist"
                  title={`Add to your wishlist (${data.name})`}
                >
                  <HeartIcon />
                </div>
              </NavLink>
            )}
            <div className="grey-out"></div>
          </div>
        )}
        <img
          src={encodeURI(data.images[0])}
          onError={({ currentTarget }) => {
            currentTarget.onerror = null;
            currentTarget.src = "/assets/placeholders/product.svg";
          }}
          alt="Product"
          style={{
            width: `${itemWidth - 20}px`,
            height: `${itemWidth - 20}px`,
          }}
        />
      </div>
      <div className="products-carousel-item-details">
        <div className="products-carousel-item-name" title={data.name}>
          <NavLink
            to={`/products/${data.slug}`}
            state={{ from: window.location.pathname }}
          >
            {reduceText(data.name, 40)}
          </NavLink>
        </div>{" "}
        <div className="products-carousel-item-category-name">
          <NavLink
            to={`/categories/${data.category_slug}`}
            state={{ from: window.location.pathname }}
          >
            {data.category}
          </NavLink>
        </div>
      </div>
      <div className="products-carousel-action-button">
        <NavLink
          to={`/products/${data.slug}`}
          state={{
            from: window.location.pathname,
          }}
        >
          Find Out More
        </NavLink>
      </div>
    </li>
  );
}
