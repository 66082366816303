import {
  createContext,
  PropsWithChildren,
  useContext,
  useEffect,
  useState,
} from "react";
import ICategoryContext from "../../domain/entities/context/ICategoryContext";
import ICategory from "../../domain/entities/category/ICategory";
import SessionStorage from "../../domain/storage/session-storage";
import SessionStorageKey from "../../domain/entities/storage/SessionStorageKey";
import CategoryService from "../../domain/services/category-service";

const CategoryContext = createContext({} as ICategoryContext);

const sessionStorage = new SessionStorage();
const categoryService = new CategoryService();

export default function CategoryProvider({ children }: PropsWithChildren) {
  const [categories, setCategories] = useState<ICategory[]>([]);

  useEffect(() => {
    async function fetchCategories() {
      try {
        const categories = await categoryService.getAllCategories({
          only_with_product_variants: false,
        });
        if (categories.status && categories.data) {
          sessionStorage.set(SessionStorageKey.CATEGORIES, categories.data);
          setCategories(categories.data);
        }
      } catch (error) {
        console.error(error);
      }
    }

    const categories = sessionStorage.get(
      SessionStorageKey.CATEGORIES
    ) as ICategory[];
    if (categories) {
      setCategories(categories);
    } else {
      fetchCategories();
    }
  }, []);

  return (
    <CategoryContext.Provider value={{ categories, setCategories }}>
      {children}
    </CategoryContext.Provider>
  );
}

export const useCategoryContext = () => {
  return useContext(CategoryContext);
};
