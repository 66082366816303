import StarRatings from "react-star-ratings";
import "./styles.css";
import { useEffect, useState } from "react";
import ProductFilterCheckbox from "../../../../products-list/product-filter-checkbox";
import ProductService from "../../../../../domain/services/product-service";
import IProductReview from "../../../../../domain/entities/product/IProductReview";
import { useUserContext } from "../../../../../contexts/user";
import UserType from "../../../../../domain/entities/user/UserType";
import SpinnerFullPage from "../../../../loading/spinner-fullpage";
import RecycleBinWithLogoIcon from "../../../../../assets/icons/recycle-bin-with-icon";
import { useCategoryContext } from "../../../../../contexts/category";
import ICategory from "../../../../../domain/entities/category/ICategory";
import PencilIcon from "../../../../../assets/icons/pencil";

interface ICollectReviewProps {
  close(reload: boolean): void;
  productSlug: string;
  categorySlug: string;
  userReview?: IProductReview;
}

const productService = new ProductService();

function getStaticReviews(
  categorySlug: string,
  categories: ICategory[]
): {
  [static_review: string]: boolean;
} {
  const category = categories?.find(
    (category) => category.slug === categorySlug
  );
  if (category) {
    return category.static_review.reduce((acc, review) => {
      acc[review] = false;
      return acc;
    }, {} as { [static_review: string]: boolean });
  }
  return {};
}

export default function CollectReview({
  close,
  productSlug,
  categorySlug,
  userReview,
}: ICollectReviewProps) {
  const [rating, setRating] = useState(userReview?.rating || 0);
  const [comment, setComment] = useState(userReview?.review || "");
  const { categories } = useCategoryContext();
  const [checkBoxes, setCheckboxes] = useState<{
    [static_review: string]: boolean;
  }>(getStaticReviews(categorySlug, categories));
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState("");
  const { user } = useUserContext();

  useEffect(() => {
    if (userReview) {
      setRating(userReview.rating || 0);
      setComment(userReview.review || "");
      const staticReviews = getStaticReviews(categorySlug, categories);
      userReview.static_review?.forEach((review) => {
        if (staticReviews[review] !== undefined) {
          staticReviews[review] = true;
        }
      });
      setCheckboxes(staticReviews);
    }
  }, [categories, categorySlug, userReview]);

  async function setReview() {
    if (rating === 0) {
      setError("Please select a rating");
      return;
    }
    setLoading(true);
    if (userReview) {
      const response = await productService.editProductReview({
        review_id: userReview.review_id,
        review: comment,
        static_review: Object.keys(checkBoxes).filter((key) => checkBoxes[key]),
        rating,
      });

      if (response.status) close(true);
      else {
        setError(response.message);
      }
    } else {
      const response = await productService.addProductReview({
        product_slug: productSlug,
        review: comment,
        static_review: Object.keys(checkBoxes).filter((key) => checkBoxes[key]),
        rating,
      });
      if (response.status) close(true);
      else {
        setError(response.message);
      }
    }

    setLoading(false);
  }

  async function deleteReview() {
    setLoading(true);
    if (userReview) {
      const response = await productService.deleteProductReview(
        userReview.review_id
      );
      if (response.status) close(true);
      else {
        setError(response.message);
      }
    }
    setLoading(false);
  }

  return (
    <div className="collect-review">
      <div className="review-details-heading">Overall Rating</div>
      {loading && <SpinnerFullPage />}
      <StarRatings
        rating={rating}
        numberOfStars={5}
        changeRating={setRating}
        starRatedColor="var(--theme-color)"
        starEmptyColor="var(--theme-color-transparent-2)"
        starHoverColor="var(--theme-color)"
        starDimension="25px"
        starSpacing="1px"
      />
      <div className="review-details-heading">Select Comment</div>
      <div className="review-details-checkbox">
        {Object.keys(checkBoxes).map((key) => (
          <ProductFilterCheckbox
            key={`${key}-checkbox-add-review`}
            name={key}
            slug={key}
            selected={checkBoxes[key]}
            onSelect={() =>
              setCheckboxes({
                ...checkBoxes,
                [key]: !checkBoxes[key],
              })
            }
          />
        ))}
      </div>
      {user?.user_type === UserType.INFLUENCER && (
        <div>
          <div className="review-details-heading">Review</div>
          <textarea
            className="review-details-textarea"
            placeholder="Write your review here"
            value={comment}
            onChange={(e) => setComment(e.target.value)}
          />
        </div>
      )}

      {error && <div className="error-message">{error}</div>}

      <div className="review-details-action">
        {userReview && (
          <button
            type="submit"
            className="review-details-submit"
            onClick={async () => {
              await deleteReview();
            }}
            disabled={loading}
          >
            <RecycleBinWithLogoIcon /> Delete Review
          </button>
        )}
        <button
          type="submit"
          className="review-details-submit"
          onClick={async () => {
            await setReview();
          }}
          disabled={loading}
        >
          {userReview ? (
            <>
              <PencilIcon /> Edit Review
            </>
          ) : (
            "Submit"
          )}
        </button>
      </div>
    </div>
  );
}
