import { useRef, useState, useEffect } from "react";
import "./styles.css";
import CloseIcon2 from "../../assets/icons/close-2";

const TIMEOUT_DELAY = 5;

let interval: NodeJS.Timeout;

export default function CacheControlStatus() {
  const [visible, setVisible] = useState(false);
  const [transitioningOut, setTransitioningOut] = useState(false);
  const [refreshPage, setRefreshPage] = useState(true);
  const [counter, setCounter] = useState(TIMEOUT_DELAY);
  const divRef = useRef<HTMLDivElement>(null);

  function closeHandler(preventRefresh?: true) {
    setTransitioningOut(true);
    setVisible(true);
    setTimeout(() => {
      setVisible(false);
      setTransitioningOut(false);
      divRef.current?.classList.remove("visible");
      if (refreshPage && !preventRefresh) {
        clearInterval(interval);
        window.location.reload();
      }
      setCounter(TIMEOUT_DELAY);
    }, 300);
  }

  useEffect(() => {
    if (visible) {
      setTimeout(() => {
        closeHandler();
        clearInterval(interval);
      }, TIMEOUT_DELAY * 1000);
      interval = setInterval(() => {
        if (counter > 0) setCounter((prev) => prev - 1);
      }, 1000);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [visible]);

  useEffect(() => {
    const targetNode = divRef.current;
    if (!targetNode) return;

    const config = { attributes: true, attributeFilter: ["class"] };

    const callback = (mutationsList: MutationRecord[]) => {
      for (const mutation of mutationsList) {
        if (
          mutation.type === "attributes" &&
          mutation.attributeName === "class"
        ) {
          if (targetNode.classList.contains("visible")) {
            setVisible(true);
          } else {
            setVisible(false);
          }
        }
      }
    };

    const observer = new MutationObserver(callback);
    observer.observe(targetNode, config);

    return () => {
      observer.disconnect();
    };
  }, []);

  return (
    <div
      id="cache-control-status"
      className={`cache-control-status${visible ? " visible" : ""}${
        transitioningOut ? " transitioning-out" : ""
      }`}
      ref={divRef}
    >
      <div
        className="close-icon"
        onClick={() => {
          closeHandler();
        }}
      >
        <CloseIcon2 />
      </div>
      <h2>Boujeeher is updated to latest version</h2>
      <h3>App Refreshes in {counter} seconds</h3>
      <p>
        Your app updated automatically in the background. Latest updates will be
        available when you visit Boujeeher next time.
      </p>
      <button
        onClick={() => {
          setRefreshPage(false);
          closeHandler(true);
        }}
      >
        Don't Refresh. I'll Update On The Next Reload
      </button>
    </div>
  );
}
