import IApiResponse from "../entities/api/IApiResponse";
import IProduct from "../entities/product/IProduct";
import IProductFilter from "../entities/product/IProductFilter";
import IProductMini from "../entities/product/IProductMini";
import IProductPriceFilter from "../entities/product/IProductPriceFilter";
import IProductReview from "../entities/product/IProductReview";
import IProductReviewBrief, {
  IProductIndividualStarRatings,
} from "../entities/product/IProductReviewBrief";
import IProductReviewEditRequest from "../entities/product/IProductReviewEditRequest";
import IProductReviewRequest from "../entities/product/IProductReviewRequest";
import ISearchProductRequest from "../entities/product/ISearchProductRequest";
import IShadeFinderProduct from "../entities/product/IShadeFinderProduct";
import IProductService from "../entities/services/IProductService";
import ProductRepository from "../repositories/product-repository";

export default class ProductService implements IProductService {
  private productRepository: ProductRepository;

  constructor() {
    this.productRepository = new ProductRepository();
  }

  async getProduct(slug: string): Promise<IApiResponse<IProduct | false>> {
    try {
      const response = await this.productRepository.getProductBasedOnSlug(slug);

      return response;
    } catch (error) {
      return {
        status: false,
        message: "Failed to fetch the product",
        requestDuration: 0,
      };
    }
  }

  async getReviews(
    productSlug: string
  ): Promise<IApiResponse<IProductReview[]>> {
    try {
      const response = await this.productRepository.getProductReviews(
        productSlug
      );

      return response;
    } catch (error) {
      return {
        status: false,
        message: "Failed to fetch reviews of the product",
        requestDuration: 0,
      };
    }
  }

  calculateProductReviewBrief(reviews: IProductReview[]): IProductReviewBrief {
    try {
      const individualStarReview: IProductIndividualStarRatings[] = [
        {
          star: 5,
          ratingCount: 0,
          ratingPercentage: 0,
        },
        {
          star: 4,
          ratingCount: 0,
          ratingPercentage: 0,
        },
        {
          star: 3,
          ratingCount: 0,
          ratingPercentage: 0,
        },
        {
          star: 2,
          ratingCount: 0,
          ratingPercentage: 0,
        },
        {
          star: 1,
          ratingCount: 0,
          ratingPercentage: 0,
        },
      ];
      const reviewBrief: IProductReviewBrief = {
        totalRating: 0,
        reviewCount: 0,
        individualRatings: individualStarReview,
        staticReviewCounts: {},
      };

      if (reviews.length === 0) {
        return reviewBrief;
      }

      reviews.forEach((review) => {
        reviewBrief.totalRating += review.rating;
        reviewBrief.reviewCount += 1;

        review.static_review?.forEach((staticReview) => {
          if (reviewBrief.staticReviewCounts[staticReview]) {
            reviewBrief.staticReviewCounts[staticReview] += 1;
          } else {
            reviewBrief.staticReviewCounts[staticReview] = 1;
          }
        });

        if (review.rating > 4 && review.rating <= 5) {
          reviewBrief.individualRatings[0].ratingCount += 1;
        }

        if (review.rating > 3 && review.rating <= 4) {
          reviewBrief.individualRatings[1].ratingCount += 1;
        }

        if (review.rating > 2 && review.rating <= 3) {
          reviewBrief.individualRatings[2].ratingCount += 1;
        }

        if (review.rating > 1 && review.rating <= 2) {
          reviewBrief.individualRatings[3].ratingCount += 1;
        }

        if (review.rating > 0 && review.rating <= 1) {
          reviewBrief.individualRatings[4].ratingCount += 1;
        }
      });

      reviewBrief.staticReviewCounts = Object.entries(
        reviewBrief.staticReviewCounts
      )
        .sort((a, b) => b[1] - a[1])
        .reduce((acc, [key, value]) => {
          acc[key] = value;
          return acc;
        }, {} as { [key: string]: number });

      reviewBrief.totalRating =
        reviewBrief.totalRating / reviewBrief.reviewCount;
      reviewBrief.individualRatings.forEach((rating) => {
        rating.ratingPercentage =
          (rating.ratingCount / reviewBrief.reviewCount) * 100;
      });

      return reviewBrief;
    } catch (error) {
      return {
        totalRating: 0,
        reviewCount: 0,
        staticReviewCounts: {},
        individualRatings: [
          {
            star: 5,
            ratingCount: 0,
            ratingPercentage: 0,
          },
          {
            star: 4,
            ratingCount: 0,
            ratingPercentage: 0,
          },
          {
            star: 3,
            ratingCount: 0,
            ratingPercentage: 0,
          },
          {
            star: 2,
            ratingCount: 0,
            ratingPercentage: 0,
          },
          {
            star: 1,
            ratingCount: 0,
            ratingPercentage: 0,
          },
        ],
      };
    }
  }

  async searchProducts(
    filter: ISearchProductRequest
  ): Promise<
    IApiResponse<{ products: IProductMini[]; filters: IProductFilter }>
  > {
    try {
      const response = await this.productRepository.searchProducts(filter);
      if (!response.status || !response.data)
        return {
          status: false,
          message: response.message,
          requestDuration: response.requestDuration || 0,
        };

      const filters = this.findFilters(response.data);
      return {
        status: true,
        message: response.message,
        data: {
          products: response.data,
          filters,
        },
        pagination: response.pagination,
        requestDuration: response.requestDuration,
      };
    } catch (error) {
      return {
        status: false,
        message: "Failed to fetch the products",
        requestDuration: 0,
      };
    }
  }
  async searchSimilarProductsBasedOnVariant(
    variantId: number
  ): Promise<IApiResponse<IShadeFinderProduct[]>> {
    return this.productRepository.searchSimilarProducts(variantId);
  }

  async getProductsByCategory(
    category_slug: string,
    filter: ISearchProductRequest
  ): Promise<
    IApiResponse<{ products: IProductMini[]; filters: IProductFilter }>
  > {
    try {
      const response = await this.productRepository.searchProducts({
        ...filter,
        category_slugs: [category_slug],
      });

      if (!response.status || !response.data)
        return {
          status: false,
          message: response.message,
          requestDuration: response.requestDuration || 0,
        };

      const filters = this.findFilters(response.data);
      return {
        status: true,
        message: response.message,
        data: {
          products: response.data,
          filters,
        },
        pagination: response.pagination,
        requestDuration: response.requestDuration,
      };
    } catch (error) {
      return {
        status: false,
        message: "Failed to fetch the products",
        requestDuration: 0,
      };
    }
  }

  async getProductsFromBrand(
    brand_slug: string,
    filter: ISearchProductRequest
  ): Promise<
    IApiResponse<{ products: IProductMini[]; filters: IProductFilter }>
  > {
    try {
      const response = await this.productRepository.searchProducts({
        ...filter,
        brand_slugs: [brand_slug],
      });
      if (!response.status || !response.data)
        return {
          status: false,
          message: response.message,
          requestDuration: response.requestDuration || 0,
        };

      const filters = this.findFilters(response.data);
      return {
        status: true,
        message: response.message,
        data: {
          products: response.data,
          filters,
        },
        pagination: response.pagination,
        requestDuration: response.requestDuration,
      };
    } catch (error) {
      return {
        status: false,
        message: "Failed to fetch the products",
        requestDuration: 0,
      };
    }
  }

  async addProductReview(
    data: IProductReviewRequest
  ): Promise<IApiResponse<null>> {
    return this.productRepository.addProductReview(data);
  }

  async editProductReview(
    data: IProductReviewEditRequest
  ): Promise<IApiResponse<null>> {
    return this.productRepository.editProductReview(data);
  }

  async deleteProductReview(reviewId: number): Promise<IApiResponse<null>> {
    return this.productRepository.deleteProductReview(reviewId);
  }

  async likeProductReview(reviewId: number): Promise<IApiResponse<null>> {
    return this.productRepository.likeProductReview(reviewId);
  }

  async dislikeProductReview(reviewId: number): Promise<IApiResponse<null>> {
    return this.productRepository.dislikeProductReview(reviewId);
  }

  async undoLikeDislikeProductReview(
    reviewId: number
  ): Promise<IApiResponse<null>> {
    return this.productRepository.undoLikeDislikeProductReview(reviewId);
  }

  async addProductToWishlist(productSlug: string): Promise<IApiResponse<null>> {
    return this.productRepository.addProductToWishlist(productSlug);
  }

  async removeProductFromWishlist(
    productSlug: string
  ): Promise<IApiResponse<null>> {
    return this.productRepository.removeProductFromWishlist(productSlug);
  }

  private findFilters(products: IProductMini[]): IProductFilter {
    let filters: IProductFilter = {
      brands: [],
      categories: [],
      others: [],
      price: this.generatePriceFilter(products),
    };

    products.forEach((product) => {
      const brand = filters.brands.find(
        (brand) => brand.slug === product.brand_slug
      );
      if (!brand) {
        filters.brands.push({
          name: product.brand,
          slug: product.brand_slug,
        });
      }

      const category = filters.categories.find(
        (cat) => cat.slug === product.category_slug
      );
      if (!category) {
        filters.categories.push({
          name: product.category,
          slug: product.category_slug,
        });
      }
    });

    filters.brands = filters.brands.sort((brand) => {
      return brand.name.localeCompare(brand.name);
    });
    filters.categories = filters.categories.sort((category) => {
      return category.name.localeCompare(category.name);
    });

    return filters;
  }

  private generatePriceFilter(products: IProductMini[]): IProductPriceFilter {
    if (products.length === 0) {
      return {
        minPrice: 0,
        maxPrice: 1,
        currency: "",
      };
    }
    const prices = products.map((product) => product.price);
    const minPrice = Math.min(...prices);
    const maxPrice = Math.max(...prices);
    const currency = products[0]?.currency || "";

    return {
      minPrice,
      maxPrice,
      currency,
    };
  }
}
