import BreadcrumbsNavUrl, {
  IBreadcrumbsNavUrlProps,
} from "./breadcrumbs-nav-url";
import "./styles.css";

interface IBreadcrumbsProps {
  data: IBreadcrumbsNavUrlProps[];
}

export default function Breadcrumbs({ data }: IBreadcrumbsProps) {
  return (
    <div className="breadcrumbs">
      <ul className="breadcrumbs-nav">
        <BreadcrumbsNavUrl to="/" label="Home" />
        {data.map((item, index) => (
          <BreadcrumbsNavUrl key={index} to={item.to} label={item.label} />
        ))}
      </ul>
    </div>
  );
}
