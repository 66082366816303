import { useEffect, useState } from "react";
import IProductReview from "../../../domain/entities/product/IProductReview";
import ReviewBrief from "./review-brief";
import ReviewItem from "./review-item";
import IProductReviewBrief from "../../../domain/entities/product/IProductReviewBrief";
import DropdownMenu from "../../utilities/dropdown";
import "./styles.css";
import IApiStatus from "../../../domain/entities/api/IApiStatus";
import SkeletonLoading from "../../loading/skeleton-loading";
import ErrorComponent from "../../error";

interface IProductReviewsProps {
  data: IProductReview[];
  reviewBrief?: IProductReviewBrief;
  apiStatus: IApiStatus;
}

export default function ProductReviews({
  data,
  reviewBrief,
  apiStatus,
}: IProductReviewsProps) {
  const [filteredReviews, setFilteredReviews] =
    useState<IProductReview[]>(data);

  useEffect(() => {
    setFilteredReviews(data);
  }, [data]);

  return apiStatus.loading ? (
    <div className="product-review">
      <div className="review-brief">
        <div className="main-review">
          <SkeletonLoading />
        </div>
        <div className="individual-star-review-count">
          {Array.from({ length: 5 }).map((_, index) => (
            <div key={`rating-${index}`} className="individual-star-review">
              <SkeletonLoading />
            </div>
          ))}
        </div>
      </div>
      <div className="review-filter">
        <SkeletonLoading />
      </div>
      <div className="individual-reviews">
        <div className="review-item">
          <div className="reviewer-name">
            <SkeletonLoading />
          </div>
          <div className="review-body">
            <SkeletonLoading />
          </div>
        </div>
      </div>
    </div>
  ) : !apiStatus.status ? (
    <div className="product-review">
      <ErrorComponent
        title="An Error Occurred"
        description="Our server was unable to process your request"
        errorCode={apiStatus.message}
      />
    </div>
  ) : reviewBrief ? (
    <div className="product-review">
      <ReviewBrief data={reviewBrief} />
      {data.length > 0 && (
        <div className="review-filter">
          <div className="review-count">{filteredReviews.length} reviews</div>
          <div className="review-filter-actions">
            <div className="review-filter-action">
              Filter by Rating{" "}
              <DropdownMenu
                id="rating-filter"
                title="Filter by Rating"
                options={[
                  { label: "All Stars", value: "All Stars" },
                  { label: "1 Star", value: "1 Star" },
                  { label: "2 Stars", value: "2 Stars" },
                  { label: "3 Stars", value: "3 Stars" },
                  { label: "4 Stars", value: "4 Stars" },
                  { label: "5 Stars", value: "5 Stars" },
                ]}
                onSelect={(value) => {
                  if (value === "All Stars") {
                    setFilteredReviews(data);
                    return;
                  }

                  const filteredData = data.filter(
                    (review) => review.rating === parseInt(value)
                  );

                  setFilteredReviews(filteredData);
                }}
              />
            </div>
            <div className="review-filter-action">
              Sort By{" "}
              <DropdownMenu
                id="sort-filter"
                title="Sort By"
                options={[
                  { label: "Most Helpful", value: "Most Helpful" },
                  { label: "Most Recent", value: "Most Recent" },
                  { label: "Highest Rated", value: "Highest Rated" },
                ]}
                onSelect={(value) => {
                  if (value === "Most Recent") {
                    const sortedData = [...filteredReviews].sort(
                      (a, b) =>
                        new Date(b.created_at).getTime() -
                        new Date(a.created_at).getTime()
                    );

                    setFilteredReviews(sortedData);
                  } else if (value === "Most Helpful") {
                    // const sortedData = [...filteredReviews].sort(
                    //   (a, b) => b.helpfulCount - a.helpfulCount
                    // );
                    const sortedData = [...filteredReviews];

                    setFilteredReviews(sortedData);
                  } else if (value === "Highest Rated") {
                    const sortedData = [...filteredReviews].sort(
                      (a, b) => b.rating - a.rating
                    );

                    setFilteredReviews(sortedData);
                  }
                }}
              />
            </div>
          </div>
        </div>
      )}
      <div className="individual-reviews">
        {filteredReviews.map((review, index) => (
          <ReviewItem key={`review-${index}`} data={review} />
        ))}
      </div>
    </div>
  ) : (
    <div className="product-review">
      <ErrorComponent
        title="An Error Occurred"
        description="Our server was unable to process your request"
        errorCode={apiStatus.message}
      />
    </div>
  );
}
