export enum ISearchProductSortBy {
  PRICE_ASC = "price_asc",
  PRICE_DESC = "price_desc",
  RATING = "rating",
  REVIEWS = "reviews",
  NAME = "name",
}

export default interface ISearchProductRequest {
  query?: string;
  page_number: number;
  page_limit: number;
  product_slug?: string;
  category_slugs?: string[];
  brand_slugs?: string[];
  must_have_variant?: boolean;
  min_price?: number;
  max_price?: number;
  sort_by?: ISearchProductSortBy;
}

export interface ISearchProductPagination {
  total_records: number;
  records_matching_search: number;
  total_pages: number;
  current_page: number;
  page_length: number;
  is_last_page: boolean;
}
