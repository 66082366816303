import { useEffect, useState } from "react";
import AppLogo from "./app-logo";
import HeaderNav from "./navigation";
import HeaderSearch from "./search";
import "./styles.css";
import HeaderUserProfile from "./user";
import MenuIcon from "../../assets/icons/menu";
import HeaderDrawer from "./drawer";
import { useLocation } from "react-router-dom";

export default function Header() {
  const [mobileHeaderVisible, setMobileHeaderVisible] =
    useState<boolean>(false);
  const [isScrolled, setIsScrolled] = useState<boolean>(false);
  const { pathname } = useLocation();

  useEffect(() => {
    function handleScroll() {
      if (window.scrollY > 0) {
        setIsScrolled(true);
      } else {
        setIsScrolled(false);
      }
    }

    window.addEventListener("scroll", handleScroll);
    return () => window.removeEventListener("scroll", handleScroll);
  }, []);

  useEffect(() => {
    setMobileHeaderVisible(false);
  }, [pathname]);

  return (
    <header className={`${isScrolled ? "drop-shadow" : ""}`}>
      <div className="header-content">
        <div className="header-app-logo">
          <div
            className="mobile-app-header-button"
            onClick={() => setMobileHeaderVisible(!mobileHeaderVisible)}
          >
            <MenuIcon />
          </div>
          <AppLogo />
        </div>
        <HeaderNav />
        <div className="header-actions">
          <HeaderSearch />
          <HeaderUserProfile />
        </div>
      </div>
      <HeaderDrawer
        visible={mobileHeaderVisible}
        close={() => setMobileHeaderVisible(false)}
      />
    </header>
  );
}
