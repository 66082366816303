import ILocalStorageValue from "../entities/storage/ILocalStorageValue";
import IStorageService from "../entities/storage/IStorageService";
import LocalStorageKey from "../entities/storage/LocalStorageKey";

export default class LocalStorage
  implements IStorageService<LocalStorageKey, ILocalStorageValue>
{
  get(key: LocalStorageKey): ILocalStorageValue[LocalStorageKey] | null {
    const value = localStorage.getItem(key);
    return value ? JSON.parse(value) : null;
  }

  set(
    key: LocalStorageKey,
    value: ILocalStorageValue[LocalStorageKey]
  ): boolean {
    try {
      localStorage.setItem(key, JSON.stringify(value));
      return true;
    } catch (error) {
      return false;
    }
  }

  remove(key: LocalStorageKey): boolean {
    try {
      localStorage.removeItem(key);
      return true;
    } catch (error) {
      return false;
    }
  }

  clear(): boolean {
    try {
      localStorage.clear();
      return true;
    } catch (error) {
      return false;
    }
  }
}
