import SunBathingIcon from "../../../assets/icons/sunbathing";
import SEO from "../../../components/seo";
import "./styles.css";

export default function Error404Page() {
  return (
    <div className="error-page">
      <SEO
        title="404 - Not Found"
        description="This page doesn't exist in Boujeeher"
        dontIndex={true}
      />
      <SunBathingIcon />
      <div className="error-heading">Looks Like You're Lost In The Sea</div>
      <div className="error-description">
        The page you're looking for doesn't exist. Or, you don't have the
        permission to view it.
      </div>
      <div className="error-code">404 | Page Not Found</div>
    </div>
  );
}
