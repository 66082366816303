import { NavLink } from "react-router-dom";
import "./styles.css";

export default function FooterNavigation() {
  return (
    <nav className="footer-navigation">
      <h2>Quick Links</h2>
      <ul className="footer-navigation-grid">
        <li>
          <NavLink
            to="/informations/about-us"
            state={{
              from: window.location.pathname,
            }}
          >
            About Us
          </NavLink>
        </li>
        <li>
          <NavLink
            to="/informations/contact-us"
            state={{
              from: window.location.pathname,
            }}
          >
            Contact Us
          </NavLink>
        </li>
        <li>
          <NavLink
            to="/informations/terms-and-conditions"
            state={{
              from: window.location.pathname,
            }}
          >
            Terms and Conditions
          </NavLink>
        </li>
        <li>
          <NavLink
            to="/informations/privacy-policy"
            state={{
              from: window.location.pathname,
            }}
          >
            Privacy Policy
          </NavLink>
        </li>
        <li>
          <NavLink
            to="/informations/sitemap"
            state={{
              from: window.location.pathname,
            }}
          >
            Sitemap
          </NavLink>
        </li>
      </ul>
    </nav>
  );
}
