import { useEffect, useState } from "react";
import CameraIcon from "../../../../assets/icons/camera";
import IUser from "../../../../domain/entities/user/IUser";
import "./styles.css";
import WishlistType from "../../../../domain/entities/user/WishlistType";
import AuthService from "../../../../domain/services/auth-service";
import SpinnerFullPage from "../../../loading/spinner-fullpage";
import AvatarIcon from "../../../../assets/icons/avatar";

interface IEditProfileFormProps {
  data: IUser;
  close: () => void;
  reload: () => void;
}

interface IFieldErrors {
  first_name: string;
  surname: string;
  short_description: string;
  facebook: string;
  instagram: string;
  twitter: string;
  tiktok: string;
}

const authService = new AuthService();

export default function EditProfileForm({
  data,
  close,
  reload,
}: IEditProfileFormProps) {
  const [image, setImage] = useState<File | null>(null);
  const [user, setUser] = useState<IUser>(data);
  const [error, setError] = useState("");
  const [fieldErrors, setFieldErrors] = useState<IFieldErrors>({
    first_name: "",
    surname: "",
    short_description: "",
    facebook: "",
    instagram: "",
    twitter: "",
    tiktok: "",
  });
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    setUser(data);
  }, [data]);

  function verifyFaceBookUrl(url: string): boolean {
    return !!url.match(/(https:\/\/)(www\.)?facebook.com\/[a-zA-Z0-9(.?)?]/);
  }

  function verifyInstagramUrl(url: string): boolean {
    return !!url.match(/(https:\/\/)(www\.)?instagram.com\/[a-zA-Z0-9(.?)?]/);
  }

  function verifyTwitterUrl(url: string): boolean {
    return !!url.match(/(https:\/\/)(www\.)?(twitter|x).com\/[a-zA-Z0-9(.?)?]/);
  }

  function verifyTikTokUrl(url: string): boolean {
    return !!url.match(/(https:\/\/)(www\.)?tiktok.com\/[a-zA-Z0-9(.?)?]/);
  }

  function handleSocialUrlType(): void {
    setFieldErrors((prev) => ({
      ...prev,
      facebook: "",
      instagram: "",
      twitter: "",
      tiktok: "",
    }));

    if (user.socials.facebook && !verifyFaceBookUrl(user.socials.facebook)) {
      setFieldErrors((prev) => ({
        ...prev,
        facebook: "Invalid Facebook URL",
      }));
    }

    if (user.socials.instagram && !verifyInstagramUrl(user.socials.instagram)) {
      setFieldErrors((prev) => ({
        ...prev,
        instagram: "Invalid Instagram URL",
      }));
    }

    if (user.socials.twitter && !verifyTwitterUrl(user.socials.twitter)) {
      setFieldErrors((prev) => ({ ...prev, twitter: "Invalid Twitter URL" }));
    }

    if (user.socials.tiktok && !verifyTikTokUrl(user.socials.tiktok)) {
      setFieldErrors((prev) => ({ ...prev, tiktok: "Invalid TikTok URL" }));
    }
  }

  async function submitHandler(event: React.FormEvent<HTMLFormElement>) {
    event.preventDefault();
    setLoading(true);
    setError("");
    try {
      if (user.socials.facebook && !verifyFaceBookUrl(user.socials.facebook)) {
        setError("Invalid Facebook URL");
        setLoading(false);
        return;
      }

      if (
        user.socials.instagram &&
        !verifyInstagramUrl(user.socials.instagram)
      ) {
        setError("Invalid Instagram URL");
        setLoading(false);
        return;
      }

      if (user.socials.twitter && !verifyTwitterUrl(user.socials.twitter)) {
        setError("Invalid Twitter URL");
        setLoading(false);
        return;
      }

      if (user.socials.tiktok && !verifyTikTokUrl(user.socials.tiktok)) {
        setError("Invalid TikTok URL");
        setLoading(false);
        return;
      }

      const response = await authService.updateMyProfile(
        user,
        image || undefined
      );
      if (response.status) {
        close();
        reload();
      } else setError(`Uh oh! An Error Occurred, Detail: ${response.message}`);
    } catch (error) {
      setError("An error occurred");
    }
    setLoading(false);
  }

  return (
    <form onSubmit={submitHandler} className="edit-profile-form">
      {loading && <SpinnerFullPage />}
      <div className="edit-profile-personal">
        <div className="edit-profile-image">
          <input
            id="edit-profile-image"
            type="file"
            accept="image/*"
            hidden
            onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
              if (event.target.files) setImage(event.target.files[0]);
            }}
          />
          <label htmlFor="edit-profile-image" className="file-label">
            <CameraIcon />
          </label>
          {data.image_url || image ? (
            <img
              src={image ? URL.createObjectURL(image) : data.image_url}
              alt="Edit your profile"
            />
          ) : (
            <AvatarIcon />
          )}
        </div>
        <div className="edit-profile-name">
          <label htmlFor="edit-profile-first-name">First Name</label>
          <input
            id="edit-profile-first-name"
            type="text"
            className={fieldErrors.first_name ? "error" : ""}
            value={user.first_name}
            onChange={(event: React.ChangeEvent<HTMLInputElement>) =>
              setUser({ ...user, first_name: event.target.value })
            }
          />
          {fieldErrors.first_name && (
            <div className="input-error">{fieldErrors.first_name}</div>
          )}

          <label htmlFor="edit-profile-surname">Surname</label>
          <input
            id="edit-profile-surname"
            type="text"
            className={fieldErrors.surname ? "error" : ""}
            value={user.surname}
            onChange={(event: React.ChangeEvent<HTMLInputElement>) =>
              setUser({ ...user, surname: event.target.value })
            }
          />
          {fieldErrors.surname && (
            <div className="input-error">{fieldErrors.surname}</div>
          )}
        </div>
      </div>
      <div className="edit-details-rest">
        <label htmlFor="edit-profile-bio">Your Bio</label>
        <textarea
          id="edit-profile-bio"
          className={fieldErrors.short_description ? "error" : ""}
          value={user.short_description}
          onChange={(event: React.ChangeEvent<HTMLTextAreaElement>) =>
            setUser({ ...user, short_description: event.target.value })
          }
        />
        {fieldErrors.short_description && (
          <div className="input-error">{fieldErrors.short_description}</div>
        )}
        <div className="edit-profile-wishlist">
          <label>Wishlist Privacy</label>
          <div className="edit-profile-wishlist-action">
            <button
              type="button"
              className={
                user.wishlist_type === WishlistType.PUBLIC ? "active" : ""
              }
              onClick={() =>
                setUser({ ...user, wishlist_type: WishlistType.PUBLIC })
              }
            >
              Public
            </button>
            <button
              type="button"
              className={
                user.wishlist_type === WishlistType.PRIVATE ? "active" : ""
              }
              onClick={() =>
                setUser({ ...user, wishlist_type: WishlistType.PRIVATE })
              }
            >
              Private
            </button>
          </div>
        </div>
        <div className="edit-profile-socials">
          <div className="edit-profile-socials-heading">Connect Socials</div>
          <div className="edit-profile-socials-action">
            <div className="edit-profile-socials-item">
              <label htmlFor="edit-profile-facebook">Facebook</label>
              <input
                id="edit-profile-facebook"
                type="text"
                placeholder="https://www.facebook.com/...."
                className={fieldErrors.facebook ? "error" : ""}
                value={user.socials.facebook}
                onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                  setUser({
                    ...user,
                    socials: { ...user.socials, facebook: event.target.value },
                  });
                  handleSocialUrlType();
                }}
              />
              {fieldErrors.facebook && (
                <div className="input-error">{fieldErrors.facebook}</div>
              )}
            </div>
            <div className="edit-profile-socials-item">
              <label htmlFor="edit-profile-instagram">Instagram</label>
              <input
                id="edit-profile-instagram"
                type="text"
                placeholder="https://www.instagram.com/...."
                className={fieldErrors.instagram ? "error" : ""}
                value={user.socials.instagram}
                onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                  setUser({
                    ...user,
                    socials: { ...user.socials, instagram: event.target.value },
                  });
                  handleSocialUrlType();
                }}
              />
              {fieldErrors.instagram && (
                <div className="input-error">{fieldErrors.instagram}</div>
              )}
            </div>
            <div className="edit-profile-socials-item">
              <label htmlFor="edit-profile-twitter">Twitter</label>
              <input
                id="edit-profile-twitter"
                type="text"
                className={fieldErrors.twitter ? "error" : ""}
                placeholder="https://www.twitter.com/...."
                value={user.socials.twitter}
                onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                  setUser({
                    ...user,
                    socials: { ...user.socials, twitter: event.target.value },
                  });
                  handleSocialUrlType();
                }}
              />
              {fieldErrors.twitter && (
                <div className="input-error">{fieldErrors.twitter}</div>
              )}
            </div>
            <div className="edit-profile-socials-item">
              <label htmlFor="edit-profile-tiktok">TikTok</label>
              <input
                id="edit-profile-tiktok"
                type="text"
                className={fieldErrors.tiktok ? "error" : ""}
                placeholder="https://www.tiktok.com/...."
                value={user.socials.tiktok}
                onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                  setUser({
                    ...user,
                    socials: { ...user.socials, tiktok: event.target.value },
                  });
                  handleSocialUrlType();
                }}
              />
              {fieldErrors.tiktok && (
                <div className="input-error">{fieldErrors.tiktok}</div>
              )}
            </div>
          </div>
        </div>
      </div>
      {error && <div className="edit-profile-error">{error}</div>}
      <div className="edit-profile-submit">
        <div className="button reverse" onClick={close}>
          Cancel
        </div>
        <button className="button" type="submit">
          Submit
        </button>
      </div>
    </form>
  );
}
