import { NavLink, Navigate, useLocation } from "react-router-dom";
import Breadcrumbs from "../../../components/breadcrumbs";
import SEO from "../../../components/seo";
import FacebookLogo from "../../../assets/logos/facebook";
import AppleLogo from "../../../assets/logos/apple";
import { useUserContext } from "../../../contexts/user";
import "./styles.css";
import { baseApiUrl } from "../../../domain/api";
import SessionStorage from "../../../domain/storage/session-storage";
import { useEffect } from "react";
import SessionStorageKey from "../../../domain/entities/storage/SessionStorageKey";

const sessionStorage = new SessionStorage();

export default function LoginPage() {
  const { isLoggedIn } = useUserContext();
  const location = useLocation();

  useEffect(() => {
    sessionStorage.set(SessionStorageKey.STATE, location.state);
  }, [location.state]);

  if (isLoggedIn) {
    return (
      <Navigate
        to={location.state?.from || "/"}
        state={{ from: location.state?.from || "/" }}
      />
    );
  }

  return (
    <div className="login-page">
      <SEO
        title="Login or Connect Your Account"
        description="Login or connect your account to Boujeeher to get started with your shopping experience."
      />
      <Breadcrumbs
        data={[{ label: "Connect Your Account", to: window.location.pathname }]}
      />
      <div className="login-page-content">
        <div className="login-page-content-image">
          <img
            src="/assets/images/login-sidedrop.jpg"
            alt="Login to your account using any of the socials"
          />
        </div>
        <div className="login-page-content-action">
          <div className="login-page-header">Login to your account</div>
          <div className="login-page-socials">
            <div className="login-page-social-button">
              <a href={`${baseApiUrl}/api/v1/auth/google/login`}>
                <img src="/assets/social-icons/googlelogo.png" alt="Google" />
                <span>Continue with Google</span>
              </a>
            </div>
            <div className="login-page-social-button">
              <NavLink
                to={location.pathname}
                state={{
                  from: location.state?.from,
                }}
              >
                <FacebookLogo />
                <span>Continue with Facebook</span>
              </NavLink>
            </div>
            <div className="login-page-social-button">
              <NavLink
                to={location.pathname}
                state={{
                  from: location.state?.from,
                }}
              >
                <AppleLogo />
                <span>Continue with Apple</span>
              </NavLink>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
