import { useEffect, useState } from "react";
import IApiStatus from "../../../domain/entities/api/IApiStatus";
import ILandingTrendingProduct from "../../../domain/entities/landing/ILandingTrendingProduct";
import LandingService from "../../../domain/services/landing-service";
import "./styles.css";
import ProductsCarousel from "../../products-carousel";
import DropdownMenu from "../../utilities/dropdown";
import ICategory from "../../../domain/entities/category/ICategory";
import ErrorComponentTiny from "../../error/tiny";
import SkeletonLoading from "../../loading/skeleton-loading";

interface ILandingTrendingProps {
  landingService: LandingService;
  categories: ICategory[];
}

export default function LandingTrending({
  landingService,
  categories,
}: ILandingTrendingProps) {
  const [trendingProducts, setTrendingProducts] = useState<
    ILandingTrendingProduct[] | undefined
  >(undefined);
  const [trendingApiDetails, setTrendingApiDetails] = useState<IApiStatus>({
    status: false,
    message: "",
    loading: true,
  });
  const [trendingCategory, setTrendingCategory] = useState<string>(
    categories[0]?.slug || ""
  );

  const [newlyAddedProducts, setNewlyAddedProducts] = useState<
    ILandingTrendingProduct[] | undefined
  >(undefined);
  const [newlyAddedApiDetails, setNewlyAddedApiDetails] = useState<IApiStatus>({
    status: false,
    message: "",
    loading: true,
  });
  const [newlyAddedCategory, setNewlyAddedCategory] = useState<string>(
    categories[0]?.slug || ""
  );

  useEffect(() => {
    if (categories.length === 0) return;
    setTrendingCategory(categories[0].slug);
    setNewlyAddedCategory(categories[0].slug);
  }, [categories]);

  useEffect(() => {
    async function fetchTrendingProducts() {
      setTrendingApiDetails({
        status: false,
        message: "",
        loading: true,
      });

      if (!trendingCategory) return;
      const response = await landingService.getTrendingProducts(
        trendingCategory
      );

      if (response.status && response.data) {
        setTrendingProducts(response.data);
      }

      setTrendingApiDetails({
        status: response.status || false,
        message: response.message || "Unknown Error Occurred",
        loading: false,
      });
    }

    fetchTrendingProducts();
  }, [landingService, trendingCategory]);

  useEffect(() => {
    async function fetchNewlyAddedProducts() {
      setNewlyAddedApiDetails({
        status: false,
        message: "",
        loading: true,
      });
      if (!newlyAddedCategory) return;

      const response = await landingService.getNewProducts(newlyAddedCategory);

      if (response.status && response.data) {
        setNewlyAddedProducts(response.data);
      }

      setNewlyAddedApiDetails({
        status: response.status || false,
        message: response.message || "Unknown Error Occurred",
        loading: false,
      });
    }

    fetchNewlyAddedProducts();
  }, [landingService, newlyAddedCategory]);

  return (
    <div className="landing-trending-products">
      <div className="landing-trending-products-list">
        <div className="landing-trending-header">
          <div className="landing-trending-header-text">Trending Now</div>
          {categories.length > 0 && (
            <div className="landing-trending-header-dropdown">
              <DropdownMenu
                id="trending-select"
                title="Choose the filter category for trending products"
                options={categories.map((category) => ({
                  label: category.name,
                  value: category.slug,
                }))}
                onSelect={setTrendingCategory}
              />
            </div>
          )}
        </div>
        {trendingApiDetails.loading || categories.length === 0 ? (
          <div className="products-carousel">
            <ul className="products">
              {Array.from({ length: 10 }).map((_, index) => (
                <li key={`sk-ld-new-${index}`}>
                  <SkeletonLoading />
                </li>
              ))}
            </ul>
          </div>
        ) : trendingApiDetails.status && trendingProducts ? (
          trendingProducts.length === 0 ? (
            <ErrorComponentTiny
              title="Check back later for trending products."
              description="Welcome to Boujeeher! While the current category might not have any trending products, fear not! There’s a whole world of exciting discoveries waiting for you. Explore other categories, from fashion to gadgets, home decor to wellness. Who knows? The next big trend might be just a click away. And don’t forget to check back later—we’re always updating our offerings. Happy exploring! 🌟"
            />
          ) : (
            <ProductsCarousel data={trendingProducts} type="trending" />
          )
        ) : (
          <ErrorComponentTiny
            title="An Error Occurred"
            description="Try again later"
          />
        )}
      </div>
      <div className="landing-trending-products-list">
        <div className="landing-trending-header">
          <div className="landing-trending-header-text">New Arrival</div>
          {categories.length > 0 && (
            <div className="landing-trending-header-dropdown">
              <DropdownMenu
                id="new-select"
                title="Choose the filter category for newly added products"
                options={categories.map((category) => ({
                  label: category.name,
                  value: category.slug,
                }))}
                onSelect={setNewlyAddedCategory}
              />
            </div>
          )}
        </div>
        {newlyAddedApiDetails.loading || categories.length === 0 ? (
          <div className="products-carousel">
            <ul className="products">
              {Array.from({ length: 10 }).map((_, index) => (
                <li key={`sk-ld-new-${index}`}>
                  <SkeletonLoading />
                </li>
              ))}
            </ul>
          </div>
        ) : newlyAddedApiDetails.status && newlyAddedProducts ? (
          newlyAddedProducts.length === 0 ? (
            <ErrorComponentTiny
              title="Check back later for newly added products."
              description="Welcome to Boujeeher! While the current category might not have any new products, fear not! There’s a whole world of exciting discoveries waiting for you. Explore other categories, from fashion to gadgets, home decor to wellness. Who knows? The next big trend might be just a click away. And don’t forget to check back later—we’re always updating our offerings. Happy exploring! 🌟"
            />
          ) : (
            <ProductsCarousel data={newlyAddedProducts} type="newly-added" />
          )
        ) : (
          <ErrorComponentTiny
            title="An Error Occurred"
            description="Try again later"
          />
        )}
      </div>
    </div>
  );
}
