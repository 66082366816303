import { MouseEvent, TouchEvent, useRef, useState } from "react";
import "./styles.css";
import IProduct from "../../../../domain/entities/product/IProduct";

interface IProductImageProps {
  product: IProduct;
}

const ZOOM = 3;

export default function ProductImage({ product }: IProductImageProps) {
  const [currentImage, setCurrentImage] = useState<string>(
    product.images[0] || ""
  );
  const selectedImageDivRef = useRef<HTMLDivElement>(null);
  const selectedImageRef = useRef<HTMLImageElement>(null);

  function handleTouchMove(event: TouchEvent<HTMLDivElement>) {
    event.preventDefault();
    const selectedImageRefCurrent = selectedImageRef.current;
    const selectedImageDivRefCurrent = selectedImageDivRef.current;
    if (!selectedImageRefCurrent || !selectedImageDivRefCurrent) return;

    const offsetLeft = selectedImageDivRefCurrent.offsetLeft;
    const offsetTop = selectedImageDivRefCurrent.offsetTop;

    selectedImageRefCurrent.style.top =
      -(event.touches[0].pageY * (ZOOM - 1) - offsetTop) + "px";
    selectedImageRefCurrent.style.left =
      -(event.touches[0].pageX * (ZOOM - 1) - offsetLeft) + "px";
    selectedImageRefCurrent.style.height =
      selectedImageDivRefCurrent.clientHeight * ZOOM + "px";
    selectedImageRefCurrent.style.width = "auto";
  }

  function handleMouseMove(event: MouseEvent<HTMLDivElement>) {
    event.preventDefault();
    const selectedImageRefCurrent = selectedImageRef.current;
    const selectedImageDivRefCurrent = selectedImageDivRef.current;
    if (!selectedImageRefCurrent || !selectedImageDivRefCurrent) return;

    const offsetLeft = selectedImageDivRefCurrent.offsetLeft;
    const offsetTop = selectedImageDivRefCurrent.offsetTop;

    selectedImageRefCurrent.style.top =
      -(event.pageY * (ZOOM - 1) - offsetTop) + "px";
    selectedImageRefCurrent.style.left =
      -(event.pageX * (ZOOM - 1) - offsetLeft) + "px";
    selectedImageRefCurrent.style.height =
      selectedImageDivRefCurrent.clientHeight * ZOOM + "px";
    selectedImageRefCurrent.style.width = "auto";
  }

  function resetMouseMove(
    event: MouseEvent<HTMLDivElement> | TouchEvent<HTMLDivElement>
  ) {
    const selectedImageRefCurrent = selectedImageRef.current;
    const selectedImageDivRefCurrent = selectedImageDivRef.current;
    if (!selectedImageRefCurrent || !selectedImageDivRefCurrent) return;

    selectedImageRefCurrent.style.top = "0px";
    selectedImageRefCurrent.style.left = "0px";
    selectedImageRefCurrent.style.height = "100%";
    selectedImageRefCurrent.style.width = "100%";
  }

  return (
    <div className="product-image">
      <div className="product-image-selector">
        {product.images.map((image, index) =>
          index < 7 ? (
            <div
              key={`product-image-selector-${image}-${index}`}
              className={`product-image-selector-item${
                currentImage === image ? " active" : ""
              }`}
              onClick={() => {
                setCurrentImage(image);
              }}
            >
              <img
                src={encodeURI(image)}
                alt="Product"
                onError={({ currentTarget }) => {
                  currentTarget.onerror = null;
                  currentTarget.src = "/assets/placeholders/product.svg";
                }}
              />
            </div>
          ) : null
        )}
      </div>
      <div
        className="product-image-current"
        ref={selectedImageDivRef}
        onMouseMove={handleMouseMove}
        onTouchMove={handleTouchMove}
        onMouseLeave={resetMouseMove}
        onTouchEnd={resetMouseMove}
      >
        <span className="product-image-current-tooltip">
          Roll over image to zoom in
        </span>
        <img
          src={encodeURI(currentImage)}
          alt="Product"
          onError={({ currentTarget }) => {
            currentTarget.onerror = null;
            currentTarget.src = "/assets/placeholders/product.svg";
          }}
          ref={selectedImageRef}
        />
      </div>
    </div>
  );
}
