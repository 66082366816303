import "./styles.css";

export default function FooterSocials() {
  return (
    <div className="footer-social">
      <a
        href="https://www.facebook.com/"
        target="_blank"
        rel="noreferrer"
        className="social-button"
        title="Our Facebook Page"
      >
        <img src="/assets/social-icons/facebook.png" alt="Facebook" />
      </a>
      <a
        href="https://www.instagram.com/"
        target="_blank"
        rel="noreferrer"
        className="social-button"
        title="Our Instagram Page"
      >
        <img src="/assets/social-icons/instagram.png" alt="Instagram" />
      </a>
      <a
        href="https://www.tiktok.com/"
        target="_blank"
        rel="noreferrer"
        className="social-button"
        title="Our TikTok Profile"
      >
        <img src="/assets/social-icons/tiktok.png" alt="TikTok" />
      </a>
      <a
        href="https://www.x.com/"
        target="_blank"
        rel="noreferrer"
        className="social-button"
        title="Our X (Previously Twitter) Page"
      >
        <img
          src="/assets/social-icons/twitter.png"
          alt="X (Previously Twitter)"
        />
      </a>
    </div>
  );
}
