import { useEffect, useState } from "react";
import MagnifyingGlassIcon from "../../../assets/icons/magnifying-glass";
import "./styles.css";
import DropdownMenu from "../../utilities/dropdown";
import { NavLink } from "react-router-dom";
import OutLightIcon from "../../../assets/icons/out-light";
import SkeletonLoading from "../../loading/skeleton-loading";
import { ISearchProductSortBy } from "../../../domain/entities/product/ISearchProductRequest";
import IProductFilter from "../../../domain/entities/product/IProductFilter";
import ErrorComponentTiny from "../../error/tiny";

interface IShaderFinderCardProps {
  step: 1 | 2 | 3;
  showFilters: boolean;
  loading: boolean;
  heading: string;
  textBoxPlaceHolder: string;
  available: boolean;
  disableMessage: string;
  selectedSlug?: string | number;
  data: {
    name: string;
    slug: string | number;
    category_slug?: string;
    url?: string;
    description?: string;
    image?: string;
    color?: string;
  }[];
  onSelect(slug: string | number): void;
  filters?: IProductFilter;
  setSortBy?: (sortBy: ISearchProductSortBy) => void;
}

export default function ShadeFinderCard({
  step,
  heading,
  textBoxPlaceHolder,
  available,
  loading,
  disableMessage,
  showFilters,
  data,
  selectedSlug,
  onSelect,
  filters,
  setSortBy,
}: IShaderFinderCardProps) {
  const [search, setSearch] = useState("");
  const [filteredData, setFilteredData] = useState(data);

  function filterDataByCategory(category_slug: string) {
    if (category_slug === "Show All") {
      setFilteredData(data);

      return;
    }

    setFilteredData(data.filter((pr) => pr.category_slug === category_slug));
  }

  useEffect(() => {
    if (search === "") setFilteredData(data);
    else
      setFilteredData(
        data.filter(
          (pr) =>
            pr.name.toLowerCase().includes(search.toLowerCase()) ||
            pr.description?.toLowerCase().includes(search.toLowerCase()) ||
            pr.category_slug?.toLowerCase().includes(search.toLowerCase()) ||
            pr.slug.toString().toLowerCase().includes(search.toLowerCase()) ||
            pr.url?.toLowerCase().includes(search.toLowerCase()) ||
            pr.color?.toLowerCase().includes(search.toLowerCase())
        )
      );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [search]);

  useEffect(() => {
    setFilteredData(data);
    setSearch("");
  }, [data]);

  return (
    <div className="shade-finder-card">
      <div className="shade-finder-card-step">Step {step}</div>
      <div className="shade-finder-card-heading">{heading}</div>
      {available ? (
        <div className="shade-finder-dropdown-search">
          <div className="shade-finder-dropdown-search-input">
            <input
              type="text"
              placeholder={loading ? "" : textBoxPlaceHolder}
              value={search}
              onChange={(e) => setSearch(e.target.value)}
              disabled={loading}
            />
            <MagnifyingGlassIcon />
          </div>
          <div className="shade-finder-dropdown-results">
            {!loading && (
              <div className="shade-finder-dropdown-filter">
                <div className="shade-finder-dropdown-filter-length">
                  {filteredData.length} results
                </div>
                {showFilters && (
                  <div className="shade-finder-dropdown-filter-content">
                    Sort By{" "}
                    <DropdownMenu
                      id={`shade-finder-dropdown-filter-sort-${step}`}
                      title={heading}
                      options={[
                        { label: "Name", value: ISearchProductSortBy.NAME },
                        { label: "Rating", value: ISearchProductSortBy.RATING },
                        {
                          label: "Reviews",
                          value: ISearchProductSortBy.REVIEWS,
                        },
                        {
                          label: "Price ↑",
                          value: ISearchProductSortBy.PRICE_ASC,
                        },
                        {
                          label: "Price ↓",
                          value: ISearchProductSortBy.PRICE_DESC,
                        },
                      ]}
                      onSelect={(value) => {
                        if (setSortBy) setSortBy(value as ISearchProductSortBy);
                      }}
                    />
                    {filters && filters.categories.length > 1 && (
                      <>
                        {" "}
                        | Filter By Category{" "}
                        <DropdownMenu
                          id={`shade-finder-dropdown-filter-category-${step}`}
                          title={heading}
                          options={[
                            { label: "Show All", value: "Show All" },
                            ...filters.categories.map((c) => ({
                              label: c.name,
                              value: c.slug,
                            })),
                          ]}
                          onSelect={(value) => {
                            filterDataByCategory(value);
                          }}
                        />
                      </>
                    )}
                  </div>
                )}
              </div>
            )}
            <div className="shade-finder-dropdown-results-data">
              {filteredData.map((item, index) => (
                <div
                  className={`shade-finder-dropdown-results-data-item${
                    selectedSlug === item.slug ? " active" : ""
                  }`}
                  key={`shade-finder-data-item-${index}-${step}`}
                >
                  {(item.color || item.image) && (
                    <div
                      className={`shade-finder-dropdown-result-data-item-image${
                        item.color ? " color" : ""
                      }`}
                      onClick={() => {
                        onSelect(item.slug);
                      }}
                    >
                      {item.image ? (
                        <img
                          src={encodeURI(item.image)}
                          alt="Product"
                          onError={({ currentTarget }) => {
                            currentTarget.onerror = null;
                            currentTarget.src =
                              "/assets/placeholders/product.svg";
                          }}
                        />
                      ) : item.color ? (
                        <div
                          className="shade-finder-dropdown-result-data-item-image-color"
                          style={{
                            backgroundColor: item.color,
                          }}
                        ></div>
                      ) : null}
                    </div>
                  )}
                  <div
                    className="shade-finder-dropdown-result-data-item-details"
                    onClick={() => {
                      onSelect(item.slug);
                    }}
                  >
                    <div className="shade-finder-dropdown-result-data-item-name">
                      {item.name}
                    </div>
                    <div className="shade-finder-dropdown-result-data-item-description">
                      {item.description}
                    </div>
                  </div>
                  {item.url && (
                    <div
                      className="popout-button"
                      title={`Visit ${item.name} page`}
                    >
                      <NavLink
                        to={item.url}
                        target="_blank"
                        state={{
                          from: window.location.pathname,
                        }}
                      >
                        <OutLightIcon />
                      </NavLink>
                    </div>
                  )}
                </div>
              ))}
              {loading ? (
                Array.from({ length: 6 }, (_, index) => (
                  <div
                    className="shade-finder-dropdown-results-data-item"
                    key={`shade-finder-skeleton-loading-${index}-${step}`}
                  >
                    <SkeletonLoading
                      key={`shade-finder-skeleton-loading-${index}-${step}`}
                    />
                  </div>
                ))
              ) : data.length > 0 ? (
                <div className="end-message">
                  Unable to find something? We are constantly working to add
                  more options to our shade matcher
                </div>
              ) : (
                <ErrorComponentTiny
                  title="No Results Found"
                  description="You can explore other options while we work hard for bring more choices in the shade matcher!"
                />
              )}
            </div>
          </div>
        </div>
      ) : (
        <div className="shade-finder-dropdown-search">
          <div className="shade-finder-dropdown-search-input">
            <input
              type="text"
              placeholder={disableMessage}
              value={search}
              onChange={(e) => setSearch(e.target.value)}
              disabled
            />
          </div>
          <div className="shade-finder-dropdown-results"></div>
        </div>
      )}
    </div>
  );
}
