import Breadcrumbs from "../../../components/breadcrumbs";
import SEO from "../../../components/seo";
import "./styles.css";
import "../styles.css";
import { NavLink } from "react-router-dom";

export default function TermsAndConditionsPage() {
  return (
    <div className="terms-conditions-page">
      <SEO
        title="Terms and Conditions"
        description="Read our terms and conditions to know how we handle your data and how you can use our services."
      />
      <Breadcrumbs
        data={[
          {
            label: "Terms and Conditions",
            to: window.location.pathname,
          },
        ]}
      />

      {/* <div className="information-image">
        <img src="/assets/images/terms.png" alt="Terms and Conditions" />
      </div> */}

      <div className="informations-brief">
        <div className="informations-brief-header">
          <div className="informations-brief-title">
            Boujeeher Terms of Service
          </div>
          <div className="informations-brief-date">
            Last updated: August 2024
          </div>
        </div>
        <div className="informations-brief-content"></div>
      </div>
      <article className="information-article">
        <h1>Introduction</h1>
        <p>
          Welcome to <span className="information-bold">Boujeeher</span>. By
          accessing or using our website, you agree to comply with and be bound
          by the following terms and conditions. Please read them carefully.
        </p>
        <h1>Use of the Website</h1>
        <ul>
          <li>
            <span className="information-bold">Eligibility</span>: You must be
            at least 18 years old to use our website.
          </li>
          <li>
            <span className="information-bold">Account</span>: When you create
            an account, you must provide accurate and complete information. You
            are responsible for maintaining the confidentiality of your account
            and password.
          </li>
          <li>
            <span className="information-bold">Prohibited Activities</span>: You
            agree not to use the website for any unlawful purpose or any purpose
            prohibited by these terms. This includes, but is not limited to,
            posting harmful content, infringing on intellectual property rights,
            and engaging in fraudulent activities.
          </li>
        </ul>
        <h1>Intellectual Property</h1>
        <ul>
          <li>
            <span className="information-bold">Content Ownership</span>: All
            content on this website, including text, graphics, logos, and
            images, is the property of Boujeeher or its content suppliers and is
            protected by copyright laws.
          </li>
          <li>
            <span className="information-bold">License</span>: We grant you a
            limited, non-exclusive, non-transferable license to access and use
            the website for personal, non-commercial purposes.
          </li>
        </ul>
        <h1>User-Generated Content</h1>
        <ul>
          <li>
            <span className="information-bold">Responsibility</span>: You are
            solely responsible for any content you post on the website. By
            posting content, you grant us a non-exclusive, royalty-free,
            perpetual, and worldwide license to use, reproduce, and distribute
            your content.
          </li>
          <li>
            <span className="information-bold">Moderation</span>: We reserve the
            right to remove or modify any user-generated content that violates
            these terms or is deemed inappropriate.
          </li>
        </ul>
        <h1>Disclaimers and Limitation of Liability</h1>
        <ul>
          <li>
            <span className="information-bold">No Warranties</span>: The website
            is provided “as is” and “as available” without any warranties of any
            kind, either express or implied.
          </li>
          <li>
            <span className="information-bold">Limitation of Liability</span>:
            In no event shall BeautyCompare be liable for any indirect,
            incidental, special, or consequential damages arising out of or in
            connection with your use of the website.
          </li>
        </ul>
        <h1>Indemnification</h1>
        <p>
          You agree to indemnify and hold Boujeeher harmless from any claims,
          losses, liabilities, damages, and expenses arising out of your use of
          the website or violation of these terms.
        </p>
        <h1>Changes to Terms</h1>
        <p>
          We reserve the right to modify these terms at any time. Any changes
          will be posted on this page, and your continued use of the website
          constitutes acceptance of the revised terms.
        </p>
        <h1>Governing Law</h1>
        <p>
          These terms shall be governed by and construed in accordance with the
          laws of United Kingdom, without regard to its conflict of law
          principles.
        </p>
        <h1>Contact Us</h1>
        <p>
          If you have any questions about these Terms and Conditions, please{" "}
          <NavLink
            to="/informations/contact-us"
            state={{
              from: window.location.pathname,
            }}
          >
            <span className="information-underline">contact us.</span>
          </NavLink>
        </p>
      </article>
    </div>
  );
}
