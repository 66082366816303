import { Navigate, useLocation, useParams } from "react-router-dom";
import "./styles.css";
import SkeletonLoading from "../../../components/loading/skeleton-loading";
import ErrorComponent from "../../../components/error";
import Breadcrumbs from "../../../components/breadcrumbs";
import SEO from "../../../components/seo";
import IProductMini from "../../../domain/entities/product/IProductMini";
import IUserProfileReview from "../../../domain/entities/user/IUserProfileReview";
import IApiStatus from "../../../domain/entities/api/IApiStatus";
import { useEffect, useState } from "react";
import IUser from "../../../domain/entities/user/IUser";
import shortenQuantityToString from "../../../utilities/text/shorten-quantity-to-string";
import ErrorComponentTiny from "../../../components/error/tiny";
import AccountWishlist from "../../../components/account/wishlist";
import AccountReviews from "../../../components/account/reviews";
import UserService from "../../../domain/services/user-service";
import Error404Page from "../../error/error404";
import AvatarIcon from "../../../assets/icons/avatar";
import { useUserContext } from "../../../contexts/user";

enum UserProfilePageState {
  REVIEWS = "reviews",
  WISHLIST = "wishlist",
}

const userService = new UserService();

export default function UserProfile() {
  const { userId } = useParams();
  const [user, setUser] = useState<IUser | undefined>(undefined);
  const [apiStatus, setApiStatus] = useState<IApiStatus>({
    loading: true,
    status: false,
    message: "",
  });
  const [wishlist, setWishlist] = useState<IProductMini[]>([]);
  const [reviews, setReviews] = useState<IUserProfileReview[]>([]);
  const [state, setState] = useState<UserProfilePageState>(
    UserProfilePageState.REVIEWS
  );
  const [loading, setLoading] = useState(false);
  const [notFound, setNotFound] = useState(false);
  const loggedInUser = useUserContext()?.user;
  const location = useLocation();

  async function fetchUser() {
    try {
      setApiStatus({ loading: true, message: "", status: false });
      if (!userId) {
        setNotFound(true);
        return;
      }

      const userIdNum = Number(userId);

      if (Number.isNaN(userIdNum)) {
        setNotFound(true);
        return;
      }

      const response = await userService.getUser(userIdNum);

      if (response.status && response.data) {
        setUser(response.data.user_details);
        setReviews(response.data.reviews);
        setWishlist(response.data.wishlist);
      }

      if (response.data === false) setNotFound(true);

      setApiStatus({
        loading: false,
        status: response.status || false,
        message:
          response.message ||
          "We were unable to load the user profile at the moment",
      });
    } catch (error) {
      setApiStatus({
        loading: false,
        status: false,
        message: "We were unable to load the user profile at the moment",
      });
    }
  }

  useEffect(() => {
    fetchUser();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [userId]);

  function stateComponentSelector() {
    switch (state) {
      case UserProfilePageState.REVIEWS:
        return (
          <AccountReviews
            reviews={reviews}
            apiStatus={apiStatus}
            currentUser={false}
          />
        );

      case UserProfilePageState.WISHLIST:
        return (
          <AccountWishlist
            currentUser={false}
            wishlist={wishlist}
            apiStatus={apiStatus}
            reload={() => fetchUser()}
          />
        );

      default:
        return (
          <ErrorComponentTiny
            title="This tab is not available"
            description="Uh oh! The tab you're trying to access is not available at the moment"
          />
        );
    }
  }

  async function followUser() {
    if (!user) return;
    const response = await userService.followUser(user.id);

    if (response.status) {
      fetchUser();
    }
  }

  async function unfollowUser() {
    if (!user) return;
    const response = await userService.unfollowUser(user.id);

    if (response.status) {
      fetchUser();
    }
  }

  async function followHandler() {
    try {
      if (!user) return;
      setLoading(true);

      if (user.is_following) await unfollowUser();
      else await followUser();

      setLoading(false);
    } catch (error) {
      setLoading(false);
    }
  }

  if (notFound) return <Error404Page />;

  if (loggedInUser && user && loggedInUser.id === user.id)
    return (
      <Navigate
        to="/account"
        state={{
          from: location.state?.from || "/",
        }}
      />
    );

  return (
    <div className="account-page">
      <SEO
        title={
          user
            ? user.full_name
              ? `${user.full_name} | Profile`
              : "User Profile"
            : "User Profile"
        }
        description={user?.short_description || ""}
      />
      <Breadcrumbs
        data={[
          {
            label: user
              ? user.full_name
                ? `${user.full_name} - Profile`
                : "User Profile"
              : "User Profile",
            to: window.location.pathname,
          },
        ]}
      />
      <div className="account-page-heading">
        {user && `Profile of ${user.full_name}`}
      </div>
      {!apiStatus.status && !apiStatus.loading ? (
        <ErrorComponent
          title="Uh Oh! An Error Occurred"
          description="We were unable to load your profile at the moment!"
          errorCode={apiStatus.message}
        />
      ) : (
        <div className="account-page-content">
          <div className="account-page-profile">
            {apiStatus.loading ? (
              <div className="account-page-user">
                <div className="user-profile-picture">
                  <SkeletonLoading />
                </div>
                <div className="account-page-user-name">
                  <SkeletonLoading />
                </div>
                <div className="account-page-user-social">
                  <SkeletonLoading />
                  <SkeletonLoading />
                  <SkeletonLoading />
                  <SkeletonLoading />
                </div>
                <div className="account-page-user-bio">
                  <SkeletonLoading />
                </div>
                <div className="account-page-user-followers">
                  <SkeletonLoading />
                </div>
              </div>
            ) : (
              user && (
                <div className="account-page-user">
                  <div className="user-profile-picture">
                    {user.image_url ? (
                      <img src={user.image_url} alt="Your Profile" />
                    ) : (
                      <AvatarIcon />
                    )}
                  </div>
                  <div className="account-page-user-name">{user.full_name}</div>
                  <div className="account-page-user-social">
                    {user.socials.facebook && (
                      <a
                        href={user.socials.facebook}
                        target="_blank"
                        rel="noreferrer"
                        className="social-button"
                        title={`Facebook profile of ${user.full_name}`}
                      >
                        <img
                          src="/assets/social-icons/facebook.png"
                          alt="Facebook"
                        />
                      </a>
                    )}
                    {user.socials.instagram && (
                      <a
                        href={user.socials.instagram}
                        target="_blank"
                        rel="noreferrer"
                        className="social-button"
                        title={`Instagram profile of ${user.full_name}`}
                      >
                        <img
                          src="/assets/social-icons/instagram.png"
                          alt="Instagram"
                        />
                      </a>
                    )}
                    {user.socials.tiktok && (
                      <a
                        href={user.socials.tiktok}
                        target="_blank"
                        rel="noreferrer"
                        className="social-button"
                        title={`Tiktok profile for ${user.full_name}`}
                      >
                        <img
                          src="/assets/social-icons/tiktok.png"
                          alt="TikTok"
                        />
                      </a>
                    )}
                    {user.socials.twitter && (
                      <a
                        href={user.socials.twitter}
                        target="_blank"
                        rel="noreferrer"
                        className="social-button"
                        title={`Twitter (X) profile for ${user.full_name}`}
                      >
                        <img
                          src="/assets/social-icons/twitter.png"
                          alt="X (Previously Twitter)"
                        />
                      </a>
                    )}
                  </div>
                  <div className="account-page-user-bio">
                    {user.short_description}
                  </div>
                  <div className="account-page-user-followers">
                    {shortenQuantityToString(user.num_followers)} followers
                  </div>
                  {user.is_followed && (
                    <div className="account-page-user-followers">
                      <i>follows you</i>
                    </div>
                  )}
                  <button
                    className="account-page-user-button"
                    type="button"
                    title={`Follow ${user.full_name}`}
                    onClick={followHandler}
                    disabled={loading}
                  >
                    {loading
                      ? "Loading"
                      : user.is_following
                      ? "Unfollow"
                      : "Follow"}
                  </button>
                </div>
              )
            )}
          </div>
          <div className="account-page-details">
            <div className="account-page-action">
              <div
                className={`account-page-action-item${
                  state === UserProfilePageState.REVIEWS ? " active" : ""
                }`}
                onClick={() => {
                  setState(UserProfilePageState.REVIEWS);
                }}
              >
                Reviews{" "}
                {!apiStatus.status &&
                  !apiStatus.loading &&
                  `(${reviews.length})`}
              </div>
              <div
                className={`account-page-action-item${
                  state === UserProfilePageState.WISHLIST ? " active" : ""
                }`}
                onClick={() => {
                  setState(UserProfilePageState.WISHLIST);
                }}
              >
                Wishlist{" "}
                {!apiStatus.status &&
                  !apiStatus.loading &&
                  `(${wishlist.length})`}
              </div>
            </div>
            <div className="account-page-details-content">
              {stateComponentSelector()}
            </div>
          </div>
        </div>
      )}
    </div>
  );
}
