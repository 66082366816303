/**
 * Get a YouTube videoId from URL
 *
 * @param url A YouTube video URL
 * @returns videoId
 */
export default function parseYoutubeVideoIdFromUrl(url: string): string {
  const youtubeShortsRegEx =
    /(?:https?:\/\/)?(?:www\.)?(?:youtube\.com)\/shorts\/([^&]+)(?:\S+)?/;
  const youtubeShortsMatch = url.match(youtubeShortsRegEx);
  if (youtubeShortsMatch && youtubeShortsMatch.length > 1) {
    return youtubeShortsMatch[1];
  }

  const youtubeRegEx =
    /(?:https?:\/\/)?(?:www\.)?(?:youtube\.com|youtu\.be)\/(?:watch\?v=)?([^&]+)(?:\S+)?/;
  const youtubeMatch = url.match(youtubeRegEx);
  if (youtubeMatch && youtubeMatch.length > 1) {
    return youtubeMatch[1];
  }
  return url;
}
