import { NavLink } from "react-router-dom";
import Breadcrumbs from "../../../components/breadcrumbs";
import SEO from "../../../components/seo";
import "./styles.css";

export default function SiteMapPage() {
  return (
    <div className="sitemap-page">
      <SEO
        title="Sitemap"
        description="Find all the pages of Boujeeher in one place. Navigate through our website easily with our sitemap."
      />
      <Breadcrumbs
        data={[
          {
            label: "Sitemap",
            to: window.location.pathname,
          },
        ]}
      />

      <div className="sitemap-content">
        <div className="sitemap-item">
          <h2>Home</h2>
          <p>
            Check out categories and products available on Boujeeher. Also you
            could check out trending, new arrivals and featured products.
          </p>
          <ul>
            <li>
              <NavLink to="/" state={{ from: window.location.pathname }}>
                Home
              </NavLink>
            </li>
          </ul>
        </div>
        <div className="sitemap-item">
          <h2>Brands</h2>
          <p>
            Check out about the brands available on Boujeeher and browse their
            products
          </p>
          <ul>
            <li>
              <NavLink to="/brands" state={{ from: window.location.pathname }}>
                Brands
              </NavLink>
            </li>
            <li>Individual brand page can be accessed from the above url</li>
          </ul>
        </div>
        <div className="sitemap-item">
          <h2>Match Your Shade</h2>
          <p>
            Find your perfect shade of foundation, concealer, lipstick and more
          </p>
          <ul>
            <li>
              <NavLink
                to="/match-your-shade"
                state={{ from: window.location.pathname }}
              >
                Match Your Shade
              </NavLink>
            </li>
          </ul>
        </div>
        <div className="sitemap-item">
          <h2>Blog</h2>
          <p>
            Check out the latest blogs and articles on beauty, skincare and
            makeup
          </p>
          <ul>
            <li>
              <NavLink to="/blog" state={{ from: window.location.pathname }}>
                Blog
              </NavLink>
            </li>
            <li>Individual blog page can be accessed from the above url</li>
          </ul>
        </div>
        <div className="sitemap-item">
          <h2>Account</h2>
          <p>
            Connect your social media account, view or edit your profile,
            wishlist, and reviews
          </p>
          <ul>
            <li>
              <NavLink
                to="/account/login"
                state={{ from: window.location.pathname }}
              >
                Login
              </NavLink>
            </li>
            <li>
              <NavLink to="/account" state={{ from: window.location.pathname }}>
                Your Account
              </NavLink>
            </li>
            <li>
              <NavLink
                to="/account?showWishlist=true"
                state={{ from: window.location.pathname }}
              >
                Wishlist
              </NavLink>
            </li>
          </ul>
        </div>
        <div className="sitemap-item">
          <h2>Informations</h2>
          <p>
            Check out the information pages like about us, contact us, privacy
            policy, terms and conditions and more
          </p>
          <ul>
            <li>
              <NavLink
                to="/informations/about-us"
                state={{ from: window.location.pathname }}
              >
                About Us
              </NavLink>
            </li>
            <li>
              <NavLink
                to="/informations/contact-us"
                state={{ from: window.location.pathname }}
              >
                Contact Us
              </NavLink>
            </li>
            <li>
              <NavLink
                to="/informations/privacy-policy"
                state={{ from: window.location.pathname }}
              >
                Privacy Policy
              </NavLink>
            </li>
            <li>
              <NavLink
                to="/informations/terms-and-conditions"
                state={{ from: window.location.pathname }}
              >
                Terms and Conditions
              </NavLink>
            </li>
          </ul>
        </div>
      </div>
    </div>
  );
}
