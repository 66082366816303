import IGetAllCategoriesRequest from "../entities/category/IGetAllCategoriesRequest";
import ICategoryService from "../entities/services/ICategoryService";
import CategoryRepository from "../repositories/category-repository";

export default class CategoryService implements ICategoryService {
  categoryRepository: CategoryRepository;

  constructor() {
    this.categoryRepository = new CategoryRepository();
  }

  async getAllCategories(filter: IGetAllCategoriesRequest) {
    return this.categoryRepository.getAllCategories(filter);
  }

  async getCategory(slug: string) {
    return this.categoryRepository.getCategory(slug);
  }
}
