import LandingService from "../../../domain/services/landing-service";
import "./styles.css";

interface ILandingAdditionalWidgetsProps {
  landingService: LandingService;
}

export default function LandingAdditionalWidgets({
  landingService,
}: ILandingAdditionalWidgetsProps) {
  return <div></div>;
}
