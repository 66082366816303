import api from "../api";
import IApiResponse from "../entities/api/IApiResponse";
import ICategory from "../entities/category/ICategory";
import ILandingAdditionalWidget from "../entities/landing/ILandingAdditionalWidget";
import ILandingCommunity from "../entities/landing/ILandingCommunity";
import ILandingDiscover from "../entities/landing/ILandingDiscover";
import ILandingEssential from "../entities/landing/ILandingEssential";
import ILandingFeatured from "../entities/landing/ILandingFeatured";
import ILandingTrendingProduct from "../entities/landing/ILandingTrendingProduct";
import IProductMini from "../entities/product/IProductMini";
import ILandingRepository from "../entities/repositories/ILandingRepository";
import CategoryService from "../services/category-service";

export default class LandingRepository implements ILandingRepository {
  private categoryService: CategoryService;

  constructor() {
    this.categoryService = new CategoryService();
  }

  async getEssentialItems(): Promise<IApiResponse<ILandingEssential>> {
    try {
      const data: ILandingEssential = {
        leftItem: {
          title: "Must Have Moisturizing Lip Care",
          description: "Seasonal Essentials",
          image: "/essential2.jpg",
          linkTo: "essential",
          linkText: "Shop Now",
        },
        rightItem: {
          title: "New Makeup Removers",
          description: "Start Fresh",
          image: "/essential1.jpg",
          linkTo: "essential",
          linkText: "Shop Now",
        },
      };

      return {
        status: true,
        message: "Essential items fetched successfully",
        data,
        requestDuration: 222,
      };
    } catch (error) {
      return {
        status: false,
        message: "An error occurred while fetching essential items",
        requestDuration: 0,
      };
    }
  }

  async getTrendingProducts(
    category_slug: string,
    maximum_items?: number
  ): Promise<IApiResponse<ILandingTrendingProduct[]>> {
    try {
      const response = await api.get<{ products: IProductMini[] }>(
        "/api/v1/product/trending",
        {
          params: { category_slug, top_n: maximum_items },
        }
      );

      if (!response || !response.data)
        return {
          status: false,
          message: "Failed to fetch the trending products",
          requestDuration: 0,
        };

      return {
        status: true,
        message: "Trending products fetched successfully",
        data:
          response.data.products?.map((product) => ({
            ...product,
            images:
              product.images?.sort((a, b) => (a.includes("main") ? -1 : 1)) ||
              [],
          })) || [],
        requestDuration: 0,
      };
    } catch (error) {
      return {
        status: false,
        message: "An error occurred while fetching trending products",
        requestDuration: 0,
      };
    }
  }

  async getNewProducts(
    category_slug: string,
    maximum_items?: number
  ): Promise<IApiResponse<ILandingTrendingProduct[]>> {
    try {
      const response = await api.get<{ products: IProductMini[] }>(
        "/api/v1/product/new",
        {
          params: { category_slug, top_n: maximum_items },
        }
      );

      if (!response || !response.data)
        return {
          status: false,
          message: "Failed to fetch newly added products",
          requestDuration: 0,
        };

      return {
        status: true,
        message: "Newly added products fetched successfully",
        data:
          response.data.products?.map((product) => ({
            ...product,
            images:
              product.images?.sort((a, b) => (a.includes("main") ? -1 : 1)) ||
              [],
          })) || [],
        requestDuration: 0,
      };
    } catch (error) {
      return {
        status: false,
        message: "An error occurred while fetching newly added products",
        requestDuration: 0,
      };
    }
  }

  async getFeaturedProducts(): Promise<
    IApiResponse<ILandingTrendingProduct[]>
  > {
    try {
      const response = await api.get<{ products: IProductMini[] }>(
        "/api/v1/product/featured"
      );

      if (!response || !response.data)
        return {
          status: false,
          message: "Failed to fetch the featured products",
          requestDuration: 0,
        };

      return {
        status: true,
        message: "Featured products fetched successfully",
        data:
          response.data.products?.map((product) => ({
            ...product,
            images:
              product.images?.sort((a, b) => (a.includes("main") ? -1 : 1)) ||
              [],
          })) || [],
        requestDuration: 0,
      };
    } catch (error) {
      return {
        status: false,
        message: "An error occurred while fetching featured products",
        requestDuration: 0,
      };
    }
  }

  async getFeaturedItems(): Promise<IApiResponse<ILandingFeatured>> {
    try {
      const data: ILandingFeatured = {
        leftItem: {
          title: "New Service - Collect in Store",
          description: "Pick your makeup",
          image: "/featured1.jpg",
          linkTo: "essential",
          linkText: "Shop Now",
        },
        rightItem: {
          title: "Nude Liquid Matte Foundation",
          description: "All About the Base",
          image: "/featured2.jpg",
          linkTo: "essential",
          linkText: "Shop Now",
        },
      };

      return {
        status: true,
        message: "Featured items fetched successfully",
        data,
        requestDuration: 335,
      };
    } catch (error) {
      return {
        status: false,
        message: "An error occurred while fetching featured items",
        requestDuration: 0,
      };
    }
  }
  async getAdditionalWidgets(): Promise<
    IApiResponse<ILandingAdditionalWidget[]>
  > {
    try {
      throw new Error("Method not implemented.");
    } catch (error) {
      return {
        status: false,
        message: "An error occurred while fetching additional widgets",
        requestDuration: 0,
      };
    }
  }

  async getCommunities(): Promise<IApiResponse<ILandingCommunity[]>> {
    try {
      const data: ILandingCommunity[] = [];

      return {
        status: true,
        message: "Community items fetched successfully",
        data,
        requestDuration: 55,
      };
    } catch (error) {
      return {
        status: false,
        message: "An error occurred while fetching community items",
        requestDuration: 0,
      };
    }
  }

  async getDiscoverItem(): Promise<
    IApiResponse<{ discover: ILandingDiscover[]; categories: ICategory[] }>
  > {
    try {
      const response = await this.categoryService.getAllCategories({
        only_with_product_variants: false,
      });

      return {
        ...response,
        data: {
          categories: response.data || [],
          discover:
            response.data?.map((category) => ({
              heading: category.name,
              linkTo: `/categories/${category.slug}`,
              imageUrl: category.logo,
            })) || [],
        },
      };
    } catch (error) {
      return {
        status: false,
        message: "An error occurred while fetching discover item",
        requestDuration: 0,
      };
    }
  }
}
