import { useEffect, useState } from "react";
import IProduct from "../../../domain/entities/product/IProduct";
import ProductImage from "./product-image";
import "./styles.css";
import StarRatings from "react-star-ratings";
import shortenQuantityToString from "../../../utilities/text/shorten-quantity-to-string";
import { NavLink, useSearchParams } from "react-router-dom";
import HeartIcon from "../../../assets/icons/heart";
import ProductDetailsSwitch from "./product-details-switch";
import StarIcon from "../../../assets/icons/star";
import IProductReview from "../../../domain/entities/product/IProductReview";
import IProductReviewBrief from "../../../domain/entities/product/IProductReviewBrief";
import IApiStatus from "../../../domain/entities/api/IApiStatus";
import ProductAdditionalInformation from "../product-additional-information";
import ProductPrice from "../product-price";
import LeaveAReviewPopUp from "./leave-a-review";
import { useUserContext } from "../../../contexts/user";
import ProductService from "../../../domain/services/product-service";

interface IProductDetailsProps {
  product: IProduct;
  reviews: {
    apiStatus: IApiStatus;
    reviews: IProductReview[];
    reviewBrief?: IProductReviewBrief;
  };
  reload: () => void;
}

const productService = new ProductService();

export default function ProductDetails({
  product,
  reviews,
  reload,
}: IProductDetailsProps) {
  const [searchParams, setSearchParams] = useSearchParams();
  const [selectedVariant, setSelectedVariant] = useState(
    searchParams.get("variant") || ""
  );
  const [displayAddAReview, setDisplayAddAReview] = useState(false);
  const [loading, setLoading] = useState(false);
  const { isLoggedIn, setWishlistCount } = useUserContext();

  useEffect(() => {
    setSelectedVariant(searchParams.get("variant") || "");
  }, [searchParams]);

  async function addProductToWishlist() {
    setLoading(true);
    const response = await productService.addProductToWishlist(product.slug);
    if (response?.status) {
      setWishlistCount((prev) => prev + 1);
      reload();
    }
    setLoading(false);
  }

  async function removeProductFromWishlist() {
    setLoading(true);
    const response = await productService.removeProductFromWishlist(
      product.slug
    );
    if (response?.status) {
      reload();

      setWishlistCount((prev) => {
        if (prev <= 0) return 0;
        return prev - 1;
      });
    }
    setLoading(false);
  }

  return (
    <div className="product-details">
      <div className="product-attributes">
        <div className="mobile-product-info">
          <div className="product-brand-name">{product.brand.name}</div>
          <div className="product-name">
            {product.name}
            {selectedVariant
              ? ` - ${
                  product.variants.find(
                    (v) => v.id.toString() === selectedVariant
                  )?.name
                }`
              : ""}
          </div>
          <div className="product-brief-review">
            <StarRatings
              rating={product.avg_rating}
              starDimension="15px"
              starRatedColor="var(--theme-color)"
              starSpacing="1px"
            />
            <span className="review-details">
              {Math.round(product.avg_rating * 100) / 100} |{" "}
              {shortenQuantityToString(product.reviews)} ratings{" "}
              <i>(from internet)</i>
            </span>
          </div>
        </div>
        <ProductImage product={product} />
        <div className="product-attributes-info">
          <div className="product-ingredients-brief">
            <div className="product-ingredients-brief-heading">
              Number of Ingredients
            </div>
            <div className="product-ingredients-brief-content">
              <div className="product-ingredients-brief-content-item">
                <img src="/assets/icons/natural.png" alt="Natural" /> Natural:{" "}
                {product.ingredients.natural.length}
              </div>
              <div className="product-ingredients-brief-content-item">
                <img src="/assets/icons/synthetic.png" alt="Synthetic" />{" "}
                Synthetic: {product.ingredients.synthetic.length}
              </div>
            </div>
          </div>
          <ProductAdditionalInformation data={product.certifications} />
        </div>
      </div>
      <div className="product-description">
        <div className="product-brand-name">{product.brand.name}</div>
        <div className="product-name">
          {product.name}
          {selectedVariant
            ? ` - ${
                product.variants.find(
                  (v) => v.id.toString() === selectedVariant
                )?.name
              }`
            : ""}
        </div>
        <div className="product-brief-review">
          <StarRatings
            rating={product.avg_rating}
            starDimension="15px"
            starRatedColor="var(--theme-color)"
            starSpacing="1px"
          />
          <span className="review-details">
            {Math.round(product.avg_rating * 100) / 100} |{" "}
            {shortenQuantityToString(product.reviews)} ratings{" "}
            <i>(from internet)</i>
          </span>
        </div>
        {product.variants.length > 0 && (
          <div className="product-shades">
            <h2>Shades</h2>
            <div className="product-shades-list">
              {product.variants.map((shade, index) => (
                <div
                  key={`prod-shade-${shade.id}-${index}`}
                  className={`product-shade${
                    selectedVariant === shade.id.toString() ? " selected" : ""
                  }`}
                  style={{ backgroundColor: shade.value }}
                  title={shade.name}
                  onClick={() => {
                    setSearchParams({ variant: shade.id.toString() });
                  }}
                ></div>
              ))}
            </div>
          </div>
        )}
        <div className="product-actions">
          {isLoggedIn ? (
            <button
              type="button"
              className="add-to-wishlist"
              onClick={() => {
                if (product.is_added_to_wishlist) {
                  removeProductFromWishlist();
                } else {
                  addProductToWishlist();
                }
              }}
              disabled={loading}
            >
              <HeartIcon />{" "}
              {loading
                ? "Please Wait"
                : product.is_added_to_wishlist
                ? "Remove from Wishlist"
                : "Add to Wishlist"}
            </button>
          ) : (
            <NavLink
              to="/account/login"
              className="add-to-wishlist"
              state={{ from: window.location.pathname }}
            >
              <HeartIcon /> Add to Wishlist
            </NavLink>
          )}
          {isLoggedIn ? (
            <button
              type="button"
              className="leave-a-review"
              onClick={() => setDisplayAddAReview(true)}
            >
              <StarIcon />{" "}
              {product.is_reviewed ? "Edit Your Review" : "Leave a Review"}
            </button>
          ) : (
            <NavLink
              to="/account/login"
              className="leave-a-review"
              state={{ from: window.location.pathname }}
            >
              <StarIcon /> Leave a Review
            </NavLink>
          )}
        </div>
        <LeaveAReviewPopUp
          productSlug={product.slug}
          categorySlug={product.category.slug}
          display={displayAddAReview}
          close={(reloadProduct) => {
            if (reloadProduct) reload();
            setDisplayAddAReview(false);
          }}
          userReview={product.user_review}
        />
        <ProductDetailsSwitch
          description={product.description}
          ingredients={product.ingredients}
          reviews={reviews}
        />
        <ProductPrice data={product.vendors} />
      </div>
    </div>
  );
}
