import IApiStatus from "../../../domain/entities/api/IApiStatus";
import IUserProfileReview from "../../../domain/entities/user/IUserProfileReview";
import ErrorComponentTiny from "../../error/tiny";
import SkeletonLoading from "../../loading/skeleton-loading";
import AccountReviewItem from "./account-review-item";
import "./styles.css";

interface IAccountWishlistProps {
  apiStatus: IApiStatus;
  reviews: IUserProfileReview[];
  currentUser: boolean;
}

export default function AccountReviews({
  apiStatus,
  reviews,
  currentUser,
}: IAccountWishlistProps) {
  return apiStatus.loading ? (
    <div className="account-page-reviews">
      <div className="review-item">
        <div className="review-item-content">
          <SkeletonLoading />
          <div className="review-content">
            <div className="review-body">
              <SkeletonLoading />
            </div>
            <div className="reviewer-name">
              <SkeletonLoading />
              <SkeletonLoading />
            </div>
          </div>
        </div>
        <div className="review-item-action">
          <SkeletonLoading />
          <SkeletonLoading />
        </div>
      </div>
      <div className="review-item">
        <div className="review-item-content">
          <SkeletonLoading />
          <div className="review-content">
            <div className="review-body">
              <SkeletonLoading />
            </div>
            <div className="reviewer-name">
              <SkeletonLoading />
              <SkeletonLoading />
            </div>
          </div>
        </div>
        <div className="review-item-action">
          <SkeletonLoading />
          <SkeletonLoading />
        </div>
      </div>
      <div className="review-item">
        <div className="review-item-content">
          <SkeletonLoading />
          <div className="review-content">
            <div className="review-body">
              <SkeletonLoading />
            </div>
            <div className="reviewer-name">
              <SkeletonLoading />
              <SkeletonLoading />
            </div>
          </div>
        </div>
        <div className="review-item-action">
          <SkeletonLoading />
          <SkeletonLoading />
        </div>
      </div>
    </div>
  ) : reviews.length > 0 ? (
    <div className="account-page-reviews">
      {reviews.map((review, index) => (
        <AccountReviewItem
          data={review}
          currentUser={currentUser}
          key={`user-review-${index}`}
        />
      ))}
    </div>
  ) : currentUser ? (
    <ErrorComponentTiny
      title="You have not reviewed any products yet"
      description="Start reviewing products to help others make better decisions. You can make a difference in the community by sharing your experience. You can keep track of the products you have reviewed here."
    />
  ) : (
    <ErrorComponentTiny
      title="No reviews yet"
      description="This user has not reviewed any products yet. Check back later for updates."
    />
  );
}
