import "./styles.css";

interface IYoutubeEmbedProps {
  videoId: string;
  width?: string;
  height?: string;
}

export default function YoutubeEmbed({
  videoId,
  width,
  height,
}: IYoutubeEmbedProps) {
  return (
    <iframe
      width={width || "315"}
      height={height || "560"}
      src={`https://www.youtube.com/embed/${videoId}`}
      title="YouTube Shorts"
      frameBorder="0"
      allow="accelerometer; autoplay; clipboard-write; encrypted-media;
      gyroscope; picture-in-picture;
      web-share"
      allowFullScreen
    ></iframe>
  );
}
