import "./styles.css";

interface IDropdownMenuProps {
  id: string;
  title: string;
  options: {
    value: string;
    label: string;
  }[];
  onSelect: (value: string) => void;
}

export default function DropdownMenu({
  id,
  title,
  options,
  onSelect,
}: IDropdownMenuProps) {
  return (
    <select
      id={id}
      title={title}
      onChange={(event) => onSelect(event.target.value)}
      className="drop-down-menu"
    >
      {options.map((option, index) => (
        <option key={`${id}-option-${index}`} value={option.value}>
          {option.label}
        </option>
      ))}
    </select>
  );
}
