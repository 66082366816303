import { Navigate, useLocation } from "react-router-dom";
import { useUserContext } from "../contexts/user";
import { PropsWithChildren } from "react";

export default function ProtectedRoute({ children }: PropsWithChildren) {
  const location = useLocation();
  const { isLoggedIn } = useUserContext();

  if (isLoggedIn) return children as JSX.Element;
  return (
    <Navigate
      to="/account/login"
      state={{
        from: location.pathname,
      }}
    />
  );
}
