import { AxiosError } from "axios";
import validationErrorCheck, {
  IValidationError,
  IValidationErrorDetailResponse,
} from "./validation-error-check";

interface IApiChecksErrorResponse {
  isApiError: boolean;
  message: string;
  validationErrors?: IValidationErrorDetailResponse[];
}

interface IErrorResponse {
  detail: IValidationError[] | string;
}

export default function apiErrorChecks(
  error: AxiosError<IErrorResponse>
): IApiChecksErrorResponse {
  if (!error?.response)
    return {
      isApiError: false,
      message: "",
    };

  if (!error.response.data)
    return {
      isApiError: true,
      message: error.message || "",
    };

  if (!error.response.data.detail)
    return {
      isApiError: true,
      message: `Error ${error.response.status}: ${error.response.statusText}`,
    };

  if (error.response.status === 422) {
    const validationError = validationErrorCheck(
      error.response.data.detail as IValidationError[]
    );

    if (validationError.isValidationError)
      return {
        isApiError: true,
        message: validationError.message,
        validationErrors: validationError.validationErrors,
      };
  }

  if (error.response.data.detail)
    return {
      isApiError: true,
      message: error.response.data.detail as string,
    };

  return {
    isApiError: true,
    message: error.message || "",
  };
}
