import StarRatings from "react-star-ratings";
import ThumbsDownIcon from "../../../../assets/icons/thumbs-down";
import ThumbsUpIcon from "../../../../assets/icons/thumbs-up";
import IUserProfileReview from "../../../../domain/entities/user/IUserProfileReview";
import { NavLink } from "react-router-dom";
import PencilIcon from "../../../../assets/icons/pencil";
import "./styles.css";
import { useEffect, useState } from "react";
import ProductService from "../../../../domain/services/product-service";
import { useUserContext } from "../../../../contexts/user";
import LeaveAReviewPopUp from "../../../products/product-details/leave-a-review";

interface IAccountReviewItemProps {
  data: IUserProfileReview;
  currentUser: boolean;
}

const productService = new ProductService();

export default function AccountReviewItem({
  data,
  currentUser,
}: IAccountReviewItemProps) {
  const [liked, setLiked] = useState(data.review.is_liked);
  const [likeCount, setLikeCount] = useState<number>(
    data.review.review_likes || 0
  );
  const [disliked, setDisliked] = useState(data.review.is_disliked);
  const [dislikeCount, setDislikeCount] = useState<number>(
    data.review.review_dislikes || 0
  );
  const { isLoggedIn } = useUserContext();
  const [showEditReview, setShowEditReview] = useState(false);

  async function likeReview() {
    await productService.likeProductReview(data.review.review_id);
    if (disliked) setDislikeCount((prev) => prev - 1);
    setLikeCount((prev) => prev + 1);
    setLiked(true);
    setDisliked(false);
  }

  async function dislikeReview() {
    await productService.dislikeProductReview(data.review.review_id);
    if (liked) setLikeCount((prev) => prev - 1);
    setDislikeCount((prev) => prev + 1);
    setLiked(false);
    setDisliked(true);
  }

  async function undoLikeDislikeReview() {
    await productService.undoLikeDislikeProductReview(data.review.review_id);
    if (liked) setLikeCount((prev) => prev - 1);
    else setDislikeCount((prev) => prev - 1);
    setLiked(false);
    setDisliked(false);
  }

  useEffect(() => {
    setLiked(data.review.is_liked);
    setLikeCount(data.review.review_likes || 0);
    setDisliked(data.review.is_disliked);
    setDislikeCount(data.review.review_dislikes || 0);
  }, [data]);

  return (
    <div className="account-review-item">
      <LeaveAReviewPopUp
        display={showEditReview}
        productSlug={data.product.slug}
        categorySlug={data.product.category_slug}
        userReview={data.review}
        close={(reload) => {
          setShowEditReview(false);
        }}
      />
      <div className="account-review-item-content">
        <div className="account-review-item-rating">
          <NavLink to={`/products/${data.product.slug}`}>
            <div className="account-review-item-image">
              <img
                src={encodeURI(data.product.images[0])}
                alt="Product"
                onError={({ currentTarget }) => {
                  currentTarget.onerror = null;
                  currentTarget.src = "/assets/placeholders/product.svg";
                }}
              />
            </div>
          </NavLink>
        </div>
        <div className="review-content">
          {/* <div className="static-reviews">
            {data.review.static_review.map((review, index) => (
              <div
                key={`st-rev-${data.review.review_id}-${index}`}
                className="static-review"
              >
                {review}
              </div>
            ))}
          </div> */}
          <div className="review-body">
            {data.review.static_review?.join(", ")}
          </div>
          <div className="review-body">
            {data.review.comment}
            {currentUser && (
              <div
                className="account-account-review-item-edit"
                onClick={() => setShowEditReview(true)}
              >
                <PencilIcon />
              </div>
            )}
          </div>
          <div className="reviewer-name">
            <StarRatings
              rating={data.review.rating}
              starDimension="15px"
              starSpacing="1px"
              starRatedColor="var(--theme-color)"
              numberOfStars={5}
            />{" "}
            {Math.round(data.review.rating * 10) / 10} |{" "}
            {new Date(data.review.timestamp).toDateString()}
          </div>
        </div>
      </div>
      {isLoggedIn ? (
        <div className="account-review-item-action">
          <div
            className={`account-review-item-action-button${
              liked ? " active" : ""
            }`}
            onClick={() => {
              if (liked) undoLikeDislikeReview();
              else likeReview();
            }}
            title={liked ? "Undo Like" : "Like this review"}
          >
            <ThumbsUpIcon />
            <span>{likeCount}</span>
          </div>
          <div
            className={`account-review-item-action-button${
              disliked ? " active" : ""
            }`}
            onClick={() => {
              if (disliked) undoLikeDislikeReview();
              else dislikeReview();
            }}
            title={disliked ? "Undo Dislike" : "Dislike this review"}
          >
            <ThumbsDownIcon />
            <span>{dislikeCount}</span>
          </div>
        </div>
      ) : (
        <NavLink
          to="/account/login"
          state={{ from: window.location.pathname }}
          className="account-review-item-action"
        >
          <div
            className={`account-review-item-action-button${
              liked ? " active" : ""
            }`}
          >
            <ThumbsUpIcon />
            <span>{likeCount}</span>
          </div>
          <div
            className={`account-review-item-action-button${
              disliked ? " active" : ""
            }`}
          >
            <ThumbsDownIcon />
            <span>{dislikeCount}</span>
          </div>
        </NavLink>
      )}
    </div>
  );
}
