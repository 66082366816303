import { useEffect, useRef, useState } from "react";
import MagnifyingGlassIcon from "../../../assets/icons/magnifying-glass";
import "./styles.css";
import { NavLink, useLocation } from "react-router-dom";

export default function HeaderSearch() {
  const [query, setQuery] = useState("");
  const searchButtonRef = useRef<HTMLAnchorElement>(null);
  const searchInputRef = useRef<HTMLInputElement>(null);
  const location = useLocation();

  useEffect(() => {
    if (!location.pathname.includes("/search/")) {
      setQuery("");
    }
  }, [location]);

  function searchHandler() {
    if (query) {
      searchButtonRef.current?.focus();
      searchButtonRef.current?.click();
    }
  }

  return (
    <div className="header-search">
      <form
        onSubmit={(e) => {
          e.preventDefault();
          searchHandler();
        }}
      >
        <input
          className={query ? "move" : ""}
          type="text"
          placeholder="Search..."
          value={query}
          onChange={(e) => setQuery(e.target.value)}
          ref={searchInputRef}
        />
        <NavLink
          to={{
            pathname: `/search/${query || " "}`,
          }}
          state={{ from: window.location.pathname }}
          ref={searchButtonRef}
        >
          <MagnifyingGlassIcon />
        </NavLink>
      </form>
    </div>
  );
}
