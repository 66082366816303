import { useEffect, useState } from "react";
import BrandService from "../../../domain/services/brand-service";
import "./styles.css";
import IBrand from "../../../domain/entities/brand/IBrand";
import IApiStatus from "../../../domain/entities/api/IApiStatus";
import IBrandList from "../../../domain/entities/brand/IBrandList";
import SEO from "../../../components/seo";
import MagnifyingGlassIcon from "../../../assets/icons/magnifying-glass";
import SkeletonLoading from "../../../components/loading/skeleton-loading";
import { NavLink } from "react-router-dom";
import ErrorComponent from "../../../components/error";
import Breadcrumbs from "../../../components/breadcrumbs";

const brandService = new BrandService();

export default function BrandsListPage() {
  const [brandList, setBrandList] = useState<IBrand[]>([]);
  const [apiStatus, setApiStatus] = useState<IApiStatus>({
    status: false,
    message: "",
    loading: true,
  });
  const [filteredBrands, setFilteredBrands] = useState<IBrandList>({});
  const [brandSearch, setBrandSearch] = useState<string>("");
  const [selectedFilters, setSelectedFilters] = useState<string[]>([]);

  useEffect(() => {
    async function fetchAllBrands() {
      try {
        const brands = await brandService.getAllBrands({
          only_with_product_variants: false,
        });

        if (!brands.status || !brands.data) {
          setApiStatus({
            status: false,
            message: brands.message,
            loading: false,
          });
          return;
        }

        setBrandList(brands.data);
        const filtered = brandService.filterBrandsByLetter(brands.data);
        setFilteredBrands(filtered);
        setApiStatus({
          status: true,
          message: brands.message,
          loading: false,
        });
      } catch (error) {
        setApiStatus({
          status: false,
          message: "An Error Occurred. Please try again later.",
          loading: false,
        });
      }
    }

    fetchAllBrands();
  }, []);

  useEffect(() => {
    const filteredBrands = brandList.filter(
      (brand) =>
        brand.name.toLowerCase().includes(brandSearch.toLowerCase()) ||
        brand.slug.toLowerCase().includes(brandSearch.toLowerCase()) ||
        brand.description?.toLowerCase().includes(brandSearch.toLowerCase())
    );

    const filtered = brandService.filterBrandsByLetter(filteredBrands);
    setFilteredBrands(filtered);
  }, [brandList, brandSearch]);

  useEffect(() => {
    if (selectedFilters.length === 0) {
      const filtered = brandService.filterBrandsByLetter(brandList);
      setFilteredBrands(filtered);
    } else {
      const selectedBrands = brandList.filter((brand) =>
        selectedFilters.includes(
          brand.name.charAt(0).toUpperCase() || brand.name.charAt(0)
        )
      );

      const filtered = brandService.filterBrandsByLetter(selectedBrands);
      setFilteredBrands(filtered);
    }
  }, [selectedFilters, brandList]);

  return (
    <div className="brands-list-page">
      <SEO
        title="Brands"
        description="Find all brands of products to choose from. We know it's so tempting in the middle of so many choices, but we are here to help you find the best match for your lifestyle."
      />
      <Breadcrumbs
        data={[
          {
            label: "Brands",
            to: window.location.pathname,
          },
        ]}
      />

      <div className="brands-list-content">
        <div className="brands-heading">
          <h1>Brands A-Z</h1>
        </div>
        <div className="brands-filter-search">
          <input
            type="text"
            placeholder="Search brands (A-Z)"
            value={brandSearch}
            onChange={(e) => {
              setBrandSearch(e.target.value);
            }}
          />
          <button type="button">
            <MagnifyingGlassIcon />
          </button>
        </div>
        <div className="brands-filter">
          <button
            className="brands-filter-button"
            type="button"
            onClick={() => {
              setSelectedFilters([]);
            }}
          >
            ALL
          </button>
          {Array.from({ length: 26 }, (_, i) =>
            String.fromCharCode(65 + i)
          ).map((letter) => (
            <button
              key={`brands-filter-${letter}`}
              className={`brands-filter-button${
                selectedFilters.includes(letter) ? " active" : ""
              }`}
              type="button"
              onClick={() => {
                setSelectedFilters(
                  selectedFilters.includes(letter)
                    ? selectedFilters.filter((filter) => filter !== letter)
                    : [...selectedFilters, letter]
                );
              }}
            >
              {letter}
            </button>
          ))}
          <button
            className={`brands-filter-button${
              selectedFilters.includes("#") ? " active" : ""
            }`}
            type="button"
            onClick={() => {
              setSelectedFilters(
                selectedFilters.includes("#")
                  ? selectedFilters.filter((filter) => filter !== "#")
                  : [...selectedFilters, "#"]
              );
            }}
          >
            #
          </button>
        </div>

        {apiStatus.loading ? (
          <div className="brands-list">
            {Array.from({ length: 50 }).map((_, i) => (
              <div
                className="brands-list-group"
                key={`skeleton-loading-brand-name-${i}`}
              >
                <SkeletonLoading />
              </div>
            ))}
          </div>
        ) : !apiStatus.status ? (
          <ErrorComponent
            title="An error occurred"
            description="An error occurred while fetching all brands"
            errorCode={apiStatus.message}
          />
        ) : (
          <div className="brands-list">
            {Object.entries(filteredBrands).map(([letter, brands]) => {
              return (
                <div
                  key={`brands-list-${letter}`}
                  className="brands-list-group"
                >
                  <h2>{letter}</h2>
                  <ul>
                    {brands.map((brand, i) => {
                      return (
                        <NavLink
                          to={`/brands/${brand.slug}`}
                          state={{
                            from: window.location.pathname,
                          }}
                          key={`brands-list-${letter}-item-${i}`}
                        >
                          <li
                            key={`brand-${brand.slug}`}
                            className="brands-list-item"
                          >
                            {brand.name}
                          </li>
                          {/* <div className="featured-brands-list-item">
                            {brand.logo ? (
                              <img src={brand.logo} alt={brand.name} />
                            ) : (
                              brand.name
                            )}
                          </div> */}
                        </NavLink>
                      );
                    })}
                  </ul>
                </div>
              );
            })}
          </div>
        )}
      </div>
    </div>
  );
}
