import "./styles.css";
import ILandingDiscover from "../../../domain/entities/landing/ILandingDiscover";
import ExpandableCarousel from "./carousel";
import SkeletonLoading from "../../loading/skeleton-loading";

interface ILandingDiscoverProps {
  discoverItems: ILandingDiscover[];
  loading: boolean;
}

export default function LandingDiscover({
  discoverItems,
  loading,
}: ILandingDiscoverProps) {
  return (
    <div className="landing-discover">
      {loading ? (
        <div className="expandable-carousel">
          {Array.from({ length: 9 }).map((_, index) => (
            <div
              className="expandable-carousel-item"
              key={`sk-ld-land-disc-${index}`}
            >
              <SkeletonLoading />
            </div>
          ))}
        </div>
      ) : (
        <ExpandableCarousel data={discoverItems} />
      )}
    </div>
  );
}
