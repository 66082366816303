import apiErrorChecks from "../../utilities/api/api-error-checks";
import api from "../api";
import IApiResponse from "../entities/api/IApiResponse";
import IBlog from "../entities/blog/IBlog";
import IBlogMini from "../entities/blog/IBlogMini";
import IBlogRequestFilter from "../entities/blog/IBlogRequestFilter";
import { ISearchProductPagination } from "../entities/product/ISearchProductRequest";
import IBlogRepository from "../entities/repositories/IBlogRepository";

export default class BlogRepository implements IBlogRepository {
  async getAllBlogs(
    filter?: IBlogRequestFilter
  ): Promise<IApiResponse<IBlogMini[]>> {
    try {
      const response = await api.post<{
        blogs: IBlogMini[];
        requestDuration: number;
        search_parameters: IBlogRequestFilter;
        pagination: ISearchProductPagination;
      }>("/api/v1/blogs", filter);

      if (!response || !response.data)
        return {
          status: false,
          message: "Failed to fetch blogs.",
          requestDuration: response?.data?.requestDuration || 0,
        };

      return {
        status: true,
        message: "Successfully fetched blogs",
        data: response.data.blogs.map((blog) => ({
          ...blog,
          image: blog.image,
        })),
        pagination: {
          page: response.data.pagination.current_page,
          totalPages: response.data.pagination.total_pages,
          total: response.data.pagination.records_matching_search,
          limit: response.data.pagination.page_length,
          lastPage: response.data.pagination.is_last_page,
        },
        requestDuration: response.data.requestDuration,
      };
    } catch (error: any) {
      const apiErrors = apiErrorChecks(error);
      if (apiErrors.isApiError && apiErrors.message)
        return {
          status: false,
          message: apiErrors.message,
          requestDuration: 0,
        };

      return {
        status: false,
        message: `An unknown error occurred on our end. Check your network connection and try again. Detail: ${
          error?.response?.data?.message ||
          error?.response?.message ||
          error?.message
        }`,
        requestDuration: 0,
      };
    }
  }

  async getBlogBySlug(id: number): Promise<IApiResponse<IBlog | false>> {
    try {
      const response = await api.get<IBlog>("/api/v1/blog", {
        params: {
          blog_id: id,
        },
      });

      if (!response || !response.data)
        return {
          status: false,
          message: "Failed to fetch blog.",
          requestDuration: 0,
        };

      return {
        status: true,
        message: "Successfully fetched blog",
        data: {
          ...response.data,
          image: response.data.image,
        },
        requestDuration: 0,
      };
    } catch (error: any) {
      if (error?.response?.status === 404) {
        return {
          status: false,
          message: "404 | Not Found",
          data: false,
          requestDuration: 0,
        };
      }
      const apiErrors = apiErrorChecks(error);
      if (apiErrors.isApiError && apiErrors.message)
        return {
          status: false,
          message: apiErrors.message,
          requestDuration: 0,
        };

      return {
        status: false,
        message: `An unknown error occurred on our end. Check your network connection and try again. Detail: ${
          error?.response?.data?.message ||
          error?.response?.message ||
          error?.message
        }`,
        requestDuration: 0,
      };
    }
  }
}
