import HeaderSearch from "../../search";
import HeaderUserProfile from "../../user";
import HeaderDrawerNav from "../drawer-nav";
import "./styles.css";

export default function HeaderDrawerContent() {
  return (
    <div className="header-drawer-content">
      <HeaderSearch />
      <HeaderDrawerNav />
      <HeaderUserProfile />
    </div>
  );
}
