const IGFooter = () => {
  return (
    <div className="instagram-media-footer" style={{ padding: "0 16px" }}>
      <div
        style={{
          display: "flex",
          flexDirection: "row",
          marginBottom: "14px",
          alignItems: "center",
        }}
      >
        <div>
          <div
            style={{
              backgroundColor: "#F4F4F4",
              borderRadius: "50%",
              height: "12.5px",
              width: "12.5px",
              transform: "translateX(0px) translateY(7px)",
            }}
          />
          <div
            style={{
              backgroundColor: "#F4F4F4",
              height: "12.5px",
              transform: "rotate(-45deg) translateX(3px) translateY(1px)",
              width: "12.5px",
              flexGrow: 0,
              marginRight: "14px",
              marginLeft: "2px",
            }}
          />
          <div
            style={{
              backgroundColor: "#F4F4F4",
              borderRadius: "50%",
              height: "12.5px",
              width: "12.5px",
              transform: "translateX(9px) translateY(-18px)",
            }}
          />
        </div>
        <div style={{ marginLeft: "8px" }}>
          <div
            style={{
              backgroundColor: "#F4F4F4",
              borderRadius: "50%",
              flexGrow: 0,
              height: "20px",
              width: "20px",
            }}
          />
          <div
            style={{
              width: 0,
              height: 0,
              borderTop: "2px solid transparent",
              borderLeft: "6px solid #f4f4f4",
              borderBottom: "2px solid transparent",
              transform: "translateX(16px) translateY(-4px) rotate(30deg)",
            }}
          />
        </div>
        <div style={{ marginLeft: "auto" }}>
          <div
            style={{
              width: "0px",
              borderTop: "8px solid #F4F4F4",
              borderRight: "8px solid transparent",
              transform: "translateY(16px)",
            }}
          />
          <div
            style={{
              backgroundColor: "#F4F4F4",
              flexGrow: 0,
              height: "12px",
              width: "16px",
              transform: "translateY(-4px)",
            }}
          />
          <div
            style={{
              width: 0,
              height: 0,
              borderTop: "8px solid #F4F4F4",
              borderLeft: "8px solid transparent",
              transform: "translateY(-4px) translateX(8px)",
            }}
          />
        </div>
      </div>
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          flexGrow: 1,
          justifyContent: "center",
          marginBottom: "24px",
        }}
      >
        <div
          style={{
            backgroundColor: "#F4F4F4",
            borderRadius: "4px",
            flexGrow: 0,
            height: "14px",
            marginBottom: "6px",
            width: "224px",
          }}
        />
        <div
          style={{
            backgroundColor: "#F4F4F4",
            borderRadius: "4px",
            flexGrow: 0,
            height: "14px",
            width: "144px",
          }}
        />
      </div>
    </div>
  );
};

export default IGFooter;
