import { ScrollRestoration, useNavigation } from "react-router-dom";
import Header from "../components/header";
import Footer from "../components/footer";
import CacheControlStatus from "../components/cache-control-status";
import { ReactNode } from "react";
import TopBarLoading from "../components/loading/top-bar";

interface IRootProps {
  element: ReactNode;
}

export default function Root({ element }: IRootProps) {
  const navigation = useNavigation();

  return (
    <div className="app-container">
      {navigation.state === "loading" && <TopBarLoading />}
      <CacheControlStatus />
      <Header />
      <main>{element}</main>
      <Footer />
      <ScrollRestoration
        getKey={(location, matches) => {
          return location.pathname;
        }}
      />
    </div>
  );
}
